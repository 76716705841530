import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { EventState } from './model'
import {
  setLoading,
  setEvent,
  setEvents,
  setRecentEvents,
  setPriorityEvents,
  setNearestEvents,
  setMusicEvents,
  setArtEvents,
  setWorkshopEvents,
  setExhibitionEvents,
  setUpcomingEvents,
  setHistoryEvents,
  setEventTicket,
  setEventTickets,
} from './service'

const useEventStore = create<EventState>()(
  devtools(immer((set, get) => ({
    loading: false,
    event: null,
    events: [],
    recentEvents: [],
    priorityEvents: [],
    nearestEvents: [],
    musicEvents: [],
    artEvents: [],
    workshopEvents: [],
    exhibitionEvents: [],
    upcomingEvents: [],
    historyEvents: [],
    eventTicket: null,
    eventTickets: [],
    setLoading,
    setEvent,
    setEvents,
    setRecentEvents,
    setPriorityEvents,
    setNearestEvents,
    setMusicEvents,
    setArtEvents,
    setWorkshopEvents,
    setExhibitionEvents,
    setUpcomingEvents,
    setHistoryEvents,
    setEventTicket,
    setEventTickets,
  })))
)

export default useEventStore
