import { envConfigs } from '@/configs'

export default class ContactModel {
  public id!: string
  public name!: string
  public phone!: string
  public avatarColorIdx!: number
  public thumbnail!: string
  public raw!: string
  public nameCardTemplate!: string
  public companyName!: string
  public companyTitle!: string
  public businessPhone!: string
  public businessEmail!: string
  public companyAddress!: string
  public companyDescription!: string
  public user_id!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.phone = data.phone
      this.avatarColorIdx = data.avatar_color_idx
      this.thumbnail = data.thumbnail
      this.raw = data.raw
      this.nameCardTemplate = data.name_card_template
      this.companyName = data.company_name
      this.companyTitle = data.company_title
      this.businessPhone = data.business_phone
      this.businessEmail = data.business_email
      this.companyAddress = data.company_address
      this.companyDescription = data.company_description
      this.user_id = data.user_id
    }
  }

  public avatarUrl(): string {
    return this.thumbnail
      ? `${envConfigs.IMAGE_LINK}/users/${this.thumbnail}`
      : ''
  }
}
