
import { Wrapper } from '@/components'

import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant, RoutesUrl } from '@/constants'
import OneSignalWeb from '@/OneSignal'
import { useAppStore } from '@/stores/app'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RiArrowRightSLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'
import OneSignal from 'react-onesignal'

const LogoutModal = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onLogout = () => {
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserLanguage)
    modalRef.current?.close()
    OneSignalWeb.logout()
    navigate(RoutesUrl.SignIn)
  }

  const onCancel = () => {
    modalRef.current?.close()
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalTitle}>
        {t('logout.logout')}
      </div>
      <div className={styles.modalContent}>
        {t('logout.logout_description')}
      </div>
      <div className={styles.modalFooter}>
        <button type="button" className={clsx('btn', styles.btnLogout)} onClick={onLogout}>{t('logout.logout')}</button>
        <button type="button" className={clsx('btn', styles.btnCancel)} onClick={onCancel}>{t('logout.cancel')}</button>
      </div>
    </div>
  )
}

const Settings = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeBreadcrumb } = useAppStore()

  const language = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  const onChangeLanguage = () => {
    navigate(RoutesUrl.ChangeLanguage)
  }

  const onChangePassword = () => {
    navigate(RoutesUrl.ChangePassword)
  }

  const onHelpCenter = () => {
    navigate(RoutesUrl.HelpCenter)
  }

  const onDeleteCookie = () => {
    OneSignal.Notifications.requestPermission()
    window.localStorage.removeItem('onesignal-notification-prompt')
    window.location.reload()
  }

  const onLogout = () => {
    modalRef.current?.open({
      content: <LogoutModal />,
    })
  }

  return (
    <Wrapper>
      <Header
        title={t('settings.settings')}
        showBack={true}
      />
      <div className={styles.container}>
        <section className={styles.section}>

          <div className={styles.sectionRow}>
            <div className={styles.rowTitle}>
              {t('settings.primacy_city')}
            </div>
            <div className={styles.rowValue}>
              <div className={styles.rowRightTitle}>Ho Chi Minh</div>
            </div>
          </div>

          <div className={styles.sectionRow}>
            <div className={styles.rowTitle}>
              {t('settings.copy_to_calendar')}
            </div>
            <div className={styles.rowValue}>
              <div className={styles.rowRightTitle}></div>
              <div className={styles.rowRightIcon}>
                <div className="form-check form-switch">
                  <input className={clsx('form-check-input', styles.customInputSwitch)} type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
          </div>


          <div className={styles.sectionRow} onClick={onChangeLanguage}>
            <div className={styles.rowTitle}>
              {t('settings.language')}
            </div>
            <div className={styles.rowValue}>
              <div className={clsx(styles.rowRightTitle, styles.orange)}>
                {language === 'en' ? t('changeLanguage.english') : t('changeLanguage.vietnamese')}
              </div>
              <div className={styles.rowRightIcon}>
                <RiArrowRightSLine size={24} style={{ color: '#8A8380' }} />
              </div>
            </div>
          </div>


          <div className={styles.sectionRow} onClick={onChangePassword}>
            <div className={styles.rowTitle}>
              {t('settings.change_password')}
            </div>
            <div className={styles.rowValue}>
              <div className={styles.rowRightIcon}>
                <RiArrowRightSLine size={24} style={{ color: '#8A8380' }} />
              </div>
            </div>
          </div>

        </section>

        <div className={styles.sectionGroup}>
          {t('settings.support')}
        </div>

        <section className={styles.section}>

          <div className={styles.sectionRow} onClick={onHelpCenter}>
            <div className={styles.rowTitle}>
              {t('settings.help_center')}
            </div>
            <div className={styles.rowValue}>
              <div className={styles.rowRightTitle}></div>
              <div className={styles.rowRightIcon}>
                <RiArrowRightSLine size={24} style={{ color: '#8A8380' }} />
              </div>
            </div>
          </div>

          <div className={styles.sectionRow}>
            <div className={styles.rowTitle}>
              {t('settings.terms_of_service')}
            </div>
            <div className={styles.rowValue}>
              <div className={styles.rowRightTitle}></div>
              <div className={styles.rowRightIcon}>
                <RiArrowRightSLine size={24} style={{ color: '#8A8380' }} />
              </div>
            </div>
          </div>

          <div className={styles.sectionRow} onClick={onDeleteCookie}>
            <div className={clsx(styles.rowTitle, styles.warning)}>
              {t('settings.deleteCookie')}
            </div>
          </div>

          <div className={styles.sectionRow} onClick={onLogout}>
            <div className={clsx(styles.rowTitle, styles.orange)}>
              {t('settings.logout')}
            </div>
          </div>

        </section>
      </div>
    </Wrapper>
  )
}

export default Settings
