import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { NotificationState } from './model'
import { setLoading, setNotifications } from './service'

const useNotificationStore = create<NotificationState>()(
  devtools(immer((set, get) => ({
    loading: false,
    notifications: [],
    setLoading,
    setNotifications,
  })))
)

export default useNotificationStore
