import images from '@/assets'
import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },

  signInPage: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    cursor: 'pointer',
  },

  headerTitle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: Colors.BLACK,
  },

  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  input: {
    display: 'flex',
    padding: '10px 0',
    paddingRight: '35px',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#858585',
    height: '45px',
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    borderRadius: 'unset',
    outline: 'none',
  },

  inputField: {
    display: 'flex',
    alignItems: 'center',
  },

  invalid: {
    backgroundImage: `url(${images.icInvalid()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '20px 20px',
    borderBottom: '1px solid #D93F21',
  },

  valid: {
    backgroundImage: `url(${images.icValid()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '20px 20px',
    borderBottom: '1px solid green',
  },

  invalidMsg: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0',
    fontStyle: 'italic',
    color: 'red',
    fontWeight: '500',
    fontSize: '14px',
  },

  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '20px',
    fontWeight: '500',
    '& a': {
      fontSize: '14px',
      color: Colors.PRIMARY,
      cursor: 'pointer',
    },
    '& a:hover': {
      color: Colors.PRIMARY,
    },
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },

  btnSignIn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
      cursor: 'pointer',
    },
  },

  btnDisabled: {
    backgroundColor: Colors.GRAY,
    color: Colors.WHITE,
    cursor: 'not-allowed',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.GRAY,
      cursor: 'not-allowed',
    },
  },

  signUpNow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: Colors.BLACK,
    marginTop: '20px',
    cursor: 'pointer',
  },

  txtSignUpNow: {
    color: Colors.PRIMARY,
    marginLeft: '5px',
    fontWeight: '500',
  },

  hrLine: {
    width: '100%',
    textAlign: 'center',
    borderBottom: '1px solid #8A8A8A',
    lineHeight: '0.1em',
    margin: '30px 0',
    '& span': {
      background: '#fff',
      color: '#8A8A8A',
      padding: '0 10px',
      fontWeight: '600',
    },
  },

  groupSocial: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btnSocial: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '50px',
    marginRight: '50px',
    cursor: 'pointer',
  },
})

export default useStyles
