import images from "@/assets";
import { envConfigs } from "@/configs";

export type ProfessorState = {
  loading: boolean;
  professor: ProfessorModel | null;
  setLoading: (loading: boolean) => void;
  setProfessor: (professor: ProfessorModel) => void;
};

export default class ProfessorModel {
  public id!: string;
  public name!: string;
  public title!: string;
  public phone!: string;
  public email!: string;
  public bio!: string;
  public data!: string;
  public files!: string;
  public raw!: string;
  public thumbnail!: string;
  public type!: number;
  public checked?: boolean;

  constructor(data: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.title = data.title;
      this.bio = data.bio;
      this.data = data.data;
      this.email = data.email;
      this.phone = data.phone;
      this.files = data.files;
      this.raw = data.raw;
      this.thumbnail = data.thumbnail;
      this.type = data.type;
      this.checked = data.checked;
    }
  }

  public getAvatar() {
    return (
      this.thumbnail ? `${envConfigs.IMAGE_LINK}/professors/${this.thumbnail}` : images.memberDefault()
    );
  }
}
