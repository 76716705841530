

import images from '@/assets'
import { Loading } from '@/components'
import EmptyState from '@/components/empty-state'
import { EventSuggestCard } from '@/components/event-suggest-card'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import useUserStore from '@/stores/user/state'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const History = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const { historyEvents, setHistoryEvents } = useEventStore()

  const { user } = useUserStore()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Events)
  }, [])

  useEffect(() => {
    if (user) {
      getHistoryEvents()
    }
  }, [user])


  const getHistoryEvents = async () => {
    setLoading(true)
    const params = {
      mode: 'all',
      timeFrame: 'past'
    }

    const response: any = await EventAPI.getHistoryEvents(params)
    if (response.status === 200) {
      const events = response.data.data.map((event: any) => new EventModel(event))
      setHistoryEvents(events)
    }
    setLoading(false)
  }

  return (
    <div className={styles.historyContent}>
      {loading && <Loading />}

      {historyEvents?.length > 0 ?
        historyEvents.map((event: any) => (
          <EventSuggestCard key={event?.id} event={event} getData={getHistoryEvents} />
        )) :
        <EmptyState
          imgUrl={images.dataNotFound()}
        />
      }
    </div>
  )
}

export default History
