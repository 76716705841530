
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import images from '@/assets'
import { AvatarColors, RoutesUrl } from '@/constants'
import { UserAPI } from '@/stores/user'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { isValidEmail, isValidPhoneNumber } from '@/utils/validates'
import clsx from 'clsx'
import { IoIosArrowBack } from 'react-icons/io'
import useStyles from './styles'

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

interface InputState {
  loading: boolean
  email: string
  phone: string
  error: any
  lang: Language
}

const ZaloCallback = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const [error, setError] = useState<any>(null)
  const [userInfo, setUserInfo] = useState<any>(null)

  const [state, setState] = useState<InputState>({
    loading: false,
    email: '',
    phone: '',
    error: '',
    lang: Language.English,
  })
  const handleStateChange = (value: string | string[] | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  useEffect(() => {
    handleCallback()
  }, [])

  const handleCallback = (): void => {
    try {
      const codeVerifier = localStorageUtils.getLocalStorage(LocalStorageValueTypes.Verifier) || ''
      const urlSearchParams = new URLSearchParams(window.location.search)
      console.log('urlSearchParams', urlSearchParams)
      const receivedCode = urlSearchParams.get('code')
      console.log('receivedCode', receivedCode)
      if (receivedCode) {
        getAccessToken(receivedCode, codeVerifier)
      } else {
        setError(t('signIn.can_not_login_via_zalo'))
        localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.Verifier)
      }
    } catch (error) {
      setError(t('signIn.can_not_login_via_zalo'))
      localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.Verifier)
    }
  }

  const getAccessToken = async (authorizationCode: string, codeVerifier: string): Promise<void> => {
    try {
      const appId = '545117529906977012'
      const url = 'https://oauth.zaloapp.com/v4/access_token'
      const data = new URLSearchParams()
      data.append('code', authorizationCode)
      data.append('app_id', appId)
      data.append('grant_type', 'authorization_code')
      data.append('code_verifier', codeVerifier)

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'secret_key': '2M185r4zC4rp1Xm62K8Y',
        },
        body: data,
      })
      const result = await response.json()
      console.log('Access result:', result)
      if (result?.access_token) {
        const userAccessToken = result.access_token
        console.log('userAccessToken', userAccessToken)
        getUserInfo(userAccessToken)
      } else {
        setError(t('signIn.can_not_login_via_zalo'))
        localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.Verifier)
        return
      }
    } catch (error) {
      setError(t('signIn.can_not_login_via_zalo'))
      localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.Verifier)
    }
  }

  const getUserInfo = async (accessToken: string): Promise<void> => {
    try {
      const url = 'https://graph.zalo.me/v2.0/me?fields=id,name,picture,phone'

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'access_token': accessToken,
        },
      })
      const result = await response.json()
      console.log('User info:', result)
      if (result?.error > 0) {
        setError(t('signIn.can_not_login_via_zalo'))
        localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.Verifier)
        return
      } else {
        setUserInfo(result)
      }
    } catch (error) {
      setError(t('signIn.can_not_login_via_zalo'))
      localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.Verifier)
    }
  }

  const goBack = () => {
    navigate(RoutesUrl.Home)
  }

  const onLogin = async () => {
    handleStateChange('', 'error')
    handleStateChange(true, 'loading')

    const { email, phone } = state

    if (!email && !phone) {
      handleStateChange(t('signIn.please_enter_email_or_phone'), 'error')
      handleStateChange(false, 'loading')
      return
    }

    if (email && !isValidEmail(email)) {
      handleStateChange(t('signIn.email_is_invalid'), 'error')
      handleStateChange(false, 'loading')
      return
    }

    if (phone && !isValidPhoneNumber(phone)) {
      handleStateChange(t('signIn.phone_is_invalid'), 'error')
      handleStateChange(false, 'loading')
      return
    }

    const response: any = await UserAPI.loginWithZalo({
      id: userInfo?.id,
      name: userInfo?.name,
      email,
      phone,
    })

    const isSuccess = response?.data?.isSuccess || false
    const data = response?.data?.data || null

    if (isSuccess && data) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.AccessToken, JSON.stringify(data?.access_token))
      return navigate('/')
    } else if (response?.data.data?.message) {
      handleStateChange(response?.data.data?.message, 'error')
    }

    handleStateChange(false, 'loading')
  }

  const onChangeInput = (e: any) => {
    handleStateChange('', 'error')
    const { name, value } = e.target
    handleStateChange(value, name)
  }

  const isEmail = isValidEmail(state.email)
  const isPhoneNumber = isValidEmail(state.phone)
  const disabled = (!state.email && !state.phone) || state.loading
  const avatarUrl = userInfo?.picture?.data?.url || null
  const color: any = AvatarColors['4']

  return (
    <div className={clsx(styles.container)}>
      <div className={clsx(styles.signInPage)}>
        <div className={clsx(styles.header)}>
          <div className={clsx(styles.back)} onClick={goBack}>
            <IoIosArrowBack size={24} />
          </div>
          <div className={clsx(styles.headerTitle)}>
            {t('signIn.sign_in_with_zalo')}
          </div>
        </div>

        <div className={clsx(styles.content)}>
          {error &&
            <div className={clsx(styles.error)}>
              <div className={clsx(styles.imageError)}>
                <img src={images.failed()} width="100%" height="100%" />
              </div>
              <div className={clsx(styles.errorMsg)}>
                {error}
              </div>
              <div className={clsx(styles.btnGroup)}>
                <button type="button" className={clsx('btn', styles.btnCancel)} onClick={goBack}>{t('signIn.back_to_home')}</button>
              </div>
            </div>
          }

          {userInfo?.id &&
            <>
              <div className={clsx(styles.userInfo)}>
                <div className={clsx(styles.userAvatar)}>
                  {avatarUrl ?
                    <img src={avatarUrl} />
                    :
                    <div className={`${styles.image} ${styles.empty}`} style={{ backgroundColor: 'white', border: `1px solid ${color}` }}>
                      <span className={`${styles.txtChar}`} style={{ color: color, fontSize: 40 * 0.48 }}>{userInfo?.name?.slice(0, 1)?.toUpperCase()}</span>
                    </div>
                  }
                </div>
                <div className={clsx(styles.userName)}>
                  {userInfo?.name}
                </div>
              </div>

              <div className={clsx(styles.inputGroup)}>
                <input type="email" className={clsx(styles.input, isEmail && styles.valid, state.email && !isEmail && styles.invalid)} name="email" placeholder="Email" value={state.email} onChange={onChangeInput} />
              </div>

              <div className={clsx(styles.inputGroup)}>
                <input type="phone" className={clsx(styles.input, isPhoneNumber && styles.valid, state.phone && !isPhoneNumber && styles.invalid)} name="phone" placeholder="Phone number" value={state.phone} onChange={onChangeInput} />
              </div>

              <div className={clsx(styles.btnGroup)}>
                <button type="button" className={clsx('btn', styles.btnSignIn, disabled ? styles.btnDisabled : '')} onClick={disabled ? undefined : onLogin}>{t('signIn.continue')}</button>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ZaloCallback
