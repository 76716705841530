import { Colors, RoutesUrl } from '@/constants'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import { clsx } from 'clsx'
import moment from 'moment'
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io'
import { IoLocationSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import useStyles from "./styles"

interface IEventSuggestCardProps {
  event?: EventModel | null
  getData?: () => void
  favoriteCallback?: () => void
}

export const EventSuggestCard = (props: IEventSuggestCardProps) => {
  const styles = useStyles()
  const navigate = useNavigate()

  const { event, getData, favoriteCallback } = props

  const goDetail = () => {
    if (event?.id) {
      navigate(RoutesUrl.EventDetail.replace(':id', event.id.toString()))
    }
  }

  const onFavorite = async () => {
    if (event?.id !== undefined) {
      const response = await EventAPI.favoriteEvent(event?.id.toString())
      if (response?.data?.status === 200) {
        getData && getData()
        favoriteCallback && favoriteCallback()
      }
    }
  }

  const viewTicket = () => {
    if (event?.id) {
      navigate(RoutesUrl.EventTicket.replace(':eventId', event.id.toString()).replace(':id', event?.participants[0]?.id.toString()))
    }
  }

  const isJoined = event?.participants?.length !== undefined && event.participants.length > 0

  return (
    <div className={styles.eventItem}>
      <div className={styles.eventSuggestCard}>
        <div className={styles.eventSuggestBanner} onClick={goDetail}>
          <img src={event?.getBannerUrl()} title='Event banner' width={'100%'} height={'100%'} />
        </div>

        <div className={styles.eventSuggestContent}>
          <div className={styles.rowSuggestTitle}>
            <div className={styles.eventSuggestTitle} onClick={goDetail}>
              {event?.title}
            </div>
            <div onClick={onFavorite}>
              {event?.isFavorite && <IoMdHeart size={20} style={{ color: Colors.PRIMARY }} />}
              {!event?.isFavorite && <IoMdHeartEmpty size={20} style={{ color: Colors.PRIMARY }} />}
            </div>
          </div>

          <div className={styles.rowSuggestAction}>
            <div className={styles.eventJoined}>
              <div className={styles.eventTime}>{moment(event?.eventDateTimes[0]?.startDateTime).format('dddd, DD/MM - HH:mm')}</div>
              <div className={styles.eventLocation}>
                <IoLocationSharp size={16} style={{ color: Colors.PRIMARY }} />
                <div className={styles.address}>{event?.location?.address}</div>
              </div>
            </div>

            <div className={clsx(styles.rowPrice, isJoined && styles.rowPriceJoined)}>
              <span className={clsx(styles.txtPrice, isJoined && styles.txtPriceJoined)}>{isJoined ? 'Joined' : 'Free'}</span>
            </div>
          </div>
        </div>
      </div>

      {isJoined &&
        <div className={styles.viewTicket} onClick={viewTicket}>
          <button className={clsx('btn', styles.btnTicket)}>View E-ticket</button>
        </div>
      }
    </div>
  )
}