

import { envConfigs } from '@/configs'
import UserModel from '@/stores/user/model'
import clsx from 'clsx'
import { QRCodeSVG } from 'qrcode.react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { LiaUserEditSolid } from 'react-icons/lia'
import { MdLocationPin, MdOutlineMailOutline } from 'react-icons/md'
import { TbPhoneCall } from 'react-icons/tb'
import useStyles from './styles'

interface Props {
  user?: UserModel | any
  userId?: string
  showTicket?: () => void
  updateProfile?: () => void
  setDefault?: () => void
}

const Template3 = (props: Props) => {
  const styles = useStyles()

  const { user, userId, showTicket, updateProfile, setDefault } = props

  const profileUrl = `${envConfigs.WEB_URL}/contacts/${user?.id}`

  return (
    <div className={styles.profileCard}>
      <div className={styles.infoSection}>
        <div className={styles.cardName}>{user?.name}</div>
        <div className={styles.cardPosition}>
          {user?.companyTitle ? user.companyTitle : ''}
        </div>

        <div className={styles.hrLine}></div>

        <div className={styles.cardPhone}>
          <div className={styles.iconArea}>
            <TbPhoneCall size={16} />
          </div>
          <div>{user?.businessPhone}</div>
        </div>
        <div className={styles.cardEmail}>
          <div className={styles.iconArea}>
            <MdOutlineMailOutline size={16} />
          </div>
          <div>{user?.businessEmail}</div>
        </div>
        <div className={styles.cardAddress}>
          <div className={styles.iconArea}>
            <MdLocationPin size={16} />
          </div>
          <div>{user?.companyAddress}</div>
        </div>
      </div>

      <div className={styles.qrSection}>
        <div className={styles.qrCodeContainer}></div>
        <div className={styles.qrBoxShadow}>
          <div className={styles.qrCodeHex}>
            <div className={styles.qrCodeContact} onClick={showTicket}>
              <QRCodeSVG
                id="qrCodeContact"
                size={80}
                value={profileUrl ?? ''}
              />
            </div>
          </div>
        </div>
        <div className={clsx('showGroupBtn', styles.groupBtn)}>
          <div className={clsx(styles.rightBtn)} onClick={user?.isDefault ? undefined : setDefault}>
            {user?.isDefault ? <AiFillStar size={22} style={{ color: 'yellow' }} /> : <AiOutlineStar size={22} style={{ color: '#fff' }} />}
          </div>
          <div className={clsx(styles.rightBtn, styles.mt2)} onClick={updateProfile}>
            <LiaUserEditSolid size={22} style={{ color: '#fff' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template3
