export default class LocationModel {
  public id!: number
  public name!: string
  public address!: string
  public placeId!: string
  public latitude!: string
  public longitude!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.address = data.address
      this.placeId = data.place_id
      this.latitude = data.latitude
      this.longitude = data.longitude
    }
  }

}