import images from "@/assets";
import { envConfigs } from "@/configs";

export type EnterpriseState = {
  loading: boolean;
  enterprise: EnterpriseModel | null;
  setLoading: (loading: boolean) => void;
  setEnterprise: (enterprise: EnterpriseModel) => void;
}

export default class EnterpriseModel {
  public id!: string;
  public name!: string;
  public bio!: string;
  public website!: string;
  public data!: string;
  public email!: string;
  public phone!: string;
  public files!: string;
  public raw!: string;
  public thumbnail!: string;
  public checked?: boolean;

  constructor(data: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.bio = data.bio;
      this.website = data.website;
      this.data = data.data;
      this.email = data.email;
      this.phone = data.phone;
      this.files = data.files;
      this.raw = data.raw;
      this.thumbnail = data.thumbnail;
      this.checked = data.checked;
    }
  }
  public getAvatar() {
    return this.thumbnail ? `${envConfigs.IMAGE_LINK}/enterprises/${this.thumbnail}` : images.memberDefault();
  }
}