import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  sectionGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0',
    fontWeight: 'bold',
  },

  section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
    padding: '15px',
    borderRadius: '10px',
    boxShadow: '1px 1px 16px 0px rgba(0, 0, 0, 0.10)',
  },

  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
    borderBottom: '1px solid #EBE9E8',
    paddingBottom: '10px',
    paddingTop: '10px',
  },

  rowTitle: {
    flex: 1,
    fontWeight: '400',
  },

  rowValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'normal',
  },

  rowRightTitle: {
    fontWeight: 'normal',
    color: '#8A8380',
  },

  rowRightIcon: {
    fontWeight: 'normal',
    cursor: 'pointer',
  },

  orange: {
    color: Colors.PRIMARY,
    fontWeight: '400',
  },

  //logout modal
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },

  modalTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },

  modalContent: {
    fontSize: '16px',
    fontWeight: 'normal',
    marginBottom: '20px',
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  modalBtnGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  btnLogout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    height: '40px',
  },

  btnCancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    color: Colors.PRIMARY,
    backgroundColor: Colors.WHITE,
    border: '1px solid #F84C0B',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    height: '40px',
    marginLeft: '10px',
  },

  customInputSwitch: {
    backgroundColor: '#27AE60 !important',
    borderColor: '#FFFFFF !important',
    height: '20px !important',
  },

  warning: {
    color: Colors.BGORANGE,
  },
})

export default useStyles
