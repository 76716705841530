
import { Loading, Wrapper } from '@/components'

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { EventCard } from '@/components/event-card'
import Header from '@/components/wrapper/components/header'
import { EventAPI } from '@/stores/event'
import EventModel, { EventType } from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const WorkshopEvents = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)

  const { workshopEvents, setWorkshopEvents } = useEventStore()

  useEffect(() => {
    getWorkshopEvents()
  }, [])

  const getWorkshopEvents = async () => {
    setLoading(true)
    const params = {
      type: EventType.Workshop
    }

    const response: any = await EventAPI.getWorkshopEvents(params)
    if (response.status === 200) {
      const events = response.data.data.map((event: any) => new EventModel(event))
      setWorkshopEvents(events)
    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <Header
        title={t('discover.workshop')}
        showBack={true}
      />

      {loading && <Loading />}

      <div className={styles.container}>
        <section className={styles.sectionEvents}>
          {workshopEvents?.length > 0 ?
            workshopEvents?.map((event: EventModel) => (
              <div className={styles.eventCardBox}>
                <EventCard event={event} eventCardCustomStyle={styles.width100} />
              </div>
            )) :
            <EmptyState
              imgUrl={images.dataNotFound()}
            />
          }
        </section>
      </div>
    </Wrapper>
  )
}

export default WorkshopEvents
