
import { Wrapper } from '@/components'

import images from '@/assets'
import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant, RoutesUrl } from '@/constants'
import { useAppStore } from '@/stores/app'
import { UserAPI } from '@/stores/user'
import useUserStore from '@/stores/user/state'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'

interface iniState {
  errors: any
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const AlertModal = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isSuccess, message } = props

  const onContinue = () => {
    navigate(RoutesUrl.SignIn)
    modalRef.current?.close()
  }

  const onTryAgain = () => {
    modalRef.current?.close()
  }

  const onCancel = () => {
    navigate(-1)
    modalRef.current?.close()
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img src={isSuccess ? images.success() : images.failed()} alt="success" />
      </div>

      <div className={styles.successTitle}>{isSuccess ? t('alert.success') : t('alert.failed')}</div>
      <div className={styles.successDescription}>
        {isSuccess ? t('changePassword.change_password_success') : t('changePassword.change_password_failed')}
      </div>

      <button className={clsx('btn', styles.btnOk)} onClick={isSuccess ? onContinue : onTryAgain}>{isSuccess ? t('alert.ok') : t('alert.try_again')}</button>
      {!isSuccess && <button className={clsx('btn', styles.btnCancel)} onClick={onCancel}>{t('alert.cancel')}</button>}
    </div>
  )
}

const ChangePassword = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { changeBreadcrumb } = useAppStore()
  const { user, changeUser } = useUserStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Settings)
  }, [])

  const [state, setState] = useState<iniState>({
    errors: {},
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const handleStateChange = (value: string | string[] | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const onChangeInput = (e: any, field: string) => {
    handleStateChange(e.target.value, field)
  }

  const onShowSuccessModal = (isSuccess: boolean, message: string) => {
    modalRef.current?.open({
      content: <AlertModal isSuccess={isSuccess} message={message} />,
    })
  }

  const onChangePassword = async () => {
    let isSuccess = false
    let message = ''
    const errors: any = {}
    const { currentPassword, newPassword, confirmPassword } = state

    if (!currentPassword) {
      errors.currentPassword = t('changePassword.current_password_required')
      handleStateChange(errors, 'errors')
      return
    }

    if (!newPassword) {
      errors.newPassword = t('changePassword.new_password_required')
      handleStateChange(errors, 'errors')
      return
    }

    if (!confirmPassword) {
      errors.confirmPassword = t('changePassword.confirm_password_required')
      handleStateChange(errors, 'errors')
      return
    }

    if (newPassword.length < 6) {
      errors.newPassword = t('changePassword.new_password_min_length', { length: 6 })
      handleStateChange(errors, 'errors')
      return
    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = t('changePassword.confirm_password_not_match')
      handleStateChange(errors, 'errors')
      return
    }

    const data = {
      currentPassword,
      newPassword
    }

    const response: any = await UserAPI.changePasswordUser(user?.id ?? '', data)
    if (response.status === 200) {
      localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
      localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
      localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserLanguage)
      isSuccess = true
    } else {
      isSuccess = false
      message = response?.data?.data?.message
    }
    onShowSuccessModal(isSuccess, message)
  }

  const disabled = !state.currentPassword || !state.newPassword || !state.confirmPassword

  return (
    <Wrapper>
      <Header
        title={t('changePassword.change_password')}
        showBack={true}
      />
      <div className={styles.container}>
        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('changePassword.current_password')}</label>
          <input type="password" placeholder={t('changePassword.current_password_placeholder')} className={styles.input} value={state.currentPassword} onChange={(e: any) => onChangeInput(e, 'currentPassword')} />
          <span className={styles.error}>{state.errors?.currentPassword}</span>
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('changePassword.new_password')}</label>
          <input type="password" placeholder={t('changePassword.new_password_placeholder')} className={styles.input} value={state.newPassword} onChange={(e: any) => onChangeInput(e, 'newPassword')} />
          <span className={styles.error}>{state.errors?.newPassword}</span>
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('changePassword.confirm_password')}</label>
          <input type="password" placeholder={t('changePassword.confirm_password_placeholder')} className={styles.input} value={state.confirmPassword} onChange={(e: any) => onChangeInput(e, 'confirmPassword')} />
          <span className={styles.error}>{state.errors?.confirmPassword}</span>
        </div>
        <div className={clsx(styles.btnGroup)}>
          <button type="button" className={clsx('btn', styles.btnUpdate, disabled ? styles.btnDisabled : '')} onClick={disabled ? undefined : onChangePassword}>{t('changePassword.save')}</button>
        </div>
      </div>
    </Wrapper>
  )
}

export default ChangePassword
