import images from "@/assets"
import { envConfigs } from "@/configs"

export default class BannerModel {
  public id!: number
  public eventId!: number
  public thumbnail!: string
  public raw!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.eventId = data.event_id
      this.thumbnail = data.thumbnail
      this.raw = data.raw
    }
  }

  public getUrl(): string {
    // https://cdn.locaworld.net/vhub/staging/events/6d17f24e89aa773793025b1a0653f48c-thumbnail
    return this.thumbnail ? `${envConfigs.IMAGE_LINK}/events/${this.thumbnail}` : images.eventDetailDefault()
  }

  public getRawUrl(): string {
    // https://cdn.locaworld.net/vhub/staging/events/6d17f24e89aa773793025b1a0653f48c-thumbnail
    return this.thumbnail ? `${envConfigs.IMAGE_LINK}/events/${this.raw}` : images.eventDetailDefault()
  }
}