
import { Wrapper } from '@/components'

import { EventCard } from '@/components/event-card'
import Header from '@/components/wrapper/components/header'
import { Colors } from '@/constants'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { TbFilterCog } from 'react-icons/tb'
import useStyles from './styles'

interface ViewAllProps {
  isShowSearch?: boolean
  isShowFilter?: boolean
}

const Filter = () => {
  const styles = useStyles()

  const [tag, setTag] = useState(0)

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <div className={styles.modalTitle}>Filter</div>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.inputGroup}>
          <label className={styles.label}>Location</label>
          <input type="text" placeholder='Location' className={styles.input} />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>Categories</label>
          <div className={clsx(styles.rowTag)}>
            <div className={clsx(styles.tagItem, tag === 0 && styles.active)} onClick={() => setTag(0)}>All</div>
            <div className={clsx(styles.tagItem, tag === 1 && styles.active)} onClick={() => setTag(1)}>Art</div>
            <div className={clsx(styles.tagItem, tag === 2 && styles.active)} onClick={() => setTag(2)}>Workshop</div>
            <div className={clsx(styles.tagItem, tag === 3 && styles.active)} onClick={() => setTag(3)}>Exhibition</div>
            <div className={clsx(styles.tagItem, tag === 4 && styles.active)} onClick={() => setTag(4)}>Festival</div>
            <div className={clsx(styles.tagItem, tag === 5 && styles.active)} onClick={() => setTag(5)}>Music</div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>Date</label>
          <div className={styles.fRow}>
            <input type="text" placeholder='Start' className={styles.input} />
            <input type="text" placeholder='End' className={clsx(styles.input, styles.ml10)} />
          </div>
        </div>

        <div className={styles.btnGroup}>
          <div className={styles.btnSearch}>Search</div>
        </div>
      </div>
    </div>
  )
}

const ViewAll = (props: ViewAllProps) => {
  const styles = useStyles()

  const { events, setEvents } = useEventStore()

  useEffect(() => {
    getEvents()
  }, [])

  const getEvents = async () => {
    const response: any = await EventAPI.getEvents()
    if (response.status === 200) {
      const events = response.data.data.map((event: any) => new EventModel(event))
      setEvents(events)
    }
  }

  const onShowFilter = () => {
    modalRef.current?.open({
      content: <Filter />,
    })
  }

  return (
    <Wrapper>
      <Header
        title='Events'
        showBack={true}
        right={
          <div className={styles.btnCreate} onClick={onShowFilter}>
            <TbFilterCog size={20} style={{ color: Colors.PRIMARY }} />
          </div>
        }
      />
      <div className={styles.container}>
        <section className={styles.sectionSearch}>
          <input type="text" className="form-control" name="keywords" placeholder="Search" />
        </section>

        <section className={styles.sectionEvents}>
          {events.map((event: EventModel) => (
            <div className={styles.eventCardBox}>
              <EventCard event={event} eventCardCustomStyle={styles.width100} />
            </div>
          ))}
        </section>
      </div>
    </Wrapper>
  )
}

export default ViewAll
