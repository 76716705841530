

import { QRCodeSVG } from 'qrcode.react'
import { MdLocationPin, MdOutlineMailOutline } from 'react-icons/md'
import { TbPhoneCall } from 'react-icons/tb'
import useStyles from './styles'

const MiniTemplate1 = () => {
  const styles = useStyles()

  return (
    <div className={styles.profileCard}>
      <div className={styles.infoSection}>
        <div className={styles.cardName}>Katniss Nguyen</div>
        <div className={styles.cardPosition}>
          Lorem ipsum dolor sit amet consectetur.
        </div>

        <div className={styles.hrLine}></div>

        <div className={styles.cardPhone}>
          <div className={styles.iconArea}>
            <TbPhoneCall size={10} />
          </div>
          <div>+84 303 030 030</div>
        </div>
        <div className={styles.cardEmail}>
          <div className={styles.iconArea}>
            <MdOutlineMailOutline size={10} />
          </div>
          <div>event2023@gmail.com</div>
        </div>
        <div className={styles.cardAddress}>
          <div className={styles.iconArea}>
            <MdLocationPin size={10} />
          </div>
          <div>35 Tôn Thất Thiết, phường 5, Q.10, Tp. HCM</div>
        </div>
      </div>

      <div className={styles.qrSection}>
        <div className={styles.qrCodeContainer}></div>
        <div className={styles.qrCode}>
          <QRCodeSVG size={40} value="Event WbSys" />
        </div>
      </div>
    </div>
  )
}

export default MiniTemplate1
