import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  btnSocial: {
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    border: 'none',
    background: 'white',
    margin: 0,
    '> span': {
      padding: '0 !important',
      margin: '0 !important',
    },
  },
})

export default useStyles
