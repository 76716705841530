import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  viewEmpty: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  imgEmpty: {
    width: '150px',
    height: '150px',
  },

  txtEmpty: {
    fontSize: 16,
    color: '#3C3C3E',
    marginTop: '20px !important',
  },
})

export default useStyles