import { ScheduleTypeEnum } from '@/constants/schedule-type.enum'
import { ScheduleAPI } from '@/stores/schedule'
import { RequestScheduleBody } from '@/stores/schedule/model'
import moment from 'moment'
import { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { Wrapper } from '../wrapper'
import useStyles from './styles'
import { useModal } from '@/ModalContext'
import { modalRef } from '@/utils/refs'
import images from '@/assets'
import clsx from 'clsx'

interface Props {
  id?: string
  type: number
  onClose: () => void
}

const AlertModal = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { isSuccess, message } = props

  const onContinue = () => {
    modalRef.current?.close()
    props.onClose && props.onClose()
  }

  const onTryAgain = () => {
    modalRef.current?.close()
    props.onClose && props.onClose()
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img
          src={isSuccess ? images.success() : images.failed()}
          alt="success"
        />
      </div>

      <div className={styles.successTitle}>
        {isSuccess ? t('alert.success') : t('alert.failed')}
      </div>
      <div className={styles.successDescription}>
        {!message ? t('schedule.successText') : message}
      </div>

      <button
        className={clsx('btn', styles.btnOk)}
        onClick={isSuccess ? onContinue : onTryAgain}
      >
        {isSuccess ? t('alert.ok') : t('alert.try_again')}
      </button>
    </div>
  )
}

const RequestSchedule: FunctionComponent<Props> = ({ id, type, onClose }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [errors, setErrors] = useState({ note: '' }) // State to hold error messages
  const { showModal, hideModal } = useModal()
  const [data, setData] = useState({
    time: moment().format('YYYY-MM-DD HH:mm:ss'),
    note: '',
    type,
  })

  const onShowAlertModal = (
    isSuccess: boolean,
    message: string,
    onClose?: () => void,
  ) => {
    modalRef.current?.open({
      content: (
        <AlertModal isSuccess={isSuccess} message={message} onClose={onClose} />
      ),
    })
  }
  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = moment(event.target.value)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss')
    setData({ ...data, time: newTime })
  }

  const [isLoading, setLoading] = useState(false)

  const onSubmit = async () => {
    const newErrors = { note: '' }
    if (!data.note) newErrors.note = t('schedule.noteRequired')

    setErrors(newErrors)

    if (Object.values(newErrors).some(Boolean)) {
      return
    }

    try {
      setLoading(true)
      const reqBody: RequestScheduleBody = { ...data }
      switch (data.type) {
        case ScheduleTypeEnum.business:
          reqBody.user_business_profile_id = id
          break
        case ScheduleTypeEnum.enterprise:
          reqBody.enterprise_id = id
          break
        case ScheduleTypeEnum.professor:
          reqBody.professor_id = id
          break
      }

      const response = await ScheduleAPI.requestSchedule(reqBody)
      setLoading(false)
      if (response?.status === 200) {
        onShowAlertModal(true, t('schedule.successText'), onClose)
      } else {
        onShowAlertModal(false, response?.statusText ?? t('schedule.failed'))
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <div className={styles.modal}>
      <Wrapper>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.modalHeader}>
              <h4>{t('schedule.requestSchedule')}</h4>
              <MdClose size={24} onClick={handleClose} />
            </div>
            <div className={styles.modalBody}>
              <div className={styles.formGroup}>
                <label className={styles.label} htmlFor="schedule-time">
                  {t('schedule.time')}
                </label>
                <input
                  className={styles.dateTimeInput}
                  type="datetime-local"
                  id="schedule-time"
                  name="schedule-time"
                  min={moment().format('YYYY-MM-DDTHH:mm')}
                  value={moment(data.time).format('YYYY-MM-DDTHH:mm')}
                  onChange={handleDateTimeChange}
                  required
                />
              </div>
              <div className={styles.modalBody}>
                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="schedule-note">
                    {t('schedule.note')}
                  </label>
                  <textarea
                    className={styles.dateTimeInput}
                    id="schedule-note"
                    required
                    value={data.note}
                    onChange={e => setData({ ...data, note: e.target.value })}
                  />
                  {errors.note && <p className={styles.error}>{errors.note}</p>}
                </div>
              </div>
            </div>

            <div className={styles.modalActions}>
              <button onClick={handleClose} className={styles.cancelBtn}>
                {t('schedule.cancel')}
              </button>
              <button
                disabled={isLoading}
                onClick={onSubmit}
                className={styles.submitBtn}
              >
                {t('schedule.send')}
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

export default RequestSchedule
