import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    historyContent: {
        display: '-webkit-flex',
        flexDirection: 'column',
        flex: 1, ///height: vh        
        maxHeight: '-webkit-calc(100vh - 220px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.25em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bcbcbc',
        },
    },
})

export default useStyles
