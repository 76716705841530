import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { AppState } from './model'
import { changeBreadcrumb } from './service'

const useAppStore = create<AppState>()(
  devtools(immer((set, get) => ({
    breadcrumb: 1,
    changeBreadcrumb,
  })))
)

export default useAppStore
