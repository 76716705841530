import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ModalProvider } from './ModalContext'
import reportWebVitals from './reportWebVitals'

// eslint-disable-next-line
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <GoogleOAuthProvider clientId="483826794083-ab8gu96h5e7je5pta6fc1pvce214nfhn.apps.googleusercontent.com">
    <ModalProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ModalProvider>
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
