

import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiPhoneCall } from 'react-icons/bi'
import { FiMail } from 'react-icons/fi'
import { IoLocationOutline } from 'react-icons/io5'
import useStyles from './styles'

interface State {
  subject: string
  message: string
}

const ContactUs = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { changeBreadcrumb } = useAppStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  const [state, setState] = useState<State>({
    subject: '',
    message: ''
  })
  const handleStateChange = (value: string | string[] | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const onChangeInput = (e: any, field: string) => {
    handleStateChange(e.target.value, field)
  }

  const disabled = !state.subject || !state.message

  return (
    <div className={styles.contactUs}>

      <div className={styles.contactInfo}>
        <div className={styles.contactTile}>WorldBaseSys</div>

        <div className={styles.contactInfoItem}>
          <div className={styles.contactInfoItemIcon}>
            <BiPhoneCall />
          </div>
          <div className={styles.contactInfoItemContent}>
            +1 234 567 890
          </div>
        </div>

        <div className={styles.contactInfoItem}>
          <div className={styles.contactInfoItemIcon}>
            <FiMail />
          </div>
          <div className={styles.contactInfoItemContent}>
            us@worldbasesys.com
          </div>
        </div>


        <div className={styles.contactInfoItem}>
          <div className={styles.contactInfoItemIcon}>
            <IoLocationOutline />
          </div>
          <div className={styles.contactInfoItemContent}>
            19 D7, Melosa, Phu Huu Ward, Thu Duc City, HCMC, Vietnam
          </div>
        </div>

      </div>

      <div className={styles.contactForm}>
        <div className={styles.contactTile}>{t('contactUs.we_ready_to_contact')}</div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('contactUs.subject')}</label>
          <input type="text" className={styles.input} value={state.subject} onChange={(e: any) => onChangeInput(e, 'subject')} />
        </div>
        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('contactUs.message')}</label>
          <textarea className={styles.textArea} value={state.message} onChange={(e: any) => onChangeInput(e, 'message')} />
        </div>

        <div className={clsx(styles.btnGroup)}>
          <button type="button" className={clsx('btn', styles.btnUpdate, disabled ? styles.btnDisabled : '')} onClick={disabled ? undefined : undefined}>{t('contactUs.send_message')}</button>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
