import axios, { AxiosResponse, Method } from 'axios'
// import perf from '@react-native-firebase/perf'
// import bugLog from '@common/utils/bug-log'

export enum APIMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export const parseFormData = (data: any) => {
  const formData = new FormData()
  Object.keys(data)?.forEach(key => {
    const value = data[key]
    formData.append(`${key}`, value)
  })

  return formData
}

// const requestTrace = (config: any) => {
//   axios.interceptors.request.use(async function () {
//     try {
//       const httpMetric = perf().newHttpMetric(config.url, config.method)
//       config.metadata = {httpMetric}
//       const state: ReduxState = store.getState()
//       const user = state.users.user
//       const selectedCompany = state.companies.selectedCompany

//       if (user) {
//         httpMetric.putAttribute('userId', user.id + '')
//         httpMetric.putAttribute(
//           'username',
//           `${user.firstName} ${user.lastName}`,
//         )
//         httpMetric.putAttribute('email', user.email)
//       }
//       if (selectedCompany) {
//         httpMetric.putAttribute('companyId', selectedCompany.id + '')
//         httpMetric.putAttribute('companyName', selectedCompany.name)
//       }

//       await httpMetric.start()
//     } finally {
//       return config
//     }
//   })
// }

// const responseTrace = (response: any) => {
//   axios.interceptors.response.use(
//     async function (res: any) {
//       try {
//         // Request success, e.g. HTTP code 200
//         const {httpMetric} = res.config.metadata

//         httpMetric.setHttpResponseCode(res.status)
//         httpMetric.setResponseContentType(res.headers['content-type'])
//         await httpMetric.stop()
//       } finally {
//         return res
//       }
//     },
//     async function (error: any) {
//       try {
//         // Request failed, e.g. HTTP code 500
//         const {httpMetric} = error.config.metadata
//         if (response.data?.message || response?.message)
//           httpMetric.putAttribute(
//             'message',
//             response.data?.message ?? response?.message,
//           )

//         httpMetric.setHttpResponseCode(error.response.status)
//         httpMetric.setResponseContentType(
//           error.response.headers['content-type'],
//         )
//         await httpMetric.stop()
//       } finally {
//         // Ensure failed requests throw after interception
//         return Promise.reject(error)
//       }
//     },
//   )
// }

export class APIGateway {
  private token: string | null = ''
  private lang: string | null = 'no'

  async init(token: string | null) {
    this.token = token
  }

  async changeLanguage(lang: string | null) {
    this.lang = lang
  }

  private async call(
    method: Method,
    url: string,
    params: object = {},
    _headers: object = {}
  ): Promise<AxiosResponse | undefined> {
    let headers: any = {
      'X-Powered-By': 'Express',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      // 'Content-Type': 'application/json; charset=utf-8', // dont work
      'Content-Type': 'application/x-www-form-urlencoded', // use this instead of application/json
      'Accept-Language': this.lang,
    }

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`
    }

    headers = Object.assign(headers, _headers)

    const initData: any = { timeout: 300000, headers }

    // HACK to fix bug: https://github.com/axios/axios/issues/4406
    if (headers['Content-Type'] === 'multipart/form-data') {
      initData.transformRequest = (dt: any) => dt
    }
    try {
      // requestTrace({url, method})

      const response: any = await axios
        .create(initData)
        .request({
          method,
          url,
          data: method !== 'GET' && params,
          params: method === 'GET' && params,
        })
        .catch(function (error: any) {
          return error.response
        })

      // responseTrace(response)
      // if (
      //   response &&
      //   (response.status >= 300 || response.status < 200) &&
      //   response.status !== 404
      // ) {
      //   bugLog.notify(
      //     `[${Config.SCHEME}][API][${url}][${
      //       response?.status ?? 'NOCODE'
      //     }] - Message: ${JSON.stringify(
      //       response.data.message
      //     )} - Data: ${JSON.stringify(response.data)}`
      //   )
      // }

      return response
    } catch (e) {}
  }

  async send(method: Method, url: string, params?: object, headers?: object) {
    return await this.call(method, url, params, headers)
  }
}

const gateway = new APIGateway()
export default gateway
