import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },

  rowBackShare: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '20px',
    position: 'absolute',
    top: 0,
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    backgroundColor: '#F84C0B66',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  share: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    backgroundColor: '#F84C0B66',
    borderRadius: '50%',
  },

  rowDateFee: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 20px',
    position: 'absolute',
    bottom: 0,
  },

  rowDate: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: Colors.WHITE,
    fontWeight: '600',
  },

  txtPrev: {
    fontSize: '14px',
  },

  dateTime: {
    fontSize: '16px',
  },

  price: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.PRIMARY,
    padding: '7px 15px',
    borderRadius: '10px',
  },

  txtPrice: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: Colors.WHITE,
    whiteSpace: 'nowrap',
  },

  //content
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '20px',
  },

  //event

  eventTitleWrapper: {
    width: '100%',
    display: 'flex',
    gap: '15px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '1px solid #E1DFDD',
  },

  eventTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    paddingBottom: '15px',
  },

  eventDateTimeArea: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0',
    borderBottom: '1px solid #E1DFDD',
  },

  eventRemaining: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  txtRemaining: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#0E0E0E',
  },

  txtRemainTime: {
    fontSize: '0.875rem',
    fontWeight: '600',
    color: Colors.PRIMARY,
  },

  eventDateTime: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
  },

  iconArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    backgroundColor: '#FDA78733',
    padding: '5px',
    borderRadius: '8px',
    '& img': {
      width: '24px',
      height: '24px',
    },
  },

  rowDateTime: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '10px',
  },

  txtDate: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#0E0E0E',
  },

  txtTime: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#8A8380',
  },

  eventLocation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '10px',
  },

  rowLocation: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '10px',
  },

  txtLocation: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#0E0E0E',
  },

  txtAddress: {
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#8A8A8A',
  },

  viewMap: {
    color: Colors.PRIMARY,
    fontSize: '0.875rem',
    fontWeight: '600',
    padding: '10px 0 10px 10px',
  },

  eventGroupMember: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '10px',
  },

  eventMemberList: {
    display: 'flex',
    position: 'relative',
    marginLeft: '10px',
  },

  eventMember: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    zIndex: 0,
  },

  eventMember2: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginLeft: -15,
    zIndex: 1,
  },

  eventMember3: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginLeft: -15,
    marginRight: 10,
    zIndex: 2,
  },

  textMember: {
    fontSize: '0.875rem',
    color: '#0E0E0E',
    fontWeight: 'bold',
  },

  ml20Sub: {
    marginLeft: -20,
  },

  //description
  eventDescription: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },

  rowLabel: {
    display: 'flex',
    flex: 1,
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#0E0E0E',
  },

  rowDescription: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '14px',
    color: '#000000CC',
    marginTop: '10px',
  },

  rulesArea: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    backgroundColor: '#F84C0B1A',
    borderRadius: '8px',
    padding: '15px',
  },

  ruleItem: {
    display: 'flex',
    alignItems: 'center',
  },

  iconRule: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    '& img': {
      width: '20px',
      height: '20px',
    },
  },

  txtRule: {
    fontSize: '0.875rem',
    color: '#131313',
    marginLeft: '10px',
  },

  viewMore: {
    color: Colors.PRIMARY,
  },

  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },

  btnLike: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '40px',
    borderRadius: '10px',
    border: '1px solid #F84C0B',
    marginRight: '15px',
  },

  btnRegister: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    padding: '10px',
    borderRadius: '10px',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
    },
  },

  btnJoined: {
    width: '100%',
    height: '45px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#27AE601A',
    color: '#27AE60',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'not-allowed',
    '&:hover': {
      color: '#27AE60',
      backgroundColor: '#27AE601A',
    },
  },

  //rowTitle
  sectionEvent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    marginBottom: '15px',
  },

  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
  },

  rowAction: {
    display: 'flex',
    color: Colors.PRIMARY,
    fontSize: '13px',
  },

  rowContent: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '0.38em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    paddingBottom: '15px',
  },

  width236: {
    width: '236px',
  },

  // share modal
  modalShare: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 15px',
    position: 'relative',
  },

  close: {
    display: 'flex',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    padding: '5px',
  },

  socialShare: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E1DFDD',
  },

  socialItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60px',
  },

  socialIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  txtShare: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#8A8380',
    marginTop: '10px',
  },

  otherShare: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px',
  },

  otherItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '60px',
  },

  otherIcon: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  //speaker
  rowSpeakers: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },

  rowSpeakerItem: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.38em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    paddingBottom: '15px',
  },

  speakerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
    marginRight: '15px',
  },

  speakerAvatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50% !important',
    '& img': {
      width: '60px',
      height: '60px',
      borderRadius: '50% !important',
    },
  },

  speakerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
  },

  speakerName: {
    fontWeight: 'bold',
    color: '#0E0E0E',
    whiteSpace: 'nowrap',
  },

  speakerPosition: {
    fontSize: '0.875rem',
    color: '#8A8380',
    whiteSpace: 'nowrap',
  },

  //sponsor
  rowSponsors: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },

  rowSponsorItem: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.38em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    paddingBottom: '15px',
  },

  sponsorItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
    cursor: 'pointer',
    marginTop: '1rem',
    padding: '0 1rem',
  },

  sponsorAvatar: {
    width: '60px',
    height: '60px',
    borderRadius: '50% !important',
    '& img': {
      width: '60px',
      height: '60px',
      borderRadius: '50% !important',
    },
  },
  sponsorInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  sponsorName: {
    fontWeight: 'bold',
    color: '#0E0E0E',
    whiteSpace: 'nowrap',
  },
})

export default useStyles
