
import { Loading, Wrapper } from '@/components'

import { EventCard } from '@/components/event-card'
import Header from '@/components/wrapper/components/header'
import { EventAPI } from '@/stores/event'
import EventModel, { EventType } from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import gateway from '@/stores/gateway'
import useUserStore from '@/stores/user/state'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const ArtEvents = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useUserStore()
  const { artEvents, setArtEvents } = useEventStore()

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken
    )
    gateway.init(token ? JSON.parse(token) : null)
  }, [user])

  useEffect(() => {
    getArtEvents()
  }, [user])

  const getArtEvents = async () => {
    setLoading(true)
    const params = {
      type: EventType.Conference
    }

    const response: any = await EventAPI.getArtEvents(params)
    if (response.status === 200) {
      const events = response.data.data.map((event: any) => new EventModel(event))
      setArtEvents(events)
    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <Header
        title={t('discover.art')}
        showBack={true}
      />

      {loading && <Loading />}

      <div className={styles.container}>
        <section className={styles.sectionEvents}>
          {artEvents?.map((event: EventModel) => (
            <div className={styles.eventCardBox}>
              <EventCard event={event} eventCardCustomStyle={styles.width100} favoriteCallback={getArtEvents} />
            </div>
          ))}
        </section>
      </div>
    </Wrapper>
  )
}

export default ArtEvents
