

import { envConfigs } from '@/configs'
import UserModel from '@/stores/user/model'
import clsx from 'clsx'
import { QRCodeSVG } from 'qrcode.react'
import { LiaUserEditSolid } from 'react-icons/lia'
import useStyles from './styles'

interface Props {
  user?: UserModel | any
  showTicket?: () => void
  updateProfile?: () => void
}

const MiniTemplate4 = (props: Props) => {
  const styles = useStyles()

  const { user, showTicket, updateProfile } = props

  const profileUrl = `${envConfigs.WEB_URL}/contacts/${user?.id}`

  return (
    <div className={styles.profileCard}>

      <div className={styles.qrSection}>
        <div className={styles.qrBoxView}>
          <div className={styles.cardCompany}>
            WBSys
          </div>

          <div className={styles.qrCodeHex}>
            <div className={styles.qrCodeContact} onClick={showTicket}>
              <QRCodeSVG
                id="qrCodeContact"
                size={48}
                value={profileUrl ?? ''}
              />
            </div>
          </div>
        </div>
        <div className={clsx('showGroupBtn', styles.groupBtn)}>
          <div className={styles.rightBtn} onClick={updateProfile}>
            <LiaUserEditSolid size={22} style={{ color: '#fff' }} />
          </div>
        </div>
      </div>

      <div className={styles.infoSection}>
        <div className={styles.cardName}>Katniss Nguyen</div>
        <div className={styles.cardPosition}>
          Lorem ipsum dolor sit amet consectetur.
        </div>

        <div className={styles.hrLine}></div>

        <div className={styles.cardPhone}>
          <div>+84 303 030 030</div>
        </div>
        <div className={styles.cardEmail}>
          <div>event2023@gmail.com</div>
        </div>
        <div className={styles.cardAddress}>
          <div>35 Tôn Thất Thiết, phường 5, Q.10, Tp. HCM</div>
        </div>
      </div>

      <div className={styles.qrCodeContainer}></div>

    </div>
  )
}

export default MiniTemplate4
