import { Loading, Wrapper } from '@/components'

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { EventCard } from '@/components/event-card'
import { EventSuggestCard } from '@/components/event-suggest-card'
import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant, RoutesUrl } from '@/constants'
import { useAppStore } from '@/stores/app'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import gateway from '@/stores/gateway'
import useUserStore from '@/stores/user/state'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlinePicture } from 'react-icons/ai'
import { GiPencilBrush } from 'react-icons/gi'
import { IoMusicalNotesOutline, IoSearchOutline } from 'react-icons/io5'
import { MdWorkOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import SlideEvents from './slide-events'
import useStyles from './styles'

const Discover = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const { changeBreadcrumb } = useAppStore()
  const { user } = useUserStore()
  const { priorityEvents, nearestEvents, setPriorityEvents, setNearestEvents } =
    useEventStore()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Discover)
  }, [])

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken,
    )
    gateway.init(token ? JSON.parse(token) : null)
  }, [user])

  useEffect(() => {
    getPriorityEvents()
    getNearEvents()
  }, [user])

  const getPriorityEvents = async () => {
    setLoading(true)
    const response: any = await EventAPI.getPriorityEvents({ isPriority: true })
    if (response.status === 200) {
      const events = response.data.data.map(
        (event: any) => new EventModel(event),
      )
      setPriorityEvents(events)
    }
    setLoading(false)
  }

  const getNearEvents = async () => {
    setLoading(true)
    const response: any = await EventAPI.getNearEvents()
    if (response.status === 200) {
      const events = response.data.data.map(
        (event: any) => new EventModel(event),
      )
      const userEvents: any = await EventAPI.getUpcomingEvents({
        mode: 'joined',
        timeFrame: 'all',
      })
      if (userEvents.status === 200) {
        const userEventsData = userEvents.data.data

        events.forEach((event: any) => {
          const userEvent = userEventsData.find(
            (userEvent: any) => userEvent.id === event.id,
          )

          if (userEvent) {
            event.participants = userEvent.participants
          }
        })
      }
      setNearestEvents(events)
    }
    setLoading(false)
  }

  const viewPriority = () => {
    navigate(RoutesUrl.EventPriority)
  }

  const viewNearest = () => {
    navigate(RoutesUrl.EventNearest)
  }

  const viewMusic = () => {
    navigate(RoutesUrl.EventMusic)
  }

  const viewArt = () => {
    navigate(RoutesUrl.EventArt)
  }

  const viewWorkshop = () => {
    navigate(RoutesUrl.EventWorkshop)
  }

  const viewExhibition = () => {
    navigate(RoutesUrl.EventExhibition)
  }

  const goSearchPage = () => {
    navigate(RoutesUrl.Search)
  }

  const favoriteCallback = () => {
    getPriorityEvents()
    getNearEvents()
  }

  const imageSlides = [
    'https://www.w3schools.com/w3css/img_mountains_wide.jpg',
    'https://www.w3schools.com/w3css/img_snow_wide.jpg',
    'https://www.w3schools.com/w3css/img_nature_wide.jpg',
  ]

  return (
    <Wrapper>
      <Header title={t('discover.discover')} />

      {loading && <Loading />}

      <div className={styles.container}>
        <section className={styles.sectionSearch} onClick={goSearchPage}>
          <span className={styles.searchIcon}>
            <IoSearchOutline size={24} />
          </span>
          <input
            type="text"
            className={styles.input}
            name="keywords"
            placeholder={t('discover.search')}
          />
        </section>

        {/* Temporary disabled the banner */}
        {/* <section className={styles.sectionSlide}>
          <SlideEvents images={imageSlides} />
        </section> */}

        <section className={styles.sectionTags}>
          <span className={styles.tag} onClick={viewMusic}>
            <IoMusicalNotesOutline className={styles.tagIcon} />
            <span className={styles.txtTag}>{t('discover.music')}</span>
          </span>
          <span className={styles.tag} onClick={viewArt}>
            <GiPencilBrush className={styles.tagIcon} />
            <span className={styles.txtTag}>{t('discover.art')}</span>
          </span>
          <span className={styles.tag} onClick={viewWorkshop}>
            <MdWorkOutline className={styles.tagIcon} />
            <span className={styles.txtTag}>{t('discover.workshop')}</span>
          </span>
          <span className={styles.tag} onClick={viewExhibition}>
            <AiOutlinePicture className={styles.tagIcon} />
            <span className={styles.txtTag}>{t('discover.exhibition')}</span>
          </span>
        </section>

        <section className={styles.sectionEvent}>
          <div className={styles.rowTitle}>
            <div className={styles.rowLabel}>
              {t('discover.priority_event')}
            </div>
            <div className={styles.rowAction} onClick={viewPriority}>
              {t('discover.view_all')}
            </div>
          </div>

          <div className={styles.rowContent}>
            {priorityEvents?.length > 0 ? (
              priorityEvents?.map((event: EventModel, idx: number) => (
                <EventCard
                  key={event?.id + ' ' + idx}
                  event={event}
                  eventCardCustomStyle={styles.width236}
                  favoriteCallback={favoriteCallback}
                />
              ))
            ) : (
              <EmptyState
                imgUrl={images.dataNotFound()}
              />
            )}
          </div>
        </section>

        <section className={styles.sectionEvent}>
          <div className={styles.rowTitle}>
            <div className={styles.rowLabel}>{t('discover.near_event')}</div>
            <div className={styles.rowAction} onClick={viewNearest}>
              {t('discover.view_all')}
            </div>
          </div>

          <div className={styles.rowContentSuggest}>
            {nearestEvents?.length > 0 ? (
              nearestEvents?.map((event: any) => (
                <EventSuggestCard
                  key={event?.id}
                  event={event}
                  favoriteCallback={favoriteCallback}
                />
              ))
            ) : (
              <EmptyState
                imgUrl={images.dataNotFound()}
              />
            )}
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

export default Discover
