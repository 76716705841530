import ContactModel from "./contact/model"

export type UserContactState = {
  loading: boolean
  userContacts: UserContactModel[]
  userContact: UserContactModel | null
  setLoading: (loading: boolean) => void
  setUserContacts: (userContact: UserContactModel[]) => void
  setUserContact: (userContact: ContactModel) => void
}

export default class UserContactModel {
  public id!: string
  public userBusinessProfileId!: string
  public userId!: string
  public contactId!: string
  public eventId!: string
  public createdAt!: string
  public updatedAt!: string
  public profile!: ContactModel

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.userBusinessProfileId = data.user_business_profile_id
      this.userId = data.user_id
      this.contactId = data.contact_id
      this.eventId = data.event_id
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.profile = new ContactModel(data.profile)
    }
  }
}