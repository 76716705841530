

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { NotificationAPI } from '@/stores/notification'
import NotificationModel from '@/stores/notification/model'
import useNotificationStore from '@/stores/notification/state'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationCard } from './components/notification-card'
import useStyles from './styles'

const Notifications = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { notifications, setNotifications, loading, setLoading } = useNotificationStore()

  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = async () => {
    setLoading(true)
    const response: any = await NotificationAPI.getNotifications()
    if (response?.data?.status === 200) {
      const newNotifications = response?.data?.data?.map((item: any) => new NotificationModel(item))
      setNotifications(newNotifications)
    }
    setLoading(false)
  }

  return (
    <div className={styles.historyContent}>
      {notifications?.length ?
        notifications?.map((notification: NotificationModel) =>
          <NotificationCard notification={notification} />
        )
        :
        <EmptyState
          imgUrl={images.dataNotFound()}
        />
      }
    </div>
  )
}

export default Notifications
