
export type NotificationState = {
  loading: boolean
  notifications: NotificationModel[]
  setLoading: (loading: boolean) => void
  setNotifications: (notifications: NotificationModel[]) => void
}

export default class NotificationModel {
  public id!: string
  public fromUserId!: string
  public userId!: string
  public type!: string
  public eventId!: string
  public title!: string
  public body!: string
  public timestamp!: string
  public read!: boolean
  public data!: any
  public link!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.fromUserId = data.from_user_id
      this.userId = data.user_id
      this.type = data.type
      this.eventId = data.event_id
      this.title = data.title
      this.body = data.body
      this.timestamp = data.timestamp
      this.read = data.read
      this.data = data.data
      this.link = data.link
    }
  }
}