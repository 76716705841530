

import { Loading } from '@/components'
import { UserContactAPI } from '@/stores/user-contact'
import UserContactModel from '@/stores/user-contact/model'
import useUserContactStore from '@/stores/user-contact/state'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoSearchOutline } from 'react-icons/io5'
import { ContactCard } from './components/contact-card'
import useStyles from './styles'
import ContactModel from '@/stores/user-contact/contact/model'

const Contacts = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const { userContacts, setUserContacts } = useUserContactStore()

  useEffect(() => {
    getUserContacts()
  }, [])

  const getUserContacts = async () => {
    setLoading(true)
    const response: any = await UserContactAPI.getUserContacts()
    if (response?.data?.status === 200) {
      const newUserContacts = response?.data?.data?.map((item: any) => new UserContactModel(item))
      setUserContacts(newUserContacts)
    }
    setLoading(false)
  }

  const onChangeSearch = (e: any) => {
    setSearch(e.target.value)
  }

  return (
    <div className={styles.contacts}>
      {loading && <Loading />}

      <section className={styles.sectionSearch}>
        <span className={styles.searchIcon}>
          <IoSearchOutline size={24} />
        </span>
        <input type="text" className={styles.input} name="keywords" placeholder={t('discover.search')} value={search} onChange={onChangeSearch} />
      </section>

      <div className={styles.contactList}>
        {userContacts.map((userContact: UserContactModel) => (
          <ContactCard key={userContact?.id} userContact={userContact} />
        ))}
      </div>
    </div>
  )
}

export default Contacts
