import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  personalInfo: {
    display: 'flex',
    flexDirection: 'column',
  },

  personalTitle: {
    textTransform: 'uppercase',
    fontWeight: 500,
    marginBottom: '15px',
  },

  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },

  label: {
    fontSize: '0.8rem',
    fontWeight: '400',
    color: '#858585',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  input: {
    display: 'flex',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#131313',
    height: '40px',
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    outline: 'none',
    borderRadius: 'unset',
    '&:focus': {
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
    '&:disabled': {
      backgroundColor: '#ffffff',
      cursor: 'not-allowed',
    },
  },

  textArea: {
    height: '100px',
    color: '#858585',
    border: 'none',
    borderBottom: '1px solid #bcbcbc',
    outline: 'none',
    resize: 'none',
    '&:focus': {
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
  },

  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '40px',
  },

  radioItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
    '& label': {
      fontWeight: '400',
      color: '#8A8380',
    },
  },

  radio: {
    marginRight: '5px',
    border: 'none !important',
    '&:checked': {
      accentColor: '#FF6100',
    },
  },

  ageGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    marginTop: '10px',
  },

  ageItem: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    height: '36px',
    border: '1px solid #DADADA',
    borderRadius: '10px',
    padding: '4px 8px',
    marginRight: '15px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },

  ageItemActive: {
    border: '1px solid #FF6100',
    backgroundColor: '#F84C0B1A',
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },

  btnUpdate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: '500',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
      cursor: 'pointer',
    },
  },

  btnDisabled: {
    backgroundColor: Colors.GRAY,
    color: Colors.WHITE,
    cursor: 'not-allowed',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.GRAY,
      cursor: 'not-allowed',
    },
  },

  //Template
  cardGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '15px',
    marginBottom: '15px',
  },

  cardItem: {
    display: 'flex',
    fontSize: '0.4rem',
    width: '100%',
    overflow: 'auto'
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #DADADA',
    borderRadius: '10px',
    padding: '5px',
    marginRight: '10px',
    cursor: 'pointer',
  },

  itemActive: {
    border: '1px solid #FF6100',
  },

  cardShow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
  },

  nameGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },

  width48: {
    width: '48%',
  },

  error: {
    color: 'red',
    fontSize: '0.875rem',
    fontWeight: '400',
    marginTop: '5px',
    fontStyle: 'italic',
  },
})

export default useStyles
