import { useModal } from '@/ModalContext'
import images from '@/assets'
import { Loading, Wrapper } from '@/components'
import { EventCard } from '@/components/event-card'
import { UserAvatar } from '@/components/user-avatar'
import { envConfigs } from '@/configs'
import { BreadcrumbsConstant, Colors, RoutesUrl } from '@/constants'
import { useAppStore } from '@/stores/app'
import EnterpriseModel from '@/stores/enterprise/model'
import { EventAPI } from '@/stores/event'
import EventModel, {
  EventRulesAgeGroupNames,
  EventRulesGenderNames,
} from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import ProfessorModel from '@/stores/professor/model'
import useUserStore from '@/stores/user/state'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import { GiSandsOfTime } from 'react-icons/gi'
import { IoIosArrowBack, IoMdHeart, IoMdHeartEmpty } from 'react-icons/io'
import { IoShareOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'

const ShareModal = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { showModal } = useModal()

  const { url } = props
  const { user } = useUserStore()

  const onCancel = () => {
    modalRef.current?.close()
  }

  const shareViaMessenger = () => {
    const messengerUrl = `fb-messenger://share/?link=${encodeURIComponent(url)}`
    window.open(messengerUrl, '_blank')
  }

  const shareViaFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url,
    )}`
    window.open(facebookUrl, '_blank')
  }

  const shareViaInstagram = () => {
    const instagramUrl = `https://www.instagram.com/sharer.php?u=${encodeURIComponent(
      url,
    )}`
    window.open(instagramUrl, '_blank')
  }

  const shareViaSMS = () => {
    const smsBody = `Tham gia event tại: ${url}`
    const smsLink = `sms:?&body=${encodeURIComponent(smsBody)}`
    window.location.href = smsLink
  }

  const shareViaEmail = () => {
    const emailSubject = 'Tham gia Event'
    const emailBody = `Tham gia event tại: ${url}`
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      emailSubject,
    )}&body=${encodeURIComponent(emailBody)}`
    window.location.href = mailtoLink
  }

  const copyLinkToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = url
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    // modalRef.current?.close()
    showModal('Link copied to clipboard!')
  }

  return (
    <div className={styles.modalShare}>
      <div className={styles.close}>
        <AiOutlineClose
          size={20}
          style={{ color: '#8A8380' }}
          onClick={onCancel}
        />
      </div>
      <div className={styles.socialShare}>
        <div className={styles.socialItem} onClick={shareViaMessenger}>
          <span className={styles.socialIcon}>
            <img
              src={images.icMessenger()}
              title="Messenger"
              width={'100%'}
              height={'100%'}
            />
          </span>
          <span className={styles.txtShare}>{t('share.messenger')}</span>
        </div>
        <div className={styles.socialItem} onClick={shareViaFacebook}>
          <span className={styles.socialIcon}>
            <img
              src={images.icFacebook()}
              title="Facebook"
              width={'100%'}
              height={'100%'}
            />
          </span>
          <span className={styles.txtShare}>{t('share.messenger')}</span>
        </div>
        <div className={styles.socialItem} onClick={shareViaInstagram}>
          <span className={styles.socialIcon}>
            <img
              src={images.icInstagram()}
              title="Instagram"
              width={'100%'}
              height={'100%'}
            />
          </span>
          <span className={styles.txtShare}>{t('share.instagram')}</span>
        </div>
      </div>

      <div className={styles.otherShare}>
        <div className={styles.otherItem} onClick={shareViaSMS}>
          <span className={styles.otherIcon}>
            <img
              src={images.icSms()}
              title="Sms"
              width={'100%'}
              height={'100%'}
            />
          </span>
          <span className={styles.txtShare}>{t('share.sms')}</span>
        </div>
        <div className={styles.otherItem} onClick={shareViaEmail}>
          <span className={styles.otherIcon}>
            <img
              src={images.icEmail()}
              title="Email"
              width={'100%'}
              height={'100%'}
            />
          </span>
          <span className={styles.txtShare}>{t('share.email')}</span>
        </div>
        <div className={styles.otherItem} onClick={copyLinkToClipboard}>
          <span className={styles.otherIcon}>
            <img
              src={images.icCopy()}
              title="Copy"
              width={'100%'}
              height={'100%'}
            />
          </span>
          <span className={styles.txtShare}>{t('share.copy')}</span>
        </div>
      </div>
    </div>
  )
}

const EventDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)

  const { changeBreadcrumb } = useAppStore()
  const { user } = useUserStore()
  const { events, event, setEvents, setEvent } = useEventStore()

  const params = useParams()
  const eventId = params.id ?? ''

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.EventDetail)
    getEvents()

    if (!user) {
    }
  }, [])

  useEffect(() => {
    if (eventId) {
      getEvent(eventId)
    }
  }, [eventId])

  const getEvents = async () => {
    const response: any = await EventAPI.getEvents()
    if (response.status === 200) {
      const newEvents = response.data.data.map(
        (event: any) => new EventModel(event),
      )
      setEvents(newEvents)
    }
  }

  const getEvent = async (eventId: string) => {
    setLoading(true)
    const response = await EventAPI.getEvent(eventId)
    if (response?.status === 200) {
      const newEvent = new EventModel(response.data.data)

      Promise.all([
        EventAPI.getEventEnterprise(eventId),
        EventAPI.getEventProfessor(eventId),
      ]).then(values => {
        console.log('values', values)
        newEvent.enterprises =
          values[0]?.data?.data?.length > 0
            ? values[0]?.data?.data.map((e: any) => new EnterpriseModel(e))
            : []
        newEvent.professors =
          values[1]?.data?.data?.length > 0
            ? values[1]?.data?.data.map((p: any) => new ProfessorModel(p))
            : []
        setEvent(newEvent)
      })
    }
    setLoading(false)
  }

  const onFavorite = async () => {
    if (event?.id !== undefined) {
      const response = await EventAPI.favoriteEvent(event?.id.toString())
      if (response?.data?.status === 200) {
        getEvent(eventId)
      }
    }
  }

  const joinEvent = () => {
    navigate(RoutesUrl.EventOrder.replace(':id', eventId))
  }

  const onOpenShareModal = () => {
    modalRef.current?.open({
      content: (
        <ShareModal
          url={`${envConfigs.WEB_URL}${RoutesUrl.EventDetail.replace(
            ':id',
            eventId,
          )}`}
        />
      ),
      customModalStyles: {
        content: {
          top: 'auto',
          left: '0',
          right: '0',
          bottom: '0',
          borderRadius: '10px 10px 0 0',
        },
        overlay: {
          maxWidth: '800px',
          minWidth: '300px',
          margin: '0 auto',
          zIndex: 999,
        },
      },
    })
  }

  const onOpenMap = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${event?.location?.latitude},${event?.location?.longitude}`
    window.open(url, '_blank')
  }

  const onOpenEnterprise = (id: string) => {
    navigate(RoutesUrl.EnterpriseDetail.replace(':id', id))
  }

  const onOpenProfessor = (id: string) => {
    navigate(RoutesUrl.ProfessorDetail.replace(':id', id))
  }

  const goBack = () => {
    navigate(-1)
  }

  const createMarkup = (content: string) => {
    return { __html: content }
  }

  const parse = (content: string) => {
    return <div dangerouslySetInnerHTML={createMarkup(content)} />
  }

  const registrationDeadline = moment(event?.registrationDeadline)
  const currentTime = moment()
  const duration = moment.duration(registrationDeadline.diff(currentTime))
  let remainingTime = ''
  if (Number(duration?.asMilliseconds()) <= 0) {
    remainingTime = t('eventDetail.expired')
  } else if (Number(duration?.asMilliseconds()) > 259200000) {
    remainingTime = `${Math.floor(duration.asDays())} ${t(
      'eventDetail.days',
    )} ${Math.floor(duration.hours())} ${t('eventDetail.hours')} ${Math.floor(
      duration.minutes(),
    )} ${t('eventDetail.minutes')}`
  } else {
    remainingTime = `${Math.floor(duration.asHours())} ${t(
      'eventDetail.hours',
    )} ${Math.floor(duration.minutes())} ${t(
      'eventDetail.minutes',
    )} ${Math.floor(duration.seconds())} ${t('eventDetail.second')}`
  }

  const eventStart = event?.eventDateTimes?.length
    ? event?.eventDateTimes[0]
    : null
  const eventEnd = event?.eventDateTimes?.length
    ? event?.eventDateTimes[event?.eventDateTimes?.length - 1]
    : null

  let eventTimeShow = ''
  let eventDateShow = ''
  if (eventStart) {
    eventTimeShow = `${moment(eventStart?.startDateTime).format(
      'dddd',
    )}, ${moment(eventStart?.startDateTime).format('h:mm A')}`
    eventDateShow = `${moment(eventStart?.startDateTime).format(
      'MMMM D, YYYY',
    )}`

    if (eventEnd) {
      if (
        moment(eventStart?.startDateTime).format('YYYY-MM-DD') !==
        moment(eventEnd?.endDateTime).format('YYYY-MM-DD')
      ) {
        eventTimeShow = `${eventTimeShow} - ${moment(
          eventEnd?.endDateTime,
        ).format('dddd')}, ${moment(eventEnd?.endDateTime).format('h:mm A')}`
        eventDateShow = `${eventDateShow} - ${moment(
          eventEnd?.endDateTime,
        ).format('MMMM D, YYYY')}`
      } else {
        eventTimeShow = `${eventTimeShow} - ${moment(
          eventEnd?.endDateTime,
        ).format('h:mm A')}`
      }
    }
  }

  const disabled = Number(duration) <= 0 ? true : false
  let eligibility = true
  if (event?.rulesAgeGroup && event?.rulesAgeGroup !== user?.ageGroup) {
    eligibility = false
  }
  if (event?.rulesGender && event?.rulesGender !== user?.gender) {
    eligibility = false
  }

  const isJoined = useMemo(() => {
    if (user) {
      return event?.participants?.find((p: any) => p.userId === user.id)
    }
  }, [user])

  return (
    <Wrapper>
      {loading && <Loading />}

      <div className={styles.container}>
        <div className={styles.header}>
          <img
            src={event?.banner?.getRawUrl()}
            title="Event banner"
            width={'100%'}
            height={'100%'}
          />

          <div className={styles.rowBackShare}>
            <div className={styles.back} onClick={goBack}>
              <IoIosArrowBack size={28} style={{ color: Colors.WHITE }} />
            </div>

            <div className={styles.share} onClick={onOpenShareModal}>
              <IoShareOutline size={24} style={{ color: Colors.WHITE }} />
            </div>
          </div>

          {/* <div className={styles.rowDateFee}>
            <div className={styles.price}>
              <span className={styles.txtPrice}>{t('eventDetail.free')}</span>
            </div>
          </div> */}
        </div>

        <div className={styles.content}>
          <div className={styles.eventTitleWrapper}>
            <div className={styles.eventTitle}>{event?.title}</div>
            <div className={styles.price}>
              <span className={styles.txtPrice}>{t('eventDetail.free')}</span>
            </div>
          </div>
          <div className={styles.eventDateTimeArea}>
            <div className={styles.eventRemaining}>
              <div className={styles.iconArea}>
                <GiSandsOfTime size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.rowDateTime}>
                <div className={styles.txtRemaining}>
                  {t('eventDetail.remaining_registration_time')}
                </div>
                <div className={styles.txtRemainTime}>{remainingTime}</div>
              </div>
            </div>

            <div className={styles.eventDateTime}>
              <div className={styles.iconArea}>
                <img src={images.icCalendar()} />
              </div>
              <div className={styles.rowDateTime}>
                <div className={styles.txtDate}>{eventDateShow}</div>
                <div className={styles.txtTime}>{eventTimeShow}</div>
              </div>
            </div>

            <div className={styles.eventLocation}>
              <div className={styles.iconArea}>
                <img src={images.icLocation()} />
              </div>
              <div className={styles.rowLocation}>
                <div className={styles.txtLocation}>
                  {event?.location?.name}
                </div>
                <div className={styles.txtAddress}>
                  {event?.location?.address}
                </div>
              </div>
              <div className={styles.viewMap} onClick={onOpenMap}>
                {t('eventDetail.view_map')}
              </div>
            </div>

            {/* hidden */}
            {/* <div className={styles.eventGroupMember}>
              <div className={styles.iconArea}>
                <img src={images.icParticipant()} />
              </div>
              <div className={styles.eventMemberList}>
                {event?.joinedParticipants?.slice(0, 3)?.map((user: any, idx: number) => (
                  <AttendedAvatar key={idx + ''} user={user} style={idx > 0 ? styles.ml20Sub : ''} />
                ))}
              </div>
              <span className={styles.textMember}> {event?.joinedParticipants?.length} {t('eventDetail.attendees')}</span>
            </div> */}
          </div>

          <div className={styles.eventDescription}>
            <div className={styles.rowLabel}>
              {t('eventDetail.about_event')}
            </div>
            <div className={styles.rowDescription}>
              {event?.content ? parse(event?.content) : ''}
            </div>
          </div>

          <div className={styles.eventDescription}>
            <div className={styles.rowLabel}>
              {t('eventDetail.eligibility')}
            </div>
            <div className={styles.rulesArea}>
              <div className={styles.ruleItem}>
                <div className={styles.iconRule}>
                  <img src={images.icGender()} />
                </div>
                <div className={styles.txtRule}>
                  {t('eventDetail.gender_rules')}:
                  {event?.rulesGender && (
                    <span> {t(EventRulesGenderNames[event?.rulesGender])}</span>
                  )}
                  {!event?.rulesGender && <span> {t('gender.male')}</span>}
                  {!event?.rulesGender && <span> {t('gender.female')}</span>}
                  {!event?.rulesGender && <span> {t('gender.other')}</span>}
                </div>
              </div>
              <div className={styles.ruleItem}>
                <div className={styles.iconRule}>
                  <img src={images.icAge()} />
                </div>
                <div className={styles.txtRule}>
                  {t('eventDetail.age_rules')}:
                  {event?.rulesAgeGroup && (
                    <span>
                      {' '}
                      {t(EventRulesAgeGroupNames[event?.rulesAgeGroup])}
                    </span>
                  )}
                  {!event?.rulesAgeGroup && (
                    <span> {t('eventDetail.all')}</span>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.rowDescription}>
              {event?.rulesContent ? parse(event?.rulesContent) : ''}
            </div>

            {event?.professors && event?.professors?.length > 0 && (
              <div className={styles.rowSpeakers}>
                <div className={styles.rowLabel}>
                  {t('eventDetail.speakers')}
                </div>
                <div className={styles.rowContent}>
                  {event?.professors?.map((professor: any, idx: number) => (
                    <div
                      key={idx + ''}
                      className={styles.speakerItem}
                      onClick={() => onOpenProfessor(professor?.id)}
                    >
                      <UserAvatar
                        user={professor}
                        avatarUrl={professor?.getAvatar()}
                        customClass={styles.speakerAvatar}
                        style={{ width: 60 }}
                      />
                      <div className={styles.speakerInfo}>
                        <div className={styles.speakerName}>
                          {professor.name}
                        </div>
                        <div className={styles.speakerPosition}>
                          {professor.title}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {event?.enterprises && event?.enterprises?.length > 0 && (
              <div className={styles.rowSponsors}>
                <div className={styles.rowLabel}>
                  {t('eventDetail.sponsors')}
                </div>
                <div>
                  {event?.enterprises?.map((enterprise: any, idx: number) => (
                    <div
                      key={idx + ''}
                      className={styles.sponsorItem}
                      onClick={() => onOpenEnterprise(enterprise?.id)}
                    >
                      <UserAvatar
                        user={enterprise}
                        avatarUrl={enterprise?.getAvatar()}
                        customClass={styles.sponsorAvatar}
                        style={{ width: 60 }}
                      />
                      <div className={styles.sponsorInfo}>
                        <div className={styles.sponsorName}>
                          {enterprise.name}
                        </div>
                        <div className={styles.sponsorBio}>
                          {enterprise.bio}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className={styles.sectionEvent}>
            <div className={styles.rowTitle}>
              <div className={styles.rowLabel}>
                {t('eventDetail.other_events')}
              </div>
              <div className={styles.rowAction}>
                {t('eventDetail.view_all')}
              </div>
            </div>

            <div className={styles.rowContent}>
              {events?.map(
                (event: EventModel, idx: number) =>
                  event.id !== eventId && (
                    <EventCard
                      key={idx + ''}
                      event={event}
                      eventCardCustomStyle={styles.width236}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.btnLike} onClick={onFavorite}>
          {event?.isFavorite && (
            <IoMdHeart size={20} style={{ color: Colors.PRIMARY }} />
          )}
          {!event?.isFavorite && (
            <IoMdHeartEmpty size={20} style={{ color: Colors.PRIMARY }} />
          )}
        </div>
        {isJoined ? (
          <button className={clsx('btn', styles.btnJoined)} onClick={joinEvent}>
            {t('orderDetail.btn_joined')}
          </button>
        ) : (
          <button
            className={clsx('btn', styles.btnRegister)}
            disabled={disabled || !eligibility}
            onClick={disabled || !eligibility ? undefined : joinEvent}
          >
            {disabled
              ? t('eventDetail.closed')
              : eligibility
                ? t('eventDetail.join_now')
                : t('eventDetail.not_eligible')}
          </button>
        )}
      </div>
    </Wrapper>
  )
}

export default EventDetail
