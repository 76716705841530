import images from '@/assets'
import { Loading } from '@/components'
import EmptyState from '@/components/empty-state'
import { EventSuggestCard } from '@/components/event-suggest-card'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import useUserStore from '@/stores/user/state'
import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const Upcoming = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const { upcomingEvents, setUpcomingEvents } = useEventStore()
  const [filter, setFilter] = useState<any>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useUserStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Events)
  }, [])

  useEffect(() => {
    if (user) {
      getUpcomingEvents()
    }
  }, [user, filter])

  const getUpcomingEvents = async () => {
    setLoading(true)
    const params = {
      mode: 'all',
      timeFrame: 'upcoming',
    }
    switch (filter) {
      case 0:
        params.mode = 'all'
        break
      case 1:
        params.mode = 'joined'
        break
      case 2:
        params.mode = 'favorite'
        break
      default:
        break
    }
    const response: any = await EventAPI.getUpcomingEvents(params)
    if (response.status === 200) {
      const events = response.data.data.map(
        (event: any) => new EventModel(event),
      )
      setUpcomingEvents(events)
    }
    setLoading(false)
  }

  const onChangeFilter = (filter: number) => {
    setFilter(filter)
  }

  return (
    <div className={styles.upcomingContent}>
      <div className={clsx(styles.filter)}>
        <div
          className={clsx(styles.filterItem, filter === 0 && styles.active)}
          onClick={() => onChangeFilter(0)}
        >
          {t('events.all')}
        </div>
        <div
          className={clsx(styles.filterItem, filter === 1 && styles.active)}
          onClick={() => onChangeFilter(1)}
        >
          {t('events.joined')}
        </div>
        <div
          className={clsx(styles.filterItem, filter === 2 && styles.active)}
          onClick={() => onChangeFilter(2)}
        >
          {t('events.favorite')}
        </div>
      </div>

      {loading && <Loading />}

      <div className={styles.upcomingList}>
        {upcomingEvents?.length > 0 ? (
          upcomingEvents.map((event: any) => (
            <EventSuggestCard
              key={event?.id}
              event={event}
              getData={getUpcomingEvents}
            />
          ))
        ) : (
          <EmptyState
            imgUrl={images.dataNotFound()}
          />
        )}
      </div>
    </div>
  )
}

export default Upcoming
