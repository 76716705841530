import images from '@/assets'
import { Wrapper } from '@/components'
import { UserAvatar } from '@/components/user-avatar'
import { Colors, RoutesUrl } from '@/constants'
import gateway from '@/stores/gateway'
import { UserContactAPI } from '@/stores/user-contact'
import ContactModel from '@/stores/user-contact/contact/model'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiMail, FiPhoneCall } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { IoHomeOutline, IoLocationOutline } from 'react-icons/io5'
import { PiBagSimple, PiBuildings } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'
import useUserStore from '@/stores/user/state'
import RequestSchedule from '@/components/request-schedule'
import { ScheduleTypeEnum } from '@/constants/schedule-type.enum'

const AlertModal = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isSuccess, message } = props

  const onContinue = () => {
    modalRef.current?.close()
  }

  const onTryAgain = () => {
    modalRef.current?.close()
  }

  const onCancel = () => {
    navigate(RoutesUrl.Home)
    modalRef.current?.close()
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img
          src={isSuccess ? images.success() : images.failed()}
          alt="success"
        />
      </div>

      <div className={styles.successTitle}>
        {isSuccess ? t('alert.success') : t('alert.failed')}
      </div>
      <div className={styles.successDescription}>
        {!message ? t('contact.add_contact_success') : message}
      </div>

      <div className={styles.alertAction}>
        <button
          className={clsx('btn', styles.btnOk)}
          onClick={isSuccess ? onContinue : onTryAgain}
        >
          {isSuccess ? t('alert.ok') : t('alert.try_again')}
        </button>
        {!isSuccess && (
          <button className={clsx('btn', styles.btnCancel)} onClick={onCancel}>
            {t('alert.cancel')}
          </button>
        )}
      </div>
    </div>
  )
}

const PublicProfile = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { user } = useUserStore()

  const { id } = params

  const [contact, setContact] = useState<ContactModel>()
  const [openRequestSchedule, setOpenRequestSchedule] = useState(false)

  useEffect(() => {
    if (id) {
      console.log(user, id, user?.id === id)
      if (!user)
        navigate(
          RoutesUrl.SignInCallback.replace(
            ':callback',
            window.location.pathname,
          ),
        )
      getUserContact(id)
    }
  }, [id])

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken,
    )
    if (token) {
      gateway.init(JSON.parse(token))
    }
  }, [])

  const getUserContact = async (id: string) => {
    const response: any = await UserContactAPI.getPublicProfile(id)
    if (response?.data?.status === 200) {
      const newUserContact = new ContactModel(response?.data?.data)
      setContact(newUserContact)
    } else {
      navigate(RoutesUrl.Home)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  const onHome = () => {
    navigate(RoutesUrl.Home)
  }

  const onShowAlertModal = (
    isSuccess: boolean,
    message: string,
    contact_id: string,
  ) => {
    modalRef.current?.open({
      content: (
        <AlertModal
          isSuccess={isSuccess}
          message={message}
          contact_id={contact_id}
        />
      ),
    })
  }

  const onSaveContact = async () => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken,
    )
    if (!token) {
      navigate(RoutesUrl.SignIn)
      return
    }

    let isSuccess = false
    let message = ''
    const data = {
      userBusinessProfileId: contact?.id ?? '',
    }

    const response = await UserContactAPI.addUserContact(data)
    if (response?.status === 200) {
      isSuccess = true
      message = t('contact.add_contact_success')
    } else {
      isSuccess = false
      message = response?.data?.data?.message ?? t('contact.add_contact_failed')
    }
    onShowAlertModal(isSuccess, message, contact?.id ?? '')
  }

  const onRequestScheduleSuccess = () => {
    setOpenRequestSchedule(false)
  }

  return (
    <Fragment>
      {openRequestSchedule && (
        <RequestSchedule
          type={ScheduleTypeEnum.business}
          onClose={onRequestScheduleSuccess}
          id={id}
        />
      )}
      <Wrapper>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.bgPen}></div>

            <div className={styles.rowBackShare}>
              <div className={styles.back} onClick={onBack}>
                <IoIosArrowBack size={28} style={{ color: Colors.PRIMARY }} />
              </div>

              <div className={styles.share} onClick={onHome}>
                <IoHomeOutline size={24} style={{ color: Colors.PRIMARY }} />
              </div>
            </div>

            <div className={styles.avatarGroup}>
              <UserAvatar user={contact} />
              <div className={styles.nameArea}>
                <div className={styles.name}>{contact?.name}</div>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <FiPhoneCall size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>{contact?.businessPhone}</div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <FiMail size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>{contact?.businessEmail}</div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <PiBuildings size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>{contact?.companyName}</div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <PiBagSimple size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>{contact?.companyTitle}</div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <IoLocationOutline
                  size={24}
                  style={{ color: Colors.PRIMARY }}
                />
              </div>
              <div className={styles.infoText}>{contact?.companyAddress}</div>
            </div>

            <div className={styles.rowIntro}>
              <div className={styles.rowTitle}>
                {t('contact.company_intro')}
              </div>
              <div className={styles.introText}>
                {contact?.companyDescription}
              </div>
            </div>
          </div>
          {user && contact && user?.id !== contact.user_id && (
            <div className={styles.footer}>
              <button
                className={clsx('btn', styles.btnCancel)}
                onClick={() => {
                  setOpenRequestSchedule(true)
                }}
              >
                {t('schedule.requestMeeting')}
              </button>
              <button
                className={clsx('btn', styles.btnAdd)}
                disabled={false}
                onClick={onSaveContact}
              >
                {t('contact.add_contact')}
              </button>
            </div>
          )}
        </div>
      </Wrapper>
    </Fragment>
  )
}

export default PublicProfile
