import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    overflow: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '0.25em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
    zIndex: 0,
    padding: '20px',
    height: '250px',
  },

  bgPen: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    clipPath: 'polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0)',
    backgroundColor: '#F84C0B',
  },

  rowBackShare: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    top: 0,
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  share: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)',
    borderRadius: '50%',
  },

  avatarGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    bottom: '-80px',
  },

  avatar: {
    width: '96px',
    height: '96px',
    border: '1px solid #bcbcbc',
    marginRight: '5px',
    borderRadius: '8px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
  },

  nameArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '10px',
  },

  name: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#F84C0B',
    textAlign: 'center',
    marginBottom: '5px',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    padding: '15px',
    marginTop: '80px',
    overflow: 'auto',
  },

  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },

  infoText: {
    fontSize: '16px',
    color: '#131313',
    fontWeight: 400,
    marginLeft: '10px',
  },

  iconArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: '#FDA78733',
    padding: '6px',
    borderRadius: '8px',
  },

  rowIntro: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    color: '#131313',
    fontWeight: 600,
  },

  introText: {
    fontSize: '16px',
    color: '#131313',
    fontWeight: 400,
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    width: '100%',
    zIndex: 1,
  },

  btnAdd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    padding: '10px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: Colors.PRIMARY,
      color: Colors.WHITE,
    },
  },

  //alert
  alertModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    },
  },

  successImg: {
    display: 'flex',
    width: '250px',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      width: '135px',
    },
  },

  successTitle: {
    color: Colors.PRIMARY,
    fontSize: '20px',
    fontWeight: '500',
  },

  successDescription: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '15px',
  },

  groupBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },

  btnOk: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',

    '&:hover': {
      backgroundColor: Colors.PRIMARY,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: Colors.PRIMARY,
      color: '#fff',
    },
  },

  btnCancel: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },
  actionsGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
  },
})

export default useStyles
