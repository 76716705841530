import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  contacts: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  sectionSearch: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    position: 'relative',
  },

  input: {
    display: 'flex',
    flex: 1,
    height: '40px',
    borderRadius: '10px',
    border: '1px solid #E8E8E8',
    paddingLeft: '2.45rem',
    paddingRight: '15px',
    fontSize: '16px',
    fontWeight: '600',
    color: '#000000',
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width: 768px)': {
      fontSize: '13px',
    },
  },

  searchIcon: {
    color: Colors.PRIMARY,
    width: '2.25rem',
    height: '2.25rem',
    position: 'absolute',
    padding: '6px 10px',
  },
})

export default useStyles
