import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  close: {
    display: 'flex',
    cursor: 'pointer',
    padding: '5px',
  },

  modalHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '10px',
    borderBottom: `1px solid #E1DFDD`,
  },

  modalTitle: {
    display: 'flex',
    flex: 1,
    fontSize: '1rem',
    fontWeight: '500',
    color: Colors.PRIMARY,
  },

  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: '15px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
  },

  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
  },
})

export default useStyles