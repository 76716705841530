import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  profileCard: {
    display: 'flex',
    width: '100%',
    minHeight: '135px',
    boxShadow: '-1px 1px 16px 0px rgba(0, 0, 0, 0.08)',
  },

  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    padding: '20px',
  },

  cardName: {
    fontWeight: '500',
    color: '#F84C0B',
    marginBottom: '2px',
  },

  cardPosition: {
    fontWeight: '400',
    color: '#131313',
    marginBottom: '2px',
  },

  hrLine: {
    width: '80%',
    height: '1px',
    backgroundColor: '#F84C0B',
    marginBottom: '2px',
  },

  cardPhone: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    color: '#F84C0B',
    marginBottom: '2px',
  },

  cardEmail: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    color: '#F84C0B',
    marginBottom: '2px',
  },

  cardAddress: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontWeight: '400',
    color: '#F84C0B',
    marginBottom: '2px',
  },

  iconArea: {
    display: 'flex',
    width: '12px',
    height: '12px',
    padding: '2px',
    backgroundColor: '#FEEDE7',
    borderRadius: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '2px',
  },

  qrSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    inset: '0% 45% 0% 45% round 10px',
    zIndex: 0,
    position: 'relative',
    padding: '20px',
  },

  qrCodeContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(248, 76, 11, 0.80)',
    clipPath: 'polygon(50% 0, 100% 0%, 100% 100%, 50% 100%)',
  },

  qrCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    width: '60px',
    height: '60px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    zIndex: 1,
  }
})

export default useStyles
