import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  eventCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    padding: '10px',
    boxShadow: '0px 0px 18px -3px #0000001A',
    marginRight: '15px',
    marginTop: '15px',
    cursor: 'pointer',
  },

  eventBanner: {
    width: '100%',
    borderRadius: '10px',
    position: 'relative',
    height: '136px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '10px',
    },
  },

  eventCalendar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 10,
    top: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '5px 10px',
    borderRadius: '10px',
    fontWeight: '600',
    color: Colors.PRIMARY,
  },

  eventDate: {
    fontSize: '16px',
  },

  eventMonth: {
    fontSize: '14px',
  },

  eventShare: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '10px',
    borderRadius: '10px',
  },

  eventTitle: {
    width: '100%',
    justifyContent: 'flex-start',
    fontWeight: '600',
    paddingTop: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  eventJoined: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: '5px',
  },

  eventMemberList: {
    display: 'flex',
    position: 'relative'
  },

  eventMember: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    zIndex: 2,
  },

  eventMember2: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginLeft: -10,
    zIndex: 1,
  },

  eventMember3: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    marginLeft: -10,
    marginRight: 10,
    zIndex: 0,
  },

  textMember: {
    fontSize: '12px',
    color: '#8A8A8A'
  },

  eventAction: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: '5px',
  },

  eventAddress: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },

  eventAddressName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    color: '#8A8A8A',
  },

  eventAddressIcon: {
    display: 'flex'
  },

  ml20Sub: {
    marginLeft: -20,
    width: '24px',
    height: '24px',
  },

  ml0Sub: {
    width: '24px',
    height: '24px',
  },
})

export default useStyles
