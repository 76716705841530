import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
    historyContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    }
})

export default useStyles
