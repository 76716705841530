import { Colors, RoutesUrl } from '@/constants'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import clsx from 'clsx'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IoMdHeart, IoMdHeartEmpty } from 'react-icons/io'
import { MdIosShare, MdLocationPin } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AttendedAvatar } from '../attended-avatar'
import useStyles from "./styles"

interface EventCardProps {
  event?: EventModel
  eventCardCustomStyle?: any
  getData?: () => void
  favoriteCallback?: () => void
}

export const EventCard = (props: EventCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const { event, eventCardCustomStyle, getData, favoriteCallback } = props

  const goDetail = () => {
    if (event?.id) {
      navigate(RoutesUrl.EventDetail.replace(':id', event.id.toString()))
    }
  }

  const onFavorite = async () => {
    if (event?.id !== undefined) {
      const response = await EventAPI.favoriteEvent(event?.id.toString())
      if (response?.data?.status === 200) {
        getData && getData()
        favoriteCallback && favoriteCallback()
      }
    }
  }

  const eventDateTime = event?.eventDateTimes?.find((eventDateTime: any) => moment(eventDateTime?.startDateTime).isSameOrAfter(moment())) || event?.eventDateTimes?.[0]

  return (
    <div className={clsx(styles.eventCard, eventCardCustomStyle)} >
      <div className={styles.eventBanner} onClick={goDetail}>
        <img src={event?.banner?.getUrl()} title='Event banner' width={'100%'} height={'100%'} />
        <div className={styles.eventCalendar}>
          <div className={styles.eventDate}>
            {moment(eventDateTime?.startDateTime).format('DD')}
          </div>
          <div className={styles.eventMonth}>
            {moment(eventDateTime?.startDateTime).format('MMM')}
          </div>
        </div>
        <div className={styles.eventShare}>
          <MdIosShare size={20} style={{ color: Colors.PRIMARY, fontWeight: '600' }} />
        </div>
      </div>
      <div className={styles.eventTitle} onClick={goDetail}>
        {event?.title}
      </div>
      
      {/* hidden */}
      {/* <div className={styles.eventJoined}>
        <div className={styles.eventMemberList}>
          {event?.joinedParticipants?.slice(0, 3)?.map((user: any, idx: number) => (
            <AttendedAvatar key={idx + ''} user={user} style={idx > 0 ? styles.ml20Sub : styles.ml0Sub} />
          ))}
        </div>
        <span className={styles.textMember}> {event?.joinedParticipants?.length} {t('eventDetail.attendees')}</span>
      </div> */}

      <div className={styles.eventAction}>
        <div className={styles.eventAddress}>
          <span className={styles.eventAddressIcon}>
            <MdLocationPin size={20} style={{ color: Colors.PRIMARY }} />
          </span>
          <span className={styles.eventAddressName}>
            {event?.location?.address}
          </span>
        </div>
        <div className={styles.eventLike} onClick={onFavorite}>
          {event?.isFavorite && <IoMdHeart size={20} style={{ color: Colors.PRIMARY }} />}
          {!event?.isFavorite && <IoMdHeartEmpty size={20} style={{ color: Colors.PRIMARY }} />}
        </div>
      </div>
    </div>
  )
}
