export default {
  english: 'English',
  vietnamese: 'Tiếng Việt',
  logout: 'Logout',
  dataNotFound: 'Data not found',
  home: {
    home: 'Home',
    event: 'Event',
    mine: 'Mine',
    activity: 'Activity',
    settings: 'Settings',
    discover: 'Discover',
    notFound: 'Not found',
  },
  signIn: {
    sign_in: 'Sign In',
    sign_in_with_zalo: 'Sign in with Zalo',
    email: 'Email',
    password: 'Password',
    btn_sign_in: 'Sign in',
    or: 'Or',
    facebook: 'Facebook',
    twitter: 'Twitter',
    sign_up: 'Sign up',
    forgot_password: 'Forgot password',
    dont_have_an_account: "Don't have an account?",
    continue: 'Continue',
    back_to_home: 'Back to home',
    can_not_login_via_zalo: 'Can not sign in with Zalo',
    please_enter_email_or_phone: 'Please enter email or phone number',
    email_is_invalid: 'Email is invalid',
    phone_is_invalid: 'Phone number is invalid',
  },
  signUp: {
    sign_up: 'Sign Up',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    password: 'Password',
    confirm_password: 'Confirm password',
    btn_sign_up: 'Sign up',
    or: 'Or',
    facebook: 'Facebook',
    twitter: 'Twitter',
    sign_in: 'Sign in',
    have_an_account: 'Do you already have an account?',
  },
  forgotPassword: {
    forgot_password: 'Forgot password',
    email: 'Email',
    forgot_password_description: 'Enter your email to receive the OTP',
    forgot_password_send_otp: 'Send OTP to email',
    forgot_password_check_mail: 'Please check the OTP in your mailbox.',
    forgot_password_expires: 'OTP code will expire in 10 minutes',
    resend: 'Resend in',
    enter_new_password: 'Enter new password',
    reset_password: 'Reset password',
    password: 'Password',
    confirm_password: 'Confirm password',
  },
  profile: {
    personal_info: 'Personal',
    company_info: 'Business',
    first_name: 'First name',
    last_name: 'Last name',
    name: 'Fullname',
    phone: 'Phone',
    email: 'Email',
    address: 'Address',
    gender: 'Gender',
    age: 'Age',
    company_name: 'Company name',
    title: 'Title',
    business_email: 'Business email',
    business_phone: 'Business phone',
    company_introduction: 'Company introduction',
    choose_card_template: 'Choose a name card template',
    btn_update: 'Update',
    male: 'Male',
    female: 'Female',
    other: 'Other',
    under: 'Under',
    above: 'above',
    under_16: 'Under 16',
    from_17_30: '17 - 30',
    from_31_45: '31 - 45',
    above_45: 'Above 45',
    add_profile: 'Add profile',
    update_profile: 'Update profile',
    business_card: 'Business card',
    contacts: 'Contacts',
    add_new_profile: 'Add new profile',
    btn_add: 'Add',
  },
  changePassword: {
    change_password: 'Change password',
    current_password: 'Current password',
    current_password_placeholder: 'Enter your current password',
    new_password: 'New password',
    new_password_placeholder: 'Enter new password',
    confirm_password: 'Confirm password',
    confirm_password_placeholder: 'Enter confirm password',
    save: 'Save',
    success: 'Success',
    change_password_success: 'Your password has been changed successfully',
    change_password_failed: 'Change password failed',
    try_again: 'Try again',
    ok: 'Ok',
    cancel: 'Cancel',
    current_password_required: 'Current password is required',
    new_password_required: 'New password is required',
    confirm_password_required: 'Confirm password is required',
    confirm_password_not_match: 'Confirm password does not match',
    new_password_min_length:
      'New password must be at least {{length}} characters',
  },
  helpCenter: {
    help_center: 'Help center',
  },
  contactUs: {
    contact_us: 'Contact us',
    subject: 'Subject',
    message: 'Message',
    send_message: 'Send message',
    we_ready_to_contact: 'We are ready to contact',
  },
  orderDetail: {
    order_detail: 'Order detail',
    date: 'Date',
    attendees: 'Attendees',
    note: 'Note',
    share_contact_info: 'Share contact information',
    name: 'Name',
    phone: 'Phone',
    btn_continue: 'Continue',
    alert_complete_profile:
      'Please complete at least one business profile to continue',
    btn_joined: 'Joined',
  },
  alert: {
    title: 'Warning',
    congratulations: 'Congratulations',
    join_event_success: 'You have successfully joined the event',
    join_event_failed: 'Join event failed',
    view_e_ticket: 'View E-Ticket',
    back_to_home: 'Back to home',
    continue: 'Continue',
    oops_sorry: 'Oops, Sorry!',
    success: 'Success',
    failed: 'Oops, Failure!',
    ok: 'Ok',
    cancel: 'Cancel',
    try_again: 'Try again',
    system_error: 'System error',
    choose_to_add_calendar: 'Choose to add to calendar',
    back: 'Back',
  },
  viewTicket: {
    view_ticket: 'View ticket',
    event_type: 'Check in type',
    event_seat: 'Seat',
    event_date: 'Date',
    event_time: 'Time',
    event_location: 'Event location',
    ticket_id: 'Ticket ID',
    ticket_description: 'Show Qr for Checkin at the event',
    ticket_download: 'Download ticket',
    add_calendar: 'Add to calendar',
    participant: 'Participant',
    sponsor: 'Sponsor',
  },
  errors: {
    error: 'Error',
    required: 'This field is required',
    invalid_email: 'Invalid email',
    invalid_phone: 'Invalid phone number',
  },
  changeLanguage: {
    change_language: 'Change language',
    language: 'Language',
    english: 'English',
    vietnamese: 'Vietnamese',
    done: 'Done',
    choose_language: 'Choose language',
  },
  settings: {
    settings: 'Settings',
    primacy_city: 'Primacy city',
    copy_to_calendar: 'Copy events to calendar',
    language: 'Language',
    change_password: 'Change password',
    support: 'Support',
    help_center: 'Help center',
    terms_of_service: 'Terms of service',
    logout: 'Logout',
    deleteCookie: 'Delete cookies',
  },
  logout: {
    logout: 'Logout',
    logout_description: 'Are you sure you want to logout?',
    cancel: 'Cancel',
    ok: 'Ok',
  },
  eventDetail: {
    event_detail: 'Event detail',
    remaining_registration_time: 'Remaining registration time',
    free: 'Free',
    view_map: 'View map',
    participants: 'participants',
    about_event: 'About event',
    eligibility: 'Eligibility',
    other_events: 'More event like this',
    view_all: 'View all',
    join_now: 'Join now',
    expired: 'Expired',
    gender_rules: 'Gender',
    age_rules: 'Age',
    all: 'All',
    not_eligible: 'Not eligible',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    second: 'second',
    attendees: 'attendees',
    closed: 'Closed',
    speakers: 'Speakers',
    sponsors: 'Sponsors',
  },
  gender: {
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  ruleAge: {
    child: 'Child',
    young_adults: 'Young Adults',
    middle_aged_adults: 'Middle Aged Adults',
    old_aged_adults: 'Old Aged Adults',
  },
  discover: {
    discover: 'Discover',
    music: 'Music',
    art: 'Art',
    workshop: 'Workshop',
    exhibition: 'Exhibition',
    priority_event: 'Priority event',
    near_event: 'Event near you',
    view_all: 'View all',
    search: 'Search',
    filter: 'Filter',
    location: 'Location',
    categories: 'Categories',
    all: 'All',
    festival: 'Festival',
    date: 'Date',
    start_date: 'Start date',
    end_date: 'End date',
  },
  events: {
    events: 'Events',
    event: 'Event',
    upcoming: 'Upcoming',
    history: 'History',
    all: 'All',
    joined: 'Joined',
    favorite: 'Favorite',
  },
  contact: {
    add_contact: 'Save contact',
    add_contact_success: 'Save contact success',
    add_contact_failed: 'Save contact failed',
    saved: 'Saved',
    company_intro: 'Company introduction',
  },
  share: {
    messenger: 'Messenger',
    facebook: 'Facebook',
    instagram: 'Instagram',
    sms: 'SMS',
    email: 'Email',
    copy: 'Copy',
    more: 'More',
  },
  enterprise: {
    bio: 'Bio',
    data: 'Description',
  },
  schedule: {
    requestSchedule: 'Request schedule',
    requestMeeting: 'Request meeting',
    time: 'Time',
    note: 'Note',
    send: 'Send',
    noteRequired: 'Note is required',
    cancel: 'Cancel',
    successTitle: 'Success',
    successText: 'Request schedule success',
    requestError: 'Request schedule failed',
  },
}
