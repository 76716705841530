
import { Wrapper } from '@/components'

import { EventCard } from '@/components/event-card'
import Header from '@/components/wrapper/components/header'
import { Colors } from '@/constants'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatePicker from "react-datepicker"
import { useTranslation } from 'react-i18next'
import { IoCalendarClearOutline } from 'react-icons/io5'
import { TbFilterCog } from 'react-icons/tb'
import useStyles from './styles'

interface ViewAllProps {
  isShowSearch?: boolean
  isShowFilter?: boolean
}

const Filter = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { callback } = props

  const [state, setState] = useState({
    location: '',
    startDate: new Date(),
    endDate: '',
    type: 0,
  })
  const handleStateChange = (value: string | string[] | boolean | number, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  const onChangeInput = (e: any) => handleStateChange(e.target.value, 'location')

  const handleChangeTag = (type: number) => handleStateChange(type, 'type')

  const handleStartDateChange = (date: any) => handleStateChange(date, 'startDate')

  const handleEndDateChange = (date: any) => handleStateChange(date, 'endDate')

  const handleSearch = () => {
    const params = {
      location: state.location,
      startDate: state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : '',
      endDate: state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : '',
      type: state.type,
    }

    callback && callback(params)
    modalRef.current?.close()
  }

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <div className={styles.modalTitle}>{t('discover.filter')}</div>
      </div>
      <div className={styles.modalBody}>
        {/* <div className={styles.inputGroup}>
          <label className={styles.label}>{t('discover.location')}</label>
          <div className={styles.inputIcon}>
            <input type="text" placeholder={t('discover.location')} className={styles.input} value={state.location} onChange={onChangeInput} />
            <div className={styles.iconAre}>
              <IoLocationOutline size={20} />
            </div>
          </div>
        </div> */}

        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('discover.categories')}</label>
          <div className={clsx(styles.rowTag)}>
            <div className={clsx(styles.tagItem, state.type === 0 && styles.active)} onClick={() => handleChangeTag(0)}>{t('discover.all')}</div>
            <div className={clsx(styles.tagItem, state.type === 1 && styles.active)} onClick={() => handleChangeTag(1)}>{t('discover.art')}</div>
            <div className={clsx(styles.tagItem, state.type === 2 && styles.active)} onClick={() => handleChangeTag(2)}>{t('discover.workshop')}</div>
            <div className={clsx(styles.tagItem, state.type === 3 && styles.active)} onClick={() => handleChangeTag(3)}>{t('discover.exhibition')}</div>
            <div className={clsx(styles.tagItem, state.type === 4 && styles.active)} onClick={() => handleChangeTag(4)}>{t('discover.festival')}</div>
            <div className={clsx(styles.tagItem, state.type === 5 && styles.active)} onClick={() => handleChangeTag(5)}>{t('discover.music')}</div>
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>{t('discover.date')}</label>
          <div className={styles.fRow}>
            <div className={styles.inputDate}>
              <DatePicker
                selected={state.startDate}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText={t('discover.start_date')}
                className={styles.input}
                wrapperClassName={styles.datePicker}
              />
              <div className={styles.iconAre}>
                <IoCalendarClearOutline size={20} />
              </div>
            </div>

            <div className={clsx(styles.inputDate, styles.ml10)}>
              <DatePicker
                selected={state.endDate}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                minDate={state.startDate}
                placeholderText={t('discover.end_date')}
                className={styles.input}
                wrapperClassName={styles.datePicker}
              />
              <div className={styles.iconAre}>
                <IoCalendarClearOutline size={20} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.btnGroup}>
          <div className={styles.btnSearch} onClick={handleSearch}>{t('discover.search')}</div>
        </div>
      </div>
    </div>
  )
}

const SearchPage = (props: ViewAllProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [filter, setFilter] = useState({
    keyword: '',
    location: '',
    startDate: new Date(),
    endDate: '',
    type: 0,
  })

  const { events, setEvents } = useEventStore()

  useEffect(() => {
    getEvents(filter)
  }, [filter])

  const getEvents = async (filter: any) => {
    const response: any = await EventAPI.getEvents(filter)
    if (response.status === 200) {
      const events = response.data.data.map((event: any) => new EventModel(event))
      setEvents(events)
    }
  }

  const onChangeKeyword = (e: any) => {
    setFilter({ ...filter, keyword: e.target.value })
  }

  const onSearch = (params: any) => {
    setFilter(params)
  }

  const onShowFilter = () => {
    modalRef.current?.open({
      content: <Filter callback={onSearch} />,
    })
  }

  return (
    <Wrapper>
      <Header
        title=' '
        showBack={true}
        right={
          <div className={styles.btnFilter} onClick={onShowFilter}>
            <TbFilterCog size={20} style={{ color: Colors.PRIMARY }} />
          </div>
        }
      />
      <div className={styles.container}>
        <section className={styles.sectionSearch}>
          <input type="text" className="form-control" name="keywords" placeholder={t('discover.search')} value={filter.keyword} onChange={onChangeKeyword} />
        </section>

        <section className={styles.sectionEvents}>
          {events.map((event: EventModel) => (
            <div className={styles.eventCardBox}>
              <EventCard event={event} eventCardCustomStyle={styles.width100} />
            </div>
          ))}
        </section>
      </div>
    </Wrapper>
  )
}

export default SearchPage
