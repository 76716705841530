
import { Wrapper } from '@/components'

import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import Events from './events'
import Notifications from './notifications'
import useStyles from './styles'

const Activities = () => {
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const [tab, setTab] = useState<any>(0)


  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Activity)
  }, [])

  const onChangeTab = (tab: number) => {
    setTab(tab)
  }

  return (
    <Wrapper>
      <Header
        title='Activities'
        bottom={
          <div className={styles.headerTab}>
            <div className={clsx(styles.tabItem, tab === 0 && styles.tabActive)} onClick={() => onChangeTab(0)}>Notifications</div>
            <div className={clsx(styles.tabItem, tab === 1 && styles.tabActive)} onClick={() => onChangeTab(1)}>Events</div>
          </div>
        }
      />
      <div className={styles.container}>
        {tab === 0 && <Notifications />}
        {tab === 1 && <Events />}
      </div>
    </Wrapper>
  )
}

export default Activities
