import gateway, { APIMethod } from "../gateway"

import { envConfigs } from "@/configs"

const API_SERVER = envConfigs.SERVER_URL

export const addUserContact = (params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/user-contacts`, params)
}

export const getUserContacts = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/user-contacts`)
}

export const getUserContact = (id: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/user-business-profiles/${id}`)
}

export const getPublicProfile = (id: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/user-business-profiles/${id}`)
}