import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  businessCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  profiles: {
    height: '100%',
    overflow: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
  },

  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '15px',
    width: '100%',
  },

  //modal
  qrModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '75vw',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },

  groupBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },

  btnAdd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: '500',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
      cursor: 'pointer',
    },
  },

})

export default useStyles
