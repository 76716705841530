import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { UserContactState } from './model'
import { setLoading, setUserContact, setUserContacts } from './service'

const useUserContactStore = create<UserContactState>()(
  devtools(immer((set, get) => ({
    loading: false,
    userContacts: [],
    userContact: null,
    setLoading,
    setUserContacts,
    setUserContact,
  })))
)

export default useUserContactStore
