export enum BreadcrumbsConstant {
  Home = 1,
  SignIn = 2,
  SignUp = 3,
  ForgotPassword = 4,
  Events = 5,
  EventDetail = 6,
  Profile = 7,
  UpdateProfile = 8,
  Activity = 9,
  Settings = 10,
  Discover = 11,
}