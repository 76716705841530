import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    overflow: 'auto',
    backgroundColor: '#F1F1F1',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  headerRight: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },

  headerRightText: {
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    color: Colors.PRIMARY,
  },

  section: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    marginTop: '15px',
  },

  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #EBE9E8',
    padding: '10px 20px',
  },

  rowTitle: {
    flex: 1,
    fontWeight: '400',
  },

  rowValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'normal',
  },

  rowRightTitle: {
    fontWeight: 'normal',
  },

  rowRightIcon: {
    fontWeight: 'normal',
    cursor: 'pointer',
  },

  orange: {
    color: Colors.PRIMARY,
    fontWeight: '400',
  },
})

export default useStyles
