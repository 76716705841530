import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import GoogleLoginButton from '@/components/google'
import { Unauthorized } from '@/components/unauthorized'
import { RoutesUrl } from '@/constants'
import OneSignalWeb from '@/OneSignal'
import { UserAPI } from '@/stores/user'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { isValidEmail } from '@/utils/validates'
import clsx from 'clsx'
import { IoIosArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'
import useStyles from './styles'

export enum Language {
  English = 'en',
  Vietnamese = 'vi',
}

interface InputState {
  loading: boolean
  username: string
  password: string
  error: any
  lang: Language
}

const SignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()
  const [searchParams] = useSearchParams()

  const callbackUrl = searchParams.get('callback') || RoutesUrl.Home

  const [state, setState] = useState<InputState>({
    loading: false,
    username: '',
    password: '',
    error: '',
    lang: Language.English,
  })
  const handleStateChange = (
    value: string | string[] | boolean,
    field: string,
  ) => setState(prevState => ({ ...prevState, [field]: value }))

  useEffect(() => {
    OneSignalWeb.init()
  }, [])

  const goBack = () => {
    navigate(RoutesUrl.Home)
  }

  const onLogin = async () => {
    handleStateChange('', 'error')
    handleStateChange(true, 'loading')

    const { username, password } = state

    const response: any = await UserAPI.login({
      username,
      password,
    })

    const isSuccess = response?.data?.isSuccess || false
    const data = response?.data?.data || null

    if (isSuccess && data) {
      localStorageUtils.setLocalStorage(
        LocalStorageValueTypes.AccessToken,
        JSON.stringify(data?.access_token),
      )
      return navigate(callbackUrl)
    } else if (response?.data.data?.message) {
      handleStateChange(response?.data.data?.message, 'error')
    }

    handleStateChange(false, 'loading')
  }

  const on3rdPartyLogin = async (response: any) => {
    const isSuccess = response?.data?.isSuccess || false
    const data = response?.data?.data || null

    if (isSuccess && data) {
      localStorageUtils.setLocalStorage(
        LocalStorageValueTypes.AccessToken,
        JSON.stringify(data?.access_token),
      )
      return navigate(callbackUrl)
    } else if (response?.data.data?.message) {
      handleStateChange(response?.data.data?.message, 'error')
    }
  }

  const onChangeInput = (e: any) => {
    handleStateChange('', 'error')
    const { name, value } = e.target
    switch (name) {
      case 'username':
        if (value.length > 50) return
        break
      case 'password':
        if (value.length > 50) return
        break
      default:
        break
    }

    handleStateChange(value, name)
  }

  const isEmail = isValidEmail(state.username)
  const disabled =
    !state.username || !isEmail || !state.password || state.loading

  return (
    <Unauthorized>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.signInPage)}>
          <div className={clsx(styles.header)}>
            <div className={clsx(styles.back)} onClick={goBack}>
              <IoIosArrowBack size={24} />
            </div>
            <div className={clsx(styles.headerTitle)}>
              {t('signIn.sign_in')}
            </div>
          </div>

          <div className={clsx(styles.content)}>
            <div className={clsx(styles.inputGroup)}>
              <input
                type="email"
                className={clsx(
                  styles.input,
                  isEmail && styles.valid,
                  state.username && !isEmail && styles.invalid,
                )}
                name="username"
                placeholder="Email"
                value={state.username}
                onChange={onChangeInput}
              />

              {state.error && (
                <div className={clsx(styles.invalidMsg)}>{state.error}</div>
              )}
            </div>

            <div className={clsx(styles.inputGroup)}>
              <input
                type="password"
                className={clsx(styles.input)}
                name="password"
                placeholder={t('signIn.password')}
                value={state.password}
                onChange={onChangeInput}
              />

              <div className={clsx(styles.forgotPassword)}>
                <Link to="/forgot-password">
                  {t('signIn.forgot_password')}?
                </Link>
              </div>
            </div>

            <div className={clsx(styles.btnGroup)}>
              <button
                type="button"
                className={clsx(
                  'btn',
                  styles.btnSignIn,
                  disabled ? styles.btnDisabled : '',
                )}
                onClick={disabled ? undefined : onLogin}
              >
                {t('signIn.btn_sign_in')}
              </button>

              <div className={clsx(styles.signUpNow)}>
                {t('signIn.dont_have_an_account')}
                <Link to="/sign-up" className={clsx(styles.txtSignUpNow)}>
                  {t('signIn.sign_up')}
                </Link>
              </div>
            </div>

            <div className={styles.hrLine}>
              <span>{t('signIn.or')}</span>
            </div>

            <div className={clsx('form-group', styles.groupSocial)}>
              {/* <span className={clsx(styles.btnSocial)} onClick={disabled ? undefined : undefined}>
              <FaFacebookSquare size={48} style={{ color: '#2374e1' }} />
            </span> */}
              {/* <div className={styles.btnSocial}>
              <FacebookLoginButton on3rdPartyLogin={on3rdPartyLogin} />
            </div> */}
              {/* <span className={clsx(styles.btnSocial)} onClick={disabled ? undefined : undefined}>
              <FcGoogle size={48} />
            </span> */}
              <div className={styles.btnSocial}>
                <GoogleLoginButton on3rdPartyLogin={on3rdPartyLogin} />
              </div>

              {/* <div className={styles.btnSocial}>
              <ZaloLoginButton />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </Unauthorized>
  )
}

export default SignIn
