import { useModal } from '@/ModalContext'
import images from '@/assets'
import { Wrapper } from '@/components'
import { EventSuggestCard } from '@/components/event-suggest-card'
import Header from '@/components/wrapper/components/header'
import { RoutesUrl } from '@/constants'
import { EventAPI } from '@/stores/event'
import EventTicketModel from '@/stores/event/event-ticket/model'
import EventDateTimesModel from '@/stores/event/event-times/model'
import EventModel, { EventParticipantType, EventParticipantTypeNames } from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import { UserAPI } from '@/stores/user'
import UserModel from '@/stores/user/model'
import useUserStore from '@/stores/user/state'
import { modalRef } from '@/utils/refs'
import { google, ics, outlook } from 'calendar-link'
import { clsx } from 'clsx'
import moment from 'moment'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { RxCalendar } from 'react-icons/rx'
import { SiApple, SiGooglecalendar, SiMicrosoftoutlook } from 'react-icons/si'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'

interface iniState {
  eventDateTimeId: string
  attendees: string
  note: string
  name: string
  phone: string
  shareContact: boolean
  joined?: boolean
}

interface EventCalendar {
  title?: string
  description?: string
  location?: string
  dateTime?: EventDateTimesModel
}

interface SuccessAlertProps {
  eventId: string
  ticketId: string
  eventCalendar: EventCalendar
}

const SuccessAlert = (props: SuccessAlertProps) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)

  const { eventId, ticketId, eventCalendar } = props

  const startDateTime = eventCalendar?.dateTime?.startDateTime ? moment(eventCalendar?.dateTime?.startDateTime).format('YYYY-MM-DDTHH:mm:ss') : ''
  const endDateTime = eventCalendar?.dateTime?.endDateTime ? moment(eventCalendar?.dateTime?.endDateTime).format('YYYY-MM-DDTHH:mm:ss') : ''

  const eventObj = {
    title: eventCalendar?.title ?? '',
    description: eventCalendar?.description ?? '',
    location: eventCalendar?.location ?? '',
    start: startDateTime,
    end: endDateTime,
  }

  const addToGoogle = () => {
    const url = google(eventObj)
    window.open(url, '_blank')
  }

  const addToOutlook = () => {
    const url = outlook(eventObj)
    window.open(url, '_blank')
  }

  const getFileICal = () => {
    const iCal = ics(eventObj); //included data:text/calendar;charset=utf-8
    const element = document.createElement('a')
    element.setAttribute('href', iCal)
    element.setAttribute('download', 'event.ics')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const goTicket = () => {
    navigate(
      RoutesUrl.EventTicket.replace(':eventId', eventId).replace(
        ':id',
        ticketId,
      ),
    )
  }

  const addToCalendar = () => {
    if (eventCalendar?.dateTime) {
      setPage(2)
    }
  }

  const backToHome = () => {
    navigate(RoutesUrl.Home)
  }

  const goBack = () => {
    setPage(1)
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img src={images.orderSuccess()} alt="success" />
      </div>

      {page === 1 && (
        <>
          <div className={styles.successTitle}>{t('alert.congratulations')}!</div>
          <div className={styles.successDescription}>
            {t('alert.join_event_success')}!
          </div>

          <button className={clsx('btn', styles.btnViewTicket)} onClick={goTicket}>
            {t('alert.view_e_ticket')}
          </button>
          <button
            className={clsx('btn', styles.btnViewTicket)}
            onClick={addToCalendar}
          >
            {t('viewTicket.add_calendar')}
          </button>
          <button className={clsx('btn', styles.btnBack)} onClick={backToHome}>
            {t('alert.back_to_home')}
          </button>
        </>
      )}

      {page === 2 && eventCalendar?.dateTime && (
        <>
          <button className={clsx('btn', styles.btnBack)} onClick={goBack}>
            {t('alert.back')}
          </button>

          <div className={styles.addCalendarTitle}>
            {t('alert.choose_to_add_calendar')}
          </div>

          <div className={styles.addCalendar}>
            <div className={clsx(styles.listBtn, 'showList')}>

              <div className={clsx(styles.btnItem)} onClick={getFileICal}>
                <span className={clsx(styles.iconItem)}>
                  <SiApple />
                </span>
                <span className={clsx(styles.btnLabel)}>Apple</span>
              </div>

              <div className={clsx(styles.btnItem)} onClick={addToGoogle}>
                <span className={clsx(styles.iconItem)}>
                  <SiGooglecalendar />
                </span>
                <span className={clsx(styles.btnLabel)}>Google</span>
              </div>

              <div className={clsx(styles.btnItem)} onClick={addToOutlook}>
                <span className={clsx(styles.iconItem)}>
                  <SiMicrosoftoutlook />
                </span>
                <span className={clsx(styles.btnLabel)}>Outlook</span>
              </div>

              <div className={clsx(styles.btnItem)} onClick={getFileICal}>
                <span className={clsx(styles.iconItem)}>
                  <RxCalendar />
                </span>
                <span className={clsx(styles.btnLabel)}>iCal File</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const FailAlert = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const goRelevant = () => {
    navigate(RoutesUrl.Home)
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img src={images.outOfTicket()} alt="failed" />
      </div>

      <div className={styles.successTitle}>{t('alert.oops_sorry')}!</div>
      <div className={styles.successDescription}>
        {t('alert.join_event_failed')}!
      </div>

      <button
        className={clsx('btn', styles.btnViewTicket)}
        onClick={goRelevant}
      >
        {t('alert.continue')}
      </button>
      <button className={clsx('btn', styles.btnBack)} onClick={goRelevant}>
        {t('alert.back_to_home')}
      </button>
    </div>
  )
}

const EventOrder = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()
  const { showModal, hideModal } = useModal()

  const params = useParams()
  const eventId = params.id ?? ''

  const { event, setEvent } = useEventStore()
  const { user } = useUserStore()

  const [state, setState] = useState<iniState>({
    eventDateTimeId: '',
    attendees: '',
    note: '',
    name: '',
    phone: '',
    shareContact: false,
    joined: false,
  })

  const handleStateChange = (
    value: string | string[] | boolean,
    field: string,
  ) => setState(prevState => ({ ...prevState, [field]: value }))

  useEffect(() => {
    if (user) {
      setState(prevState => ({
        ...prevState,
        name: user.name,
        phone: user.phone,
      }))
    }
  }, [user])

  useEffect(() => {
    if (event) {
      const firstEventDateTime: any = event?.eventDateTimes?.[0]

      const joined = event?.participants?.find(
        (item: EventTicketModel) =>
          item.eventDateTimeId === firstEventDateTime?.id,
      )

      setState(prevState => ({
        ...prevState,
        eventDateTimeId: firstEventDateTime?.id ?? '',
        attendees: '1',
        note: '',
        joined: !!joined,
      }))
    }
  }, [event])

  useEffect(() => {
    if (user && eventId) {
      getEvent(eventId)
    }
  }, [user, eventId])

  const getEvent = async (eventId: string) => {
    const response: any = await EventAPI.getEvent(eventId)
    if (response.status === 200) {
      const newEvent = new EventModel(response.data.data)
      setEvent(newEvent)
    }
  }

  const onChangeEventDateTime = (e: any) => {
    const joined = event?.participants?.find(
      (item: EventTicketModel) => item.eventDateTimeId === e.target.value,
    )
    if (joined) {
      handleStateChange(true, 'joined')
    } else {
      handleStateChange(false, 'joined')
    }
    handleStateChange(e.target.value, 'eventDateTimeId')
  }

  const onChangeParticipant = (e: any) => {
    handleStateChange(e.target.value, 'attendees')
  }

  const checkProfiles = async () => {
    const response = await UserAPI.getProfiles()
    if (response?.status === 200) {
      const newUserProfiles = response?.data?.data?.map(
        (item: any) => new UserModel(item),
      )
      if (newUserProfiles.length > 0) {
        return true
      }
    }
    return false
  }

  const goUpdateProfile = () => {
    hideModal()
    navigate(RoutesUrl.UpsertProfile, {
      state: { callbackUrl: window.location.pathname },
    })
  }

  const onSend = async () => {
    const { eventDateTimeId, attendees, note, name, phone, shareContact } =
      state
    const data: any = {
      eventDateTimeId,
      type: attendees,
      note,
    }

    if (!user)
      return navigate(RoutesUrl.SignIn, {
        state: { callbackUrl: window.location.pathname },
      })

    const checkBiz = await checkProfiles()
    if (!checkBiz) {
      showModal(
        t('orderDetail.alert_complete_profile'),
        <button
          className={clsx('btn', styles.btnContinue)}
          onClick={goUpdateProfile}
        >
          {t('orderDetail.btn_continue')}
        </button>,
      )
      return
    }

    if (shareContact) {
      data.name = name
      data.phone = phone
    }

    const response: any = await EventAPI.joinEvent(eventId, data)
    if (response.status === 200) {
      const newParticipant = response.data.data
      const ticketId = newParticipant?.id
      showSuccess(eventId, ticketId, newParticipant)
    } else {
      showFail()
    }
  }

  const showSuccess = (eventId: string, ticketId: string, participant: any) => {
    const dateTime = event?.eventDateTimes?.find(
      (item: EventDateTimesModel) => item.id === participant.event_date_time_id,
    )

    const eventCalendar: EventCalendar = {
      title: event?.title,
      description: event?.content,
      location: event?.location?.name,
      dateTime,
    }

    modalRef.current?.open({
      content: (
        <SuccessAlert
          eventId={eventId}
          ticketId={ticketId}
          eventCalendar={eventCalendar}
        />
      ),
    })
  }

  const showFail = () => {
    modalRef.current?.open({
      content: <FailAlert />,
    })
  }

  const disabled = !state.eventDateTimeId || !state.attendees

  return (
    <Wrapper>
      <Header title={t('orderDetail.order_detail')} showBack={true} />
      <div className={styles.container}>
        <div className={styles.eventSection}>
          <EventSuggestCard event={event || null} />
        </div>

        <div className={styles.orderInfo}>
          <div className={styles.inputGroup}>
            <label htmlFor="date">{t('orderDetail.date')}</label>
            <select
              className={clsx(styles.input, styles.select)}
              value={state?.eventDateTimeId}
              onChange={(e: any) => onChangeEventDateTime(e)}
            >
              {event?.eventDateTimes?.map((item: EventDateTimesModel) => (
                <option value={item?.id}>
                  {moment(item.startDateTime).format('dddd, DD MMMM YYYY')}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="attendees">{t('orderDetail.attendees')}</label>
            <select
              className={clsx(styles.input, styles.select)}
              value={state?.attendees}
              onChange={(e: any) => onChangeParticipant(e)}
            >
              {Object.keys(EventParticipantTypeNames).map((key: any) => (
                <option value={key}>{t(`orderDetail.${EventParticipantTypeNames[key]}`)}</option>
              ))}
            </select>
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="note">{t('orderDetail.note')}</label>
            <textarea
              className={styles.textArea}
              value={state.note}
              onChange={e => handleStateChange(e.target.value, 'note')}
            />
          </div>
        </div>

        {Number(state?.attendees) === EventParticipantType.Sponsor && (
          <div className={styles.contactInfo}>
            <div className={styles.inputGroup}>
              <div className="form-check">
                <input
                  type="checkbox"
                  className={clsx('form-check-input', styles.checkbox)}
                  checked={state.shareContact}
                  onChange={e =>
                    handleStateChange(e.target.checked, 'shareContact')
                  }
                />
                <label className="form-check-label">
                  {t('orderDetail.share_contact_info')}
                </label>
              </div>
            </div>

            {state.shareContact && (
              <div className={styles.groupName}>
                <div className={clsx(styles.inputGroup, styles.width50)}>
                  <label htmlFor="name">{t('orderDetail.name')}</label>
                  <input
                    type="text"
                    className={styles.input}
                    value={state.name}
                    onChange={e => handleStateChange(e.target.value, 'name')}
                  />
                </div>

                <div className={clsx(styles.inputGroup, styles.width50)}>
                  <label htmlFor="phone">{t('orderDetail.phone')}</label>
                  <input
                    type="text"
                    className={styles.input}
                    value={state.phone}
                    onChange={e => handleStateChange(e.target.value, 'phone')}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <button
          className={clsx(
            'btn',
            disabled
              ? styles.btnDisabled
              : state.joined
                ? styles.btnJoined
                : styles.btnConfirm,
          )}
          onClick={disabled || state.joined ? undefined : onSend}
        >
          {state.joined
            ? t('orderDetail.btn_joined')
            : t('orderDetail.btn_continue')}
        </button>
      </div>
    </Wrapper>
  )
}

export default EventOrder
