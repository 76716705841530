
import { Loading, Wrapper } from '@/components'

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { EventCard } from '@/components/event-card'
import Header from '@/components/wrapper/components/header'
import { EventAPI } from '@/stores/event'
import EventModel, { EventType } from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import gateway from '@/stores/gateway'
import useUserStore from '@/stores/user/state'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const ExhibitionEvents = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useUserStore()
  const { exhibitionEvents, setExhibitionEvents } = useEventStore()

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken
    )
    gateway.init(token ? JSON.parse(token) : null)
  }, [user])

  useEffect(() => {
    getExhibitionEvents()
  }, [user])

  const getExhibitionEvents = async () => {
    const params = {
      type: EventType.Exhibition
    }

    const response: any = await EventAPI.getExhibitionEvents(params)
    if (response.status === 200) {
      const events = response.data.data.map((event: any) => new EventModel(event))
      setExhibitionEvents(events)
    }
  }

  return (
    <Wrapper>
      <Header
        title={t('discover.exhibition')}
        showBack={true}
      />

      {loading && <Loading />}

      <div className={styles.container}>
        <section className={styles.sectionEvents}>
          {exhibitionEvents?.length > 0 ? exhibitionEvents?.map((event: EventModel) => (
            <div className={styles.eventCardBox}>
              <EventCard event={event} eventCardCustomStyle={styles.width100} favoriteCallback={getExhibitionEvents} />
            </div>
          ))
            :
            <EmptyState
              imgUrl={images.dataNotFound()}
            />
          }
        </section>
      </div>
    </Wrapper>
  )
}

export default ExhibitionEvents