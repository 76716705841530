import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '5px 10px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {

    },
  },

  sectionEvents: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  width100: {
    width: '100%',
  },

  eventCardBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '48.5%',
  },

  //modal filter
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    padding: '10px',
  },

  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },

  modalTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
  },

  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  label: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#858585',
    marginBottom: '10px',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: '100%',
  },

  input: {
    display: 'flex',
    padding: '10px',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#858585',
    height: '45px',
    width: '100%',
    border: 'none',
    outline: 'none',
    boxShadow: '1px 1px 16px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: 'unset',
  },

  rowTag: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  tagItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    borderRadius: '10px',
    cursor: 'pointer',
    border: `1px solid #8A8380`,
    color: '#8A8380',
    marginBottom: '15px',
    '&:not(:last-child)': {
      marginRight: '15px',
    },
  },

  active: {
    border: `1px solid ${Colors.PRIMARY}`,
    backgroundColor: '#F84C0B1A',
    color: Colors.PRIMARY,
  },

  fRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },

  ml10: {
    marginLeft: '10px',
  },

  btnSearch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    cursor: 'pointer',
    width: '100%',
  },
})

export default useStyles
