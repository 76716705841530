import OneSignal from 'react-onesignal';
import { envConfigs } from './configs';

class OneSignalWeb {

    static init = async () => {
        try {
            OneSignal.init({
                appId: envConfigs.ONE_SIGNAL_APP_ID,
                safari_web_id: envConfigs.ONE_SIGNAL_SAFARI_WEB_ID,
                allowLocalhostAsSecureOrigin: true
            });

            OneSignal.Slidedown.promptPush();
        } catch (error) {
            console.log('init', error);
        }
    }

    static login = async (userId: string) => {
        try {
            console.log('permission', OneSignal.Notifications.permission);
            if (OneSignal.Notifications.permission) {
                OneSignal.login(userId);
            } else {
                OneSignal.Notifications.addEventListener('permissionChange', async (isAllowed) => {
                    console.log('isAllowed', isAllowed);
                    if (isAllowed) {
                        OneSignalWeb.login(userId);
                    } else {
                        OneSignal.logout();
                    }
                });
            }
        } catch (error) {
            console.log('login', error);
        }
    }

    static logout = async () => {
        try {
            OneSignal.logout();
        } catch (error) {
            console.log('logout', error);
        }
    }
}

export default OneSignalWeb;