

import { BreadcrumbsConstant, RoutesUrl } from '@/constants';
import { useAppStore } from '@/stores/app';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidUser, BiUser } from 'react-icons/bi';
import { IoNotifications, IoNotificationsOutline, IoSearchOutline, IoTicket, IoTicketOutline } from 'react-icons/io5';
import { RiHomeFill, RiHomeLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import './styles.css';

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

const MenuCard = React.memo((props: any) => {
  const { title, url, active, icActive, icInActive } = props

  return (
    <li className={active ? 'active' : ''}>
      <Link to={url}>
        {!active ? icInActive : icActive}
        <span></span>
      </Link>
    </li>
  )
})

const Menu = () => {
  const { t } = useTranslation()
  const { breadcrumb } = useAppStore()

  const menus = [
    // {
    //   title: t('home.home'),
    //   url: RoutesUrl.Home,
    //   active: Number(breadcrumb) === BreadcrumbsConstant.Home,
    //   icActive: <RiHomeFill size={22} />,
    //   icInActive: <RiHomeLine size={22} />
    // },
    {
      title: t('home.event'),
      url: RoutesUrl.Events,
      active: Number(breadcrumb) === BreadcrumbsConstant.Events,
      icActive: <IoTicket size={22} style={{ transform: 'rotate(45deg)' }} />,
      icInActive: <IoTicketOutline size={22} style={{ transform: 'rotate(45deg)' }} />
    },
    {
      title: t('home.discover'),
      url: RoutesUrl.Home,
      active: Number(breadcrumb) === BreadcrumbsConstant.Discover,
      icActive: <IoSearchOutline size={22} />,
      icInActive: <IoSearchOutline size={22} />
    },
    {
      title: t('home.activity'),
      url: RoutesUrl.Activity,
      active: Number(breadcrumb) === BreadcrumbsConstant.Activity,
      icActive: <IoNotifications size={22} />,
      icInActive: <IoNotificationsOutline size={22} />
    },
    {
      title: t('home.settings'),
      url: RoutesUrl.Profile,
      active: Number(breadcrumb) === BreadcrumbsConstant.Profile,
      icActive: <BiSolidUser size={22} />,
      icInActive: <BiUser size={22} />
    },
  ]

  return (
    <nav className="menu-nav">
      <ul className="menu">
        {
          menus.map((menu: any) => (
            <MenuCard key={menu.url} {...menu} />
          ))
        }
      </ul>
    </nav>
  )
}

export default Menu
