import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  image: {
    width: '72px',
    height: '72px',
    borderRadius: '8px',
    backgroundColor: Colors.WHITE,
    alignItems: 'center',
    justifyContent: 'center'
  },

  empty: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  txtChar: {
    fontSize: '22px',
    color: Colors.PRIMARY,
  },

  border: {
    borderColor: '#1769aa',
    borderWidth: 0.8
  },
})

export default useStyles