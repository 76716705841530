import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { UserState } from './model'
import { changeUser, setLoading, setUserEdit, setUserProfiles } from './service'

const useUserStore = create<UserState>()(
  devtools(
    immer((set, get) => ({
      authLoading: false,
      user: null,
      userEdit: null,
      profiles: [],
      setLoading,
      changeUser,
      setUserEdit,
      setUserProfiles,
    })),
  ),
)

export default useUserStore
