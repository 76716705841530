import { envConfigs } from "@/configs"

// const API_SERVER = envConfigs.SERVER_URL

export type UserState = {
  user: UserModel | null
  authLoading: boolean
  userEdit: UserModel | null
  profiles: UserModel[]
  changeUser: (user: UserModel) => void
  setLoading: (loading: boolean) => void
  setUserEdit: (user: UserModel | null) => void
  setUserProfiles: (profiles: UserModel[]) => void
}

export enum genders {
  Male = 'M',
  Female = 'F',
  Other = 'O',
}

export const genderNames: any = {
  'M': 'male',
  'F': 'female',
  'O': 'other',
}

export enum ageGroupOptions {
  Child = 'C',
  YoungAdults = 'Y',
  MiddleAgedAdults = 'M',
  OldAgedAdults = 'O',
}

export const ageGroupNames: any = {
  'C': 'under_16',
  'Y': 'from_17_30',
  'M': 'from_31_45',
  'O': 'above_45',
}

export default class UserModel {
  public id!: string
  public name!: string
  public phone!: string
  public email!: string
  public username!: string
  public raw!: string
  public thumbnail!: string
  public avatarColorIdx!: number
  public level!: number
  public locale!: string
  public birthday!: string
  public bio!: string
  public gender!: string
  public ageGroup!: string
  public nameCardTemplate!: number
  public companyName!: string
  public companyTitle!: string
  public businessEmail!: string
  public businessPhone!: string
  public companyAddress!: string
  public companyDescription!: string
  public createdAt!: string
  public updatedAt!: string
  public lockedAt!: string
  public isDefault!: boolean

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.phone = data.phone
      this.email = data.email
      this.username = data.username
      this.raw = data.raw
      this.thumbnail = data.thumbnail
      this.avatarColorIdx = data.avatar_color_idx
      this.level = data.level
      this.locale = data.locale
      this.birthday = data.birthday
      this.bio = data.bio
      this.gender = data.gender
      this.ageGroup = data.age_group
      this.nameCardTemplate = data.name_card_template
      this.companyName = data.company_name
      this.companyTitle = data.company_title
      this.businessEmail = data.business_email
      this.businessPhone = data.business_phone
      this.companyAddress = data.company_address
      this.companyDescription = data.company_description
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.lockedAt = data.locked_at
      this.isDefault = data.is_default
    }
  }

  public getUrl(): string {
    return `${envConfigs.IMAGE_LINK}/users/`
  }
}