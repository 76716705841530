import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  profileCard: {
    display: 'flex',
    width: '60%',
    backgroundSize: '50% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    boxShadow: '-1px 1px 16px 0px rgba(0, 0, 0, 0.08)',
    '@media (max-width: 768px)': {
      width: '100%',
    },
    '&:hover div.showGroupBtn': {
      display: 'flex',
    },
  },

  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px',
  },

  cardName: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#F84C0B',
    marginBottom: '10px',
  },

  cardPosition: {
    fontSize: '0.8rem',
    fontWeight: '400',
    color: '#131313',
    marginBottom: '10px',
  },

  hrLine: {
    width: '80%',
    height: '1px',
    backgroundColor: '#F84C0B',
    marginBottom: '10px',
  },

  cardPhone: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#F84C0B',
    marginBottom: '10px',
  },

  cardEmail: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#F84C0B',
    marginBottom: '10px',
  },

  cardAddress: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#F84C0B',
    marginBottom: '10px',
  },

  iconArea: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '4px',
    backgroundColor: '#FEEDE7',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px',
  },

  qrSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
    zIndex: 0,
    padding: '20px',
    '@media (max-width: 768px)': {
      padding: '0px',
    },
  },

  qrCodeContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    clipPath: 'polygon(25% 0, 100% 1%, 100% 100%, 50% 100%, 0% 50%)',
    backgroundColor: 'rgba(248, 76, 11, 0.80)',
  },

  qrCodeContact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    width: '110px',
    height: '110px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    zIndex: 1,
    marginLeft: '30px',
    '@media (max-width: 768px)': {
      width: '80px',
      height: '80px',
      marginLeft: '0px',
    },
  },

  //groupBtn
  groupBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    position: 'absolute',
    top: -15,
    right: 5,
  },

  rightBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5px',
    marginLeft: '5px',
    cursor: 'pointer',
  },

  mt2: {
    marginTop: '10px',
  },
})

export default useStyles
