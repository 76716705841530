import './App.css'
import './locale'

import { Route, Routes } from 'react-router-dom'

import { useEffect } from 'react'
import Authenticated from './Authenticated'
import { RoutesUrl } from './constants'
import NotFound from './containers/not-found'
import Discover from './containers/unauthenticated/discover'
import SearchPage from './containers/unauthenticated/discover/search'
import Enterprise from './containers/unauthenticated/enterprise'
import ArtEvents from './containers/unauthenticated/events/event-art'
import EventDetail from './containers/unauthenticated/events/event-detail'
import ExhibitionEvents from './containers/unauthenticated/events/event-exhibition'
import MusicEvents from './containers/unauthenticated/events/event-music'
import NearestEvent from './containers/unauthenticated/events/event-nearest'
import PriorityEvents from './containers/unauthenticated/events/event-priority'
import WorkshopEvents from './containers/unauthenticated/events/event-workshop'
import ViewAll from './containers/unauthenticated/events/view-all'
import ForgotPassword from './containers/unauthenticated/forgot-password'
import Professor from './containers/unauthenticated/professor'
import SignIn from './containers/unauthenticated/sign-in'
import SignUp from './containers/unauthenticated/sign-up'
import PublicProfile from './containers/unauthenticated/user-contact/public-profile'
import ZaloCallback from './containers/unauthenticated/zalo-callback'
import i18next from './locale'
import { localStorageUtils } from './utils'
import { LocalStorageValueTypes } from './utils/localStorage'

function App() {
  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    const currentLang = lang ? lang : 'en'
    i18next.changeLanguage(currentLang)
    if (!lang) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, currentLang)
    }
  }, [])

  return (
    <Routes>
      <Route path={RoutesUrl.SignIn} element={<SignIn />} />
      <Route path={RoutesUrl.SignUp} element={<SignUp />} />
      <Route path={RoutesUrl.ForgotPassword} element={<ForgotPassword />} />
      <Route path={RoutesUrl.Home} element={<Discover />} />
      <Route path={RoutesUrl.Search} element={<SearchPage />} />
      <Route path={RoutesUrl.ViewAll} element={<ViewAll />} />
      <Route path={RoutesUrl.EventPriority} element={<PriorityEvents />} />
      <Route path={RoutesUrl.EventNearest} element={<NearestEvent />} />
      <Route path={RoutesUrl.EventMusic} element={<MusicEvents />} />
      <Route path={RoutesUrl.EventArt} element={<ArtEvents />} />
      <Route path={RoutesUrl.EventWorkshop} element={<WorkshopEvents />} />
      <Route path={RoutesUrl.EventExhibition} element={<ExhibitionEvents />} />
      <Route path={RoutesUrl.EventDetail} element={<EventDetail />} />
      <Route path={RoutesUrl.PublicProfile} element={<PublicProfile />} />
      <Route path={RoutesUrl.ZaloCallback} element={<ZaloCallback />} />
      <Route path={RoutesUrl.EnterpriseDetail} element={<Enterprise />} />
      <Route path={RoutesUrl.ProfessorDetail} element={<Professor />} />
      <Route path="/*" element={<Authenticated />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
