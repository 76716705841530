import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  qrScannerHeader: {
    backgroundColor: '#333',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
  },
  qrScannerBackButton: {
    background: 'none',
    border: 'none',
    color: '#fff',
    fontSize: '18px',
    cursor: 'pointer',
  },
  qrScannerTitle: {
    fontSize: '24px',
  },
  qrScannerContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  qrReaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div > video': {
      width: 'auto !important',
      height: '100% !important',
      overflow: 'unset !important',
      objectFit: 'contain !important',
      '@media (max-width: 768px)': {
        width: '100vw !important',
        height: '100vh !important',
        objectFit: 'cover !important',
      },
    },
  },
  qrReader: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  svgContainerStyle: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Màu overlay
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  svgDesc: {
    color: '#fff',
    fontSize: '14px',
    zIndex: 1,
    marginBottom: '36px',
    fontWeight: '400',
  },
  svgStyle: {
    top: '0px',
    left: '0px',
    zIndex: 1,
    width: '35%',
    objectFit: 'none',
    '@media (max-width: 768px)': {
      width: '65%',
    },
  },

  videoStyle: {
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
  },

  videoContainerStyle: {
    width: '100%',
    height: '100%',
  },

  //alert
  alertModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    },
  },

  successImg: {
    display: 'flex',
    width: '250px',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      width: '135px',
    },
  },

  successTitle: {
    color: Colors.PRIMARY,
    fontSize: '20px',
    fontWeight: '500',
  },

  successDescription: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '15px',
  },

  groupBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },

  btnOk: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },

  btnCancel: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },
})

export default useStyles
