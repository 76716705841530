

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { useAppStore } from '@/stores/app'
import { clsx } from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const Events = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const [filter, setFilter] = useState<any>(0)

  const onChangeFilter = (filter: number) => {
    setFilter(filter)
  }

  return (
    <div className={styles.upcomingContent}>
      <div className={clsx(styles.filter)}>
        <div className={clsx(styles.filterItem, filter === 0 && styles.active)} onClick={() => onChangeFilter(0)}>All</div>
        <div className={clsx(styles.filterItem, filter === 1 && styles.active)} onClick={() => onChangeFilter(1)}>Joined</div>
        <div className={clsx(styles.filterItem, filter === 2 && styles.active)} onClick={() => onChangeFilter(2)}>Favorite</div>
      </div>

      <div className={styles.upcomingList}>
        <EmptyState
          imgUrl={images.dataNotFound()}
        />
      </div>
    </div>
  )
}

export default Events
