import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  sectionSearch: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    position: 'relative',
  },

  input: {
    display: 'flex',
    flex: 1,
    height: '40px',
    borderRadius: '10px',
    border: '1px solid #E8E8E8',
    paddingLeft: '2.45rem',
    paddingRight: '15px',
    fontSize: '16px',
    fontWeight: '600',
    color: '#000000',
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width: 768px)': {
      fontSize: '13px',
    },
  },

  searchIcon: {
    color: Colors.PRIMARY,
    width: '2.25rem',
    height: '2.25rem',
    position: 'absolute',
    padding: '6px 10px',
  },

  sectionSlide: {
    display: 'flex',
    width: '100%',
    height: '350px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '15px',
  },

  sectionTags: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '15px',
    marginBottom: '15px',
  },

  tag: {
    display: 'flex',
    flexDirection: 'column',
    width: '150px',
    height: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 15px',
    boxShadow: '1px 1px 16px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '10px',
    marginRight: '10px',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      width: window.innerWidth / 5 + 'px',
      height: window.innerWidth / 5 + 'px',
    },
  },

  tagIcon: {
    color: Colors.PRIMARY,
    width: '50px',
    height: '50px',
    '@media (max-width: 768px)': {
      width: '24px',
      height: '24px',
    },
  },

  txtTag: {
    fontSize: '16px',
    color: '#A59F9C',
    marginTop: '15px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    '@media (max-width: 768px)': {
      fontSize: '13px',
      marginTop: '5px',
    },
  },

  sectionEvent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
    marginBottom: '15px',
  },

  rowTitle: {
    display: 'flex',
    flexDirection: 'row'
  },

  rowLabel: {
    display: 'flex',
    flex: 1,
    color: '#000000',
    fontWeight: '600',
  },

  rowAction: {
    display: 'flex',
    color: Colors.PRIMARY,
    fontSize: '13px'
  },

  rowContent: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '0.38em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    paddingBottom: '15px',
  },

  //rowContentSuggest
  rowContentSuggest: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
  },

  width236: {
    width: '236px',
  }
})

export default useStyles
