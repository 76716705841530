
import { Wrapper } from '@/components'

import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes, setLocalStorage } from '@/utils/localStorage'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiCheck } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'

const ChangeLanguage = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { changeBreadcrumb } = useAppStore()

  const language = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)

  const [lang, setLang] = useState('en')

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  useEffect(() => {
    const currentLang = language ? language : 'en'
    i18next.changeLanguage(currentLang)
    setLang(currentLang)
  }, [language])

  const onSetLanguage = (lang: string) => {
    setLang(lang)
  }

  const onChangeLanguage = () => {
    setLocalStorage(LocalStorageValueTypes.UserLanguage, lang)
    i18next.changeLanguage(lang)
    setLang(lang)
    navigate(-1)
  }

  return (
    <Wrapper>
      <Header
        title={t('changeLanguage.choose_language')}
        showBack={true}
        right={
          <div className={styles.headerRight}>
            <div className={styles.headerRightText} onClick={onChangeLanguage}>{t('changeLanguage.done')}</div>
          </div>
        }
      />
      <div className={styles.container}>
        <section className={styles.section}>

          <div className={styles.sectionRow} onClick={() => onSetLanguage('en')}>
            <div className={styles.rowTitle}>
              {t('changeLanguage.english')}
            </div>
            <div className={styles.rowValue}>
              <div className={styles.rowRightIcon}>
                {lang === 'en' && <FiCheck size={24} style={{ color: '#8A8380' }} />}
              </div>
            </div>
          </div>

          <div className={styles.sectionRow} onClick={() => onSetLanguage('vi')}>
            <div className={styles.rowTitle}>
              {t('changeLanguage.vietnamese')}
            </div>
            <div className={styles.rowValue}>
              {lang === 'vi' && <FiCheck size={24} style={{ color: '#8A8380' }} />}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

export default ChangeLanguage
