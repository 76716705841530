

import { Wrapper } from '@/components'
import { UserAvatar } from '@/components/user-avatar'
import { Colors, RoutesUrl } from '@/constants'
import { UserContactAPI } from '@/stores/user-contact'
import ContactModel from '@/stores/user-contact/contact/model'
import useUserStore from '@/stores/user/state'
import { useEffect, useState } from 'react'
import { FiMail, FiPhoneCall } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { IoHomeOutline, IoLocationOutline } from 'react-icons/io5'
import { PiBagSimple, PiBuildings } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'
import VCard from 'vcard-creator'
import useStyles from './styles'

const ContactDetail = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const params = useParams()

  const { id } = params

  const { user } = useUserStore()
  const [contact, setContact] = useState<ContactModel>()

  useEffect(() => {
    if (user && id) {
      getUserContact(id)
    }
  }, [user, id])

  const getUserContact = async (id: string) => {
    const response: any = await UserContactAPI.getUserContact(id)
    if (response?.data?.status === 200) {
      const newUserContact = new ContactModel(response?.data?.data)
      setContact(newUserContact)
    } else {
      // navigate(RoutesUrl.Profile)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  const onHome = () => {
    navigate(RoutesUrl.Home)
  }

  const saveContact = () => {
    // save CVF contact
    if (!contact) return;

    const card = new VCard();

    card.addName(contact.name);
    card.addPhoneNumber(contact.businessPhone, 'WORK');
    card.addEmail(contact.businessEmail);
    card.addCompany(contact.companyName);
    card.addJobtitle(contact.companyTitle);
    card.addAddress(contact.companyAddress);
    card.addNote(contact.companyDescription);

    const vcfString = card.toString();

    const blob = new Blob([vcfString], { type: 'text/vcard;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'contact.vcf';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  return (
    <Wrapper>
      <div className={styles.container}>
        <div className={styles.contactDetail}>
          <div className={styles.header}>
            <div className={styles.bgPen}></div>

            <div className={styles.rowBackShare}>
              <div className={styles.back} onClick={onBack}>
                <IoIosArrowBack size={28} style={{ color: Colors.PRIMARY }} />
              </div>

              <div className={styles.home} onClick={onHome}>
                <IoHomeOutline size={24} style={{ color: Colors.PRIMARY }} />
              </div>
            </div>


            <div className={styles.avatarGroup}>
              <UserAvatar user={contact} customClass={styles.avatar} />
              <div className={styles.nameArea}>
                <div className={styles.name}>{contact?.name}</div>
              </div>
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <FiPhoneCall size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>
                <a className={styles.phoneText} href={`tel:${contact?.businessPhone}`}>
                  {contact?.businessPhone}
                </a>
              </div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <FiMail size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>
                {contact?.businessEmail}
              </div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <PiBuildings size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>
                {contact?.companyName}
              </div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <PiBagSimple size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>
                {contact?.companyTitle}
              </div>
            </div>

            <div className={styles.rowInfo}>
              <div className={styles.iconArea}>
                <IoLocationOutline size={24} style={{ color: Colors.PRIMARY }} />
              </div>
              <div className={styles.infoText}>
                {contact?.companyAddress}
              </div>
            </div>


            <div className={styles.rowIntro}>
              <div className={styles.rowTitle}>Company introduction</div>
              <div className={styles.introText}>
                {contact?.companyDescription}
              </div>
            </div>

          </div>

          <div className={styles.footer}>
            <div className={styles.saveContactBtn}>
              <div className={styles.btnText} onClick={saveContact}>Save contact</div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ContactDetail
