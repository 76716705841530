

import { BreadcrumbsConstant, RoutesUrl } from '@/constants'
import { useAppStore } from '@/stores/app'
import { UserAPI } from '@/stores/user'
import UserModel from '@/stores/user/model'
import useUserStore from '@/stores/user/state'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Template1 from '../card/template-1'
import Template2 from '../card/template-2'
import Template3 from '../card/template-3'
import Template4 from '../card/template-4'
import useStyles from './styles'

const ShowQr = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const { qrCode } = props

  return (
    <div className={styles.qrModal}>
      <img src={qrCode} alt="qrCode" />
    </div>
  )
}

const BusinessCard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const { user, profiles, setUserProfiles, setUserEdit } = useUserStore()

  const [tab, setTab] = useState<any>(0)

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  useEffect(() => {
    if (user) {
      getProfiles()
    }
  }, [user])

  const getProfiles = async () => {
    const response = await UserAPI.getProfiles()
    if (response?.status === 200) {
      const newUserProfiles = response?.data?.data?.map((item: any) => new UserModel(item))
      setUserProfiles(newUserProfiles)
    }
  }


  const onOpenQRScanner = () => {
    navigate(RoutesUrl.QrScanner)
  }

  const onUpdateProfile = () => {
    navigate(RoutesUrl.UpdateProfile)
  }

  const onSettings = () => {
    navigate(RoutesUrl.Settings)
  }

  const getQrCodeSvg = () => {
    const svg = document.getElementById("qrCodeContact")
    if (!svg) return
    const svgData = new XMLSerializer().serializeToString(svg)
    return `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  const showTicket = () => {
    const qrCode = getQrCodeSvg()
    modalRef.current?.open({
      content: <ShowQr qrCode={qrCode} />,
    })
  }

  const addNewProfile = () => {
    setUserEdit(null)
    navigate(RoutesUrl.UpsertProfile)
  }

  const updateProfile = (user: any) => {
    setUserEdit(user)
    navigate(RoutesUrl.UpsertProfile)
  }

  const setDefault = async (user: any) => {
    const response = await UserAPI.setDefaultProfile(user?.id)
    if (response?.status === 200) {
      getProfiles()
    }
  }

  return (
    <div className={styles.businessCard}>
      <div className={styles.profiles}>
        {profiles?.sort((a: any, b: any) => b.isDefault - a.isDefault)?.map((profile: any, index: number) =>
          <div key={index} className={styles.profile}>
            {Number(profile?.nameCardTemplate) === 1 && <Template1 user={profile} userId={user?.id} showTicket={showTicket} updateProfile={() => updateProfile(profile)} setDefault={() => setDefault(profile)} />}
            {Number(profile?.nameCardTemplate) === 2 && <Template2 user={profile} userId={user?.id} showTicket={showTicket} updateProfile={() => updateProfile(profile)} setDefault={() => setDefault(profile)} />}
            {Number(profile?.nameCardTemplate) === 3 && <Template3 user={profile} userId={user?.id} showTicket={showTicket} updateProfile={() => updateProfile(profile)} setDefault={() => setDefault(profile)} />}
            {Number(profile?.nameCardTemplate) === 4 && <Template4 user={profile} userId={user?.id} showTicket={showTicket} updateProfile={() => updateProfile(profile)} setDefault={() => setDefault(profile)} />}
          </div>
        )}
      </div>

      <div className={styles.groupBtn}>
        <button type="button" className={clsx('btn', styles.btnAdd)} onClick={addNewProfile}>
          {t('profile.add_new_profile')}
        </button>
      </div>
    </div>
  )
}

export default BusinessCard
