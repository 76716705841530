
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import GoogleLoginButton from '@/components/google'
import { Unauthorized } from '@/components/unauthorized'
import { RoutesUrl } from '@/constants'
import OneSignalWeb from '@/OneSignal'
import { UserAPI } from '@/stores/user'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { validateEmail, validatePhone } from '@/utils/string'
import clsx from 'clsx'
import { IoIosArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'
import useStyles from './styles'

export enum Language {
  English = 'en',
  Vietnamese = 'vi'
}

interface InputState {
  loading: boolean
  name: string
  phone: string
  email: string
  password: string
  confirm_password: string
  error: any
  lang: Language
}

const SignUp = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const styles = useStyles()

  const callbackUrl = location?.state?.callbackUrl || RoutesUrl.Home
  console.log('currentUrl sign in', callbackUrl)

  const [state, setState] = useState<InputState>({
    loading: false,
    name: '',
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
    error: '',
    lang: Language.English,
  })
  const handleStateChange = (value: string | string[] | boolean, field: string) => setState(prevState => ({ ...prevState, [field]: value }))


  useEffect(() => {
    OneSignalWeb.init()
  }, [])

  const goBack = () => {
    navigate(-1)
  }

  const onSignUp = async () => {
    const { name, phone, email, password, confirm_password } = state

    if (!validateEmail(email)) {
      handleStateChange('Invalid email format.', 'error')
      handleStateChange(false, 'loading')

      return
    }

    if (!validatePhone(phone)) {
      handleStateChange('Invalid phone number.', 'error')
      handleStateChange(false, 'loading')

      return
    }

    if (password.trim().length < 6) {
      handleStateChange('Password must be at least 6 characters.', 'error')
      handleStateChange(false, 'loading')

      return
    } else if (password !== confirm_password) {
      handleStateChange('Password and confirm password do not match.', 'error')
      handleStateChange(false, 'loading')

      return
    }

    handleStateChange(true, 'loading')
    const response: any = await UserAPI.signup({
      name,
      email,
      phone,
      password
    })

    if (response?.data?.status === 200 && response?.data?.data?.access_token) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.AccessToken, JSON.stringify(response?.data?.data?.access_token))
      return navigate(RoutesUrl.Profile)
    } else if (response?.data?.data?.message) {
      handleStateChange(response?.data?.data?.message, 'error')
    }
    handleStateChange(false, 'loading')
  }

  const onChangeInput = (e: any) => {
    const { name, value } = e.target
    handleStateChange(value, name)
  }

  const on3rdPartyLogin = async (response: any) => {
    const isSuccess = response?.data?.isSuccess || false
    const data = response?.data?.data || null

    if (isSuccess && data) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.AccessToken, JSON.stringify(data?.access_token))
      return navigate(callbackUrl)
    } else if (response?.data.data?.message) {
      handleStateChange(response?.data.data?.message, 'error')
    }
  }

  const disabled = !state.name || !state.phone || !state.email || !state.password || !state.confirm_password || state.loading

  return (
    <Unauthorized>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.signUpPage)}>
          <div className={clsx(styles.header)}>
            <div className={clsx(styles.back)} onClick={goBack}>
              <IoIosArrowBack size={24} />
            </div>
            <div className={clsx(styles.headerTitle)}>
              {t('signUp.sign_up')}
            </div>
          </div>

          <div className={clsx(styles.content)}>
            <div className={clsx(styles.error)}>
              {state.error}
            </div>

            <div className={clsx(styles.inputGroup)}>
              <input type="text" className={clsx(styles.input)} name="name" placeholder={t('signUp.name')} value={state.name} onChange={onChangeInput} />
            </div>

            <div className={clsx(styles.inputGroup)}>
              <input type="email" className={clsx(styles.input)} name="email" placeholder="Email" value={state.email} onChange={onChangeInput} />
            </div>

            <div className={clsx(styles.inputGroup)}>
              <input type="text" className={clsx(styles.input)} name="phone" placeholder={t('signUp.phone')} value={state.phone} onChange={onChangeInput} />
            </div>

            <div className={clsx(styles.inputGroup)}>
              <input type="password" className={clsx(styles.input)} name="password" placeholder={t('signUp.password')} value={state.password} onChange={onChangeInput} />
            </div>

            <div className={clsx(styles.inputGroup)}>
              <input type="password" className={clsx(styles.input)} name="confirm_password" placeholder={t('signUp.confirm_password')} value={state.confirm_password} onChange={onChangeInput} />
            </div>

            <div className={clsx(styles.btnGroup)}>
              <button type="button" className={clsx('btn', styles.btnSignUp, disabled ? styles.btnDisabled : '')} onClick={disabled ? undefined : onSignUp}>{t('signUp.btn_sign_up')}</button>

              <div className={clsx(styles.signInNow)}>
                {t('signUp.have_an_account')}
                <Link to="/sign-in" className={clsx(styles.txtSignInNow)}>{t('signUp.sign_in')}</Link>
              </div>
            </div>

            <div className={styles.hrLine}>
              <span>{t('signUp.or')}</span>
            </div>

            <div className={clsx('form-group', styles.groupSocial)}>
              <div className={styles.btnSocial}>
                <GoogleLoginButton on3rdPartyLogin={on3rdPartyLogin} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Unauthorized>
  )
}

export default SignUp
