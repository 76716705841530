import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
    zIndex: 0,
    padding: '20px',
    height: '250px',
  },

  bgPen: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    clipPath: 'polygon(100% 0, 100% 75%, 50% 100%, 0 75%, 0 0)',
    backgroundColor: '#F84C0B',
  },

  rowBackShare: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    top: 0,
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)',
    borderRadius: '8px',
    cursor: 'pointer',
  },

  home: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)',
    borderRadius: '8px',
  },

  avatarGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
    bottom: '-70px'
  },

  avatar: {
    width: '125px',
    height: '125px',
    border: '1px solid #bcbcbc',
    marginRight: '5px',
    borderRadius: '8px',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
  },

  nameArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '10px',
  },

  name: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#F84C0B',
    textAlign: 'center',
    marginBottom: '5px',
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    padding: '15px',
    marginTop: '80px',
  },

  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },

  infoText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: '#131313',
    fontWeight: 400,
    marginLeft: '10px',
  },

  iconArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: '#FDA78733',
    padding: '6px',
    borderRadius: '8px',
  },

  rowIntro: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  rowTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    color: '#131313',
    fontWeight: 600,
  },

  introText: {
    fontSize: '16px',
    color: '#131313',
    fontWeight: 400,
  },

  phoneText: {
    fontSize: '16px',
    color: '#131313',
    fontWeight: 400,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    marginTop: '20px',
    alignItems: 'center',
  },

  saveContactBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75%',
    height: '50px',
    backgroundColor: '#F84C0B',
    borderRadius: '8px',
  },

  btnText: {
    fontSize: '16px',
    color: '#fff',
    fontWeight: 600,
  },
})

export default useStyles
