export default class EventTicketModel {
  public id!: string
  public eventId!: string
  public userId!: string
  public ticketId!: string
  public ticketCode!: string
  public eventDateTimeId!: string
  public paymentStatus!: number
  public type!: number
  public note!: string
  public contactName!: string
  public contactPhoneNumber!: string
  public createdAt!: string
  public updatedAt!: string
  public deletedAt!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.eventId = data.event_id
      this.userId = data.user_id
      this.ticketId = data.ticket_id
      this.ticketCode = data.ticket_code
      this.eventDateTimeId = data.event_date_time_id
      this.paymentStatus = data.payment_status
      this.type = data.type
      this.note = data.note
      this.contactName = data.contact_name
      this.contactPhoneNumber = data.contact_phone_number
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.deletedAt = data.deleted_at
    }
  }

}