import { RoutesUrl } from '@/constants'
import { useTranslation } from 'react-i18next'
import { MdOutlineMailOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import useStyles from "./styles"

export const ContactCard = (props: any) => {
  const { userContact } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const onGoContact = () => {
    navigate(RoutesUrl.ContactDetail.replace(':id', userContact?.userBusinessProfileId))
  }

  const profile = userContact?.profile

  return (
    <div className={styles.contactCard} onClick={onGoContact}>
      <div className={styles.contactName}>
        {profile?.name}
      </div>

      <div className={styles.contactCompany}>
        {profile?.companyName}
      </div>

      <div className={styles.contactEmail}>
        <MdOutlineMailOutline size={14} />
        <span>{profile?.businessEmail}</span>
      </div>
    </div>
  )
}
