import { Loading, Wrapper } from '@/components'

import images from '@/assets'
import EmptyState from '@/components/empty-state'
import { EventSuggestCard } from '@/components/event-suggest-card'
import Header from '@/components/wrapper/components/header'
import { EventAPI } from '@/stores/event'
import EventModel from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import gateway from '@/stores/gateway'
import useUserStore from '@/stores/user/state'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const PriorityEvents = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useUserStore()
  const { priorityEvents, setPriorityEvents } = useEventStore()

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken,
    )
    gateway.init(token ? JSON.parse(token) : null)
  }, [user])

  useEffect(() => {
    getPriorityEvents()
  }, [user])

  const getPriorityEvents = async () => {
    setLoading(true)
    const response: any = await EventAPI.getPriorityEvents({ isPriority: true })
    if (response.status === 200) {
      const events = response.data.data.map(
        (event: any) => new EventModel(event),
      )
      setPriorityEvents(events)
    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <Header title="" showBack={true} />

      {loading && <Loading />}

      <div className={styles.container}>
        <section className={styles.sectionEvents}>
          {priorityEvents?.length > 0 ? (
            priorityEvents?.map((event: EventModel) => (
              <div className={styles.eventCardBox}>
                <EventSuggestCard
                  event={event}
                  favoriteCallback={getPriorityEvents}
                />
              </div>
            ))
          ) : (
            <EmptyState
              imgUrl={images.dataNotFound()}
            />
          )}
        </section>
      </div>
    </Wrapper>
  )
}

export default PriorityEvents
