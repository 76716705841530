import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import NotFound from '@/containers/not-found'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import i18next from 'i18next'
import OneSignalWeb from './OneSignal'
import { RoutesUrl } from './constants/routes.constants'
import Activities from './containers/authenticated/activities'
import Events from './containers/authenticated/events'
import EventOrder from './containers/authenticated/events/event-order'
import EventTicket from './containers/authenticated/events/event-ticket'
import Profile from './containers/authenticated/profile'
import Contacts from './containers/authenticated/profile/contacts'
import ContactDetail from './containers/authenticated/profile/contacts/contact-detail'
import QRScanner from './containers/authenticated/profile/qr-scan'
import UpdateProfile from './containers/authenticated/profile/update-profile'
import UpsertProfile from './containers/authenticated/profile/upsert-profile'
import Settings from './containers/authenticated/settings'
import ChangeLanguage from './containers/authenticated/settings/change-language'
import ChangePassword from './containers/authenticated/settings/change-password'
import HelpCenter from './containers/authenticated/settings/help-center'
import gateway from './stores/gateway'
import { UserAPI } from './stores/user'
import UserModel, { UserState } from './stores/user/model'
import useUserStore from './stores/user/state'

function AuthenticatedApp() {
  const navigate = useNavigate()

  const [changeUser, setLoading] = useUserStore(
    (state: UserState) => [state.changeUser, state.setLoading],
    shallow
  )

  useEffect(() => {
    const lang = localStorageUtils.getLocalStorage(LocalStorageValueTypes.UserLanguage)
    const currentLang = lang ? lang : 'en'
    i18next.changeLanguage(currentLang)
    if (!lang) {
      localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserLanguage, currentLang)
    }

    checkAuthorized()
  }, [])

  const saveUser = (data: any) => {
    OneSignalWeb.login(data?.id)
    changeUser(new UserModel(data))
    localStorageUtils.setLocalStorage(LocalStorageValueTypes.UserData, JSON.stringify(data))
  }

  const authLogin = async () => {
    // get user by token
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken
    )
    if (!token) return false

    gateway.init(JSON.parse(token))
    const response: any = await UserAPI.getUserProfile()
    const { data } = response

    if (data?.isSuccess) {
      saveUser(data?.data)
    }

    setLoading(false)
    return data?.isSuccess
  }

  const onLogout = async () => {
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.AccessToken)
    localStorageUtils.deleteLocalStorage(LocalStorageValueTypes.UserData)
  }

  const checkAuthorized = async () => {
    const currentUrl = window.location.pathname
    console.log('currentUrl', currentUrl)

    const isLogin = await authLogin()

    if (!isLogin) {
      onLogout()
      navigate(RoutesUrl.SignIn, { state: { callbackUrl: currentUrl } })
      return false
    }
  }

  return (
    <Routes>
      <Route path={RoutesUrl.Events} element={<Events />} />
      <Route path={RoutesUrl.EventOrder} element={<EventOrder />} />
      <Route path={RoutesUrl.EventTicket} element={<EventTicket />} />
      <Route path={RoutesUrl.Profile} element={<Profile />} />
      <Route path={RoutesUrl.UpdateProfile} element={<UpdateProfile />} />
      <Route path={RoutesUrl.QrScanner} element={<QRScanner />} />
      <Route path={RoutesUrl.Activity} element={<Activities />} />
      <Route path={RoutesUrl.Settings} element={<Settings />} />
      <Route path={RoutesUrl.ChangeLanguage} element={<ChangeLanguage />} />
      <Route path={RoutesUrl.ChangePassword} element={<ChangePassword />} />
      <Route path={RoutesUrl.HelpCenter} element={<HelpCenter />} />
      <Route path={RoutesUrl.Settings} element={<Settings />} />
      <Route path={RoutesUrl.UserContact} element={<Contacts />} />
      <Route path={RoutesUrl.ContactDetail} element={<ContactDetail />} />
      <Route path={RoutesUrl.UpsertProfile} element={<UpsertProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AuthenticatedApp
