import images from "@/assets";
import { envConfigs } from "@/configs";
import EnterpriseModel from "../enterprise/model";
import ProfessorModel from "../professor/model";
import BannerModel from "./banner/model";
import EventTicketModel from "./event-ticket/model";
import EventDateTimesModel from "./event-times/model";
import LocationModel from "./location/model";

export type EventState = {
  loading: boolean
  event: EventModel | null
  events: EventModel[] | []
  recentEvents: []
  priorityEvents: []
  nearestEvents: []
  musicEvents: []
  artEvents: []
  workshopEvents: []
  exhibitionEvents: []
  upcomingEvents: []
  historyEvents: []
  eventTicket: EventTicketModel | null
  eventTickets: EventTicketModel[] | []
  setLoading: (loading: boolean) => void
  setEvents: (events: EventModel[]) => void
  setEvent: (event: EventModel) => void
  setRecentEvents: (recentEvents: any) => void
  setPriorityEvents: (priorityEvents: any) => void
  setNearestEvents: (nearestEvents: any) => void
  setMusicEvents: (musicEvents: any) => void
  setArtEvents: (artEvents: any) => void
  setWorkshopEvents: (workshopEvents: any) => void
  setExhibitionEvents: (exhibitionEvents: any) => void
  setUpcomingEvents: (upcomingEvents: any) => void
  setHistoryEvents: (historyEvents: any) => void
  setEventTicket: (eventTicket: EventTicketModel) => void
  setEventTickets: (eventTickets: EventTicketModel[]) => void
}

export enum EventType {
  Conference = 1,
  Workshop = 2,
  Music = 3,
  Exhibition = 4,
  Festival = 5,
  Other = 9
}

export enum EventParticipantType {
  Participant = 1,
  Sponsor = 2,
}

export const EventParticipantTypeNames: any = {
  1: 'participant',
  2: 'sponsor',
}

export enum EventParticipantPaymentType {
  Paid = 1,
  Unpaid = 2,
}

export enum EventRulesAgeGroup {
  Child = 'C',
  YoungAdults = 'Y',
  MiddleAgedAdults = 'M',
  OldAgedAdults = 'O'
}

export const EventRulesAgeGroupNames: any = {
  'C': 'ruleAge.child',
  'Y': 'ruleAge.young_adults',
  'M': 'ruleAge.middle_aged_adults',
  'O': 'ruleAge.old_aged_adults'
}

export enum EventRulesGender {
  Male = 'M',
  Female = 'F',
  Other = 'O'
}

export const EventRulesGenderNames: any = {
  'M': 'gender.male',
  'F': 'gender.female',
  'O': 'gender.other'
}

export default class EventModel {
  public id!: string
  public locationId!: string
  public title!: string
  public registrationStart!: string
  public registrationDeadline!: string
  public lockRegistration!: string
  public content!: string
  public rulesContent!: string
  public rulesAgeGroup!: string
  public rulesGender!: string
  public participantMaxSize!: number
  public privacy!: number
  public status!: number
  public type!: number
  public paymentType!: number
  public createdAt!: string
  public updatedAt!: string
  public location!: LocationModel
  public banner!: BannerModel
  public eventDateTimes!: EventDateTimesModel[]
  public isFavorite!: boolean
  public participants!: EventTicketModel[]
  public joinedParticipants!: any

  public enterprises!: EnterpriseModel[] | []
  public professors!: ProfessorModel[] | []

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.locationId = data.location_id
      this.title = data.title
      this.registrationStart = data.registration_start
      this.registrationDeadline = data.registration_deadline
      this.lockRegistration = data.lock_registration
      this.content = data.content
      this.rulesContent = data.rules_content
      this.rulesAgeGroup = data.rules_age_group
      this.rulesGender = data.rules_gender
      this.participantMaxSize = data.participant_max_size
      this.privacy = data.privacy
      this.status = data.status
      this.type = data.type
      this.paymentType = data.payment_type
      this.createdAt = data.created_at
      this.updatedAt = data.updated_at
      this.location = new LocationModel(data?.location)
      this.banner = new BannerModel(data.banner)
      this.eventDateTimes = data.event_date_times?.map((d: any) => new EventDateTimesModel(d))
      this.isFavorite = data.is_favorite
      this.participants = data?.participants?.length > 0 ? data.participants?.map((t: any) => new EventTicketModel(t)) : []
      this.joinedParticipants = data?.joined_participants?.length > 0 ? data.joined_participants : []

      this.enterprises = data?.enterprises?.length > 0 ? data.enterprises.map((e: any) => new EnterpriseModel(e)) : []
      this.professors = data?.professors?.length > 0 ? data.professors.map((p: any) => new ProfessorModel(p)) : []
    }
  }

  public getUrl(): string {
    return `${envConfigs.IMAGE_LINK}/users/`
  }

  public getBannerUrl(): string {
    return this.banner.thumbnail ? `${envConfigs.IMAGE_LINK}/events/${this.banner.thumbnail}` : images.eventSuggestDefault()
  }
}