import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
  },

  headerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e5e5e5',
  },

  contentTab: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 'calc(100vh - 210px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
    backgroundColor: '#F1F1F1',
  },

  tabItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '15px',
    // borderBottom: '2px solid #000',
  },

  tabActive: {
    borderBottom: `1px solid ${Colors.PRIMARY}`,
  },
})

export default useStyles
