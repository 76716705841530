import { Loading, Wrapper } from '@/components'
import Header from '@/components/wrapper/components/header'
import { EventAPI } from '@/stores/event'
import EventTicketModel from '@/stores/event/event-ticket/model'
import EventDateTimesModel from '@/stores/event/event-times/model'
import EventModel, { EventParticipantTypeNames } from '@/stores/event/model'
import useEventStore from '@/stores/event/state'
import useUserStore from '@/stores/user/state'
import { modalRef } from '@/utils/refs'
import { google, ics, outlook } from 'calendar-link'
import { clsx } from 'clsx'
import moment from 'moment'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { RxCalendar } from 'react-icons/rx'
import { SiApple, SiGooglecalendar, SiMicrosoftoutlook } from 'react-icons/si'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'

const ShowQr = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const { qrCode } = props

  return (
    <div className={styles.qrModal}>
      <img src={qrCode} alt="qrCode" />
    </div>
  )
}

const AddToCalendar = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const { event, eventTicket } = props

  const activeEventTime = event?.eventDateTimes.find((eventDateTime: EventDateTimesModel) => eventDateTime.id === eventTicket?.eventDateTimeId)

  const startDateTime = moment(activeEventTime?.startDateTime).format('YYYY-MM-DDTHH:mm:ss')
  const endDateTime = moment(activeEventTime?.endDateTime).format('YYYY-MM-DDTHH:mm:ss')

  const eventObj = {
    title: event?.title,
    description: event?.content,
    location: event?.location?.address,
    start: startDateTime,
    end: endDateTime,
  }

  const addToGoogle = () => {
    const url = google(eventObj)
    window.open(url, '_blank')
  }

  const addToOutlook = () => {
    const url = outlook(eventObj)
    window.open(url, '_blank')
  }

  const getFileICal = () => {
    const iCal = ics(eventObj); //included data:text/calendar;charset=utf-8
    const element = document.createElement('a')
    element.setAttribute('href', iCal)
    element.setAttribute('download', 'event.ics')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  return (
    <div className={styles.addCalendar}>
      <div className={clsx(styles.listBtn, 'showList')}>

        <div className={clsx(styles.btnItem)} onClick={getFileICal}>
          <span className={clsx(styles.iconItem)}>
            <SiApple />
          </span>
          <span className={clsx(styles.btnLabel)}>Apple</span>
        </div>

        <div className={clsx(styles.btnItem)} onClick={addToGoogle}>
          <span className={clsx(styles.iconItem)}>
            <SiGooglecalendar />
          </span>
          <span className={clsx(styles.btnLabel)}>Google</span>
        </div>

        <div className={clsx(styles.btnItem)} onClick={addToOutlook}>
          <span className={clsx(styles.iconItem)}>
            <SiMicrosoftoutlook />
          </span>
          <span className={clsx(styles.btnLabel)}>Outlook</span>
        </div>

        <div className={clsx(styles.btnItem)} onClick={getFileICal}>
          <span className={clsx(styles.iconItem)}>
            <RxCalendar />
          </span>
          <span className={clsx(styles.btnLabel)}>iCal File</span>
        </div>
      </div>
    </div>
  )
}

const EventTicket = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const params = useParams()
  const { eventId, id } = params

  const [loading, setLoading] = useState<boolean>(false)

  const { user } = useUserStore()
  const { event, eventTicket, setEvent, setEventTicket } = useEventStore()

  useEffect(() => {
    if (user && eventId) {
      getEvent(eventId)
    }
  }, [user, eventId])

  useEffect(() => {
    if (user && eventId && id) {
      getEventTicket(eventId, id)
    }
  }, [user, eventId, id])

  const getEvent = async (eventId: string) => {
    setLoading(true)
    const response: any = await EventAPI.getEvent(eventId)
    if (response.status === 200) {
      const newEvent = new EventModel(response.data.data)
      setEvent(newEvent)
    }
    setLoading(false)
  }

  const getEventTicket = async (eventId: string, id: string) => {
    if (!eventId || !id) return

    setLoading(true)

    const response = await EventAPI.getEventTicket(eventId, id)
    if (response?.data?.status === 200) {
      const newEventTicket = new EventTicketModel(response.data.data)
      setEventTicket(newEventTicket)
    }
    setLoading(false)
  }

  const getQrCodeSvg = () => {
    const svg = document.getElementById('ticketQrCode')
    if (!svg) return
    const svgData = new XMLSerializer().serializeToString(svg)
    return `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  const downloadQrCode = () => {
    const qrCode = getQrCodeSvg()
    if (!qrCode) return

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = 'QRCode'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = qrCode
  }

  const showTicket = () => {
    const qrCode = getQrCodeSvg()
    modalRef.current?.open({
      content: <ShowQr qrCode={qrCode} />,
    })
  }

  const showAddToCalendar = () => {
    modalRef.current?.open({
      content: <AddToCalendar event={event} eventTicket={eventTicket} />,
    })
  }

  const eventDate = event?.eventDateTimes.find(
    (eventDateTime: any) => eventDateTime.id === eventTicket?.eventDateTimeId,
  )
  const qrCodeVal = eventTicket?.id

  const eventStart = event?.eventDateTimes?.length
    ? event?.eventDateTimes[0]
    : null
  const eventEnd = event?.eventDateTimes?.length
    ? event?.eventDateTimes[event?.eventDateTimes?.length - 1]
    : null

  let eventTimeShow = ''
  let eventDateShow = ''
  if (eventStart) {
    eventTimeShow = `${moment(eventStart?.startDateTime).format(
      'dddd',
    )}, ${moment(eventStart?.startDateTime).format('h:mm A')}`
    eventDateShow = `${moment(eventStart?.startDateTime).format(
      'MMMM D, YYYY',
    )}`

    if (eventEnd) {
      if (
        moment(eventStart?.startDateTime).format('YYYY-MM-DD') !==
        moment(eventEnd?.endDateTime).format('YYYY-MM-DD')
      ) {
        eventTimeShow = `${eventTimeShow} - ${moment(
          eventEnd?.endDateTime,
        ).format('dddd')}, ${moment(eventEnd?.endDateTime).format('h:mm A')}`
        eventDateShow = `${eventDateShow} - ${moment(
          eventEnd?.endDateTime,
        ).format('MMMM D, YYYY')}`
      } else {
        eventTimeShow = `${eventTimeShow} - ${moment(
          eventEnd?.endDateTime,
        ).format('h:mm A')}`
      }
    }
  }

  return (
    <Wrapper>
      <Header title={t('viewTicket.view_ticket')} showBack={true} />
      {loading && <Loading />}

      <div className={styles.container}>
        <div className={styles.ticket}>
          <div className={styles.ticketInfo}>
            <div className={styles.eventBanner}>
              <img className={styles.banner} src={event?.banner?.getRawUrl()} />
            </div>
            <div className={styles.eventInfo}>
              <div className={styles.eventName}>
                <span>{event?.title}</span>
              </div>

              <div className={styles.eventTypeSeat}>
                <div className={styles.eventType}>
                  <label className={clsx(styles.label)}>
                    {t('viewTicket.event_type')}
                  </label>
                  <div className={clsx(styles.value)}>
                    {eventTicket?.type
                      ? EventParticipantTypeNames[eventTicket?.type]
                      : ''}
                  </div>
                </div>

                <div className={styles.eventSeat}>
                  <label className={clsx(styles.label)}>
                    {t('viewTicket.event_seat')}
                  </label>
                  <div className={clsx(styles.value)}>No seat</div>
                </div>
              </div>

              <div className={styles.eventDateTime}>
                <div className={styles.eventDate}>
                  <label className={clsx(styles.label)}>
                    {t('viewTicket.event_date')}
                  </label>
                  <div className={clsx(styles.value)}>
                    <div className={styles.txtDate}>{eventDateShow}</div>
                    <div className={styles.txtTime}>{eventTimeShow}</div>
                  </div>
                </div>
              </div>
              <div className={styles.eventLocation}>
                <label className={clsx(styles.label)}>
                  {t('viewTicket.event_location')}
                </label>
                <div className={clsx(styles.value)}>
                  {event?.location?.address}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ticketRecLine}></div>
          <div className={styles.ticketQrCode}>
            <div className={styles.ticketQrContent}>
              <div className={styles.ticketQrInfo}>
                <div className={styles.ticketQrId}>
                  {t('viewTicket.ticket_id')}: {eventTicket?.ticketCode}
                </div>
                <div className={styles.ticketQrDesc}>
                  {t('viewTicket.ticket_description')}
                </div>
              </div>
              <div className={styles.ticketQrImg}>
                <div className={styles.qrCode} onClick={showTicket}>
                  <QRCodeSVG
                    id="ticketQrCode"
                    size={230}
                    value={JSON.stringify(qrCodeVal)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <button
            className={clsx('btn', styles.btnAddCalendar)}
            onClick={showAddToCalendar}
          >
            {t('viewTicket.add_calendar')}
          </button>

          <button
            className={clsx('btn', styles.btnConfirm)}
            onClick={downloadQrCode}
          >
            {t('viewTicket.ticket_download')}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default EventTicket
