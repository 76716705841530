import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  upcomingContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },

  filter: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '15px',
  },

  filterItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    borderRadius: '10px',
    cursor: 'pointer',
    border: `1px solid #8A8380`,
    color: '#8A8380',
    '&:not(:last-child)': {
      marginRight: '15px',
    },
  },

  active: {
    border: `1px solid ${Colors.PRIMARY}`,
    backgroundColor: '#F84C0B1A',
    color: Colors.PRIMARY,
  },
})

export default useStyles
