
import { Wrapper } from '@/components'

import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContactUs from './components/contact'
import Faq from './components/faq'
import useStyles from './styles'

const HelpCenter = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  const [tab, setTab] = useState<any>(0)

  const { changeBreadcrumb } = useAppStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  const onChangeTab = (tab: number) => {
    setTab(tab)
  }

  return (
    <Wrapper>
      <Header
        title={t('helpCenter.help_center')}
        showBack={true}
      />
      <div className={styles.container}>
        <div className={styles.headerTab}>
          <div className={clsx(styles.tabItem, tab === 0 && styles.tabActive)} onClick={() => onChangeTab(0)}>FAQ</div>
          <div className={clsx(styles.tabItem, tab === 1 && styles.tabActive)} onClick={() => onChangeTab(1)}>Contact us</div>
        </div>

        <div className={styles.contentTab}>
          {tab === 0 && <Faq />}
          {tab === 1 && <ContactUs />}
        </div>
      </div>
    </Wrapper>
  )
}

export default HelpCenter
