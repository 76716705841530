import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0 5px 5px -5px rgba(149, 157, 165, 0.2)',
  },

  headerTop: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '20px',
  },

  title: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    color: '#131313',
    fontSize: '1.25rem',
    fontWeight: '600',
  },

  center: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },

  back: {
    display: 'flex',
    flexDirection: 'row',
    padding: '3px 0',
    paddingRight: '10px',
  },

  users: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },

  txtName: {
    color: '#2196f3',
    fontWeight: 'bold',
    fontSize: '16px',
  },

  txtLogout: {
    color: 'red',
  },

  btnLogout: {
    marginLeft: '15px',
    border: '1px solid #E1222A',
    borderRadius: '20px',
    padding: '2px 15px',
    backgroundColor: 'white',
    color: '#E1222A',
    fontSize: '14px'
  },

  logoutBtns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '20px',
  },

  btnNo: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '2px 20px',
    backgroundColor: '#fff',
    fontWeight: '600'
  },

  btnYes: {
    border: '1px solid #E1222A',
    borderRadius: '5px',
    padding: '2px 20px',
    color: '#E1222A',
    fontWeight: '600',
    backgroundColor: '#fff',
  },

  userAvatar: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginLeft: '10px',
  },

  languageSection: {
    fontSize: '25px',
    marginLeft: '20px'
  }
})

export default useStyles
