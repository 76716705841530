import { useGoogleLogin } from '@react-oauth/google';

import { UserAPI } from '@/stores/user';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

const GoogleLoginButton = (props: any) => {
  const navigate = useNavigate()
  const { on3rdPartyLogin } = props
  const styles = useStyles()

  const clientId = '483826794083-ab8gu96h5e7je5pta6fc1pvce214nfhn.apps.googleusercontent.com'

  const handleGoogleSuccess = async (res: any) => {
    const response = await UserAPI.loginWithGoogle(res)
    on3rdPartyLogin && on3rdPartyLogin(response)
  };

  const onLogin = useGoogleLogin({
    onSuccess: res => handleGoogleSuccess(res),
  })

  return (
    <button type="button" className={styles.btnSocial} onClick={() => onLogin()}>
      <FcGoogle size={48} />
    </button>
  );
};

export default GoogleLoginButton;
