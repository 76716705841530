import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  label: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#858585',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  input: {
    display: 'flex',
    padding: '10px 0',
    paddingRight: '35px',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#858585',
    height: '45px',
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    borderRadius: 'unset',
    outline: 'none',
    '&:focus': {
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
    '&::placeholder': {
      color: '#EBE9E8',
      fontSize: '0.8125rem',
    },
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    padding: '0 20px',
  },

  btnUpdate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: '500',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
      cursor: 'pointer',
    },
  },

  btnDisabled: {
    backgroundColor: Colors.GRAY,
    color: Colors.WHITE,
    cursor: 'not-allowed',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.GRAY,
      cursor: 'not-allowed',
    },
  },

  //alert
  alertModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    },
  },

  successImg: {
    display: 'flex',
    width: '250px',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      width: '135px',
    },
  },

  successTitle: {
    color: Colors.PRIMARY,
    fontSize: '20px',
    fontWeight: '500',
  },

  successDescription: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '15px',
  },

  groupBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },

  btnOk: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },

  btnCancel: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },

  error: {
    color: 'red',
    fontSize: '0.875rem',
    fontWeight: '400',
    marginTop: '5px',
    fontStyle: 'italic',
  },
})

export default useStyles
