import images from '@/assets'
import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },

  forgotPasswordPage: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    cursor: 'pointer',
  },

  headerTitle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: Colors.BLACK,
  },

  txtDescription: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '400',
    color: Colors.BLACK,
    '& p': {
      width: '70%',
      textAlign: 'center',
      color: '#8A8A8A',
    }
  },

  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  input: {
    display: 'flex',
    padding: '10px 0',
    paddingRight: '35px',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#858585',
    height: '45px',
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    borderRadius: 'unset',
    outline: 'none',
  },

  inputField: {
    display: 'flex',
    alignItems: 'center',
  },

  invalid: {
    backgroundImage: `url(${images.icInvalid()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '20px 20px',
    borderBottom: '1px solid #D93F21',
  },

  valid: {
    backgroundImage: `url(${images.icValid()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '20px 20px',
    borderBottom: '1px solid green',
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },

  btnSendOtp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
  },

  btnDisabled: {
    backgroundColor: Colors.GRAY,
    color: Colors.WHITE,
    cursor: 'not-allowed',
  },

  //otpGroup
  otpGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '20px',
  },

  resend: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 0 20px',
  },

  resendText: {
    flex: 1,
  },

  resendButton: {
    color: '#F84C0B',
    fontWeight: '600',
  },

  btnSetPassword: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
  },
})

export default useStyles
