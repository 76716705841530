import { useEffect, useRef, useState } from 'react'

import { OtpInputs } from '@/components/otp-inputs'
import { Unauthorized } from '@/components/unauthorized'
import { UserAPI } from '@/stores/user'
import { isValidEmail } from '@/utils/validates'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const otpInputRef: any = useRef(null)
  const styles = useStyles()

  const [email, setEmail] = useState('')

  const [confirmOtp, setConfirmOtp] = useState(false)
  const [seconds, setSeconds] = useState(60)
  const [code, setCode] = useState('')

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState('')

  useEffect(() => {
    if (confirmOtp) {
      const interval = seconds > 0 ? setInterval(() => {
        setSeconds(seconds - 1)
      }, 1000) : null

      return () => {
        if (interval) clearInterval(interval)
      }
    }
  }, [seconds, confirmOtp])

  const goBack = () => {
    navigate(-1)
  }

  const onResendEmailOtp = async () => {
    setSeconds(60)
    const response: any = await UserAPI.sendForgetPasswordEmailOtp({ email })

    if (response?.data?.status === 200 && response?.data?.data) {
      // 'OTP has been sent. Please check your mailbox.'      
    } else {
      setError(response?.data?.data?.message)
    }
  }

  const onPushVerifyEmailOtp = async () => {
    setError('')
    // setLoading(true)
    const response: any = await UserAPI.sendForgetPasswordEmailOtp({ email })

    if (response?.data?.status === 200 && response?.data?.data) {
      setConfirmOtp(true)
    } else {
      setError(response?.data?.data?.message)
    }
    // setLoading(false)
  }

  const onChangeCode = (c: string) => {
    setCode(c)
    if (c.length === 6) {
      onSetNewPassword(c)
    }
  }

  const onSetNewPassword = async (c: string = code) => {
    setError('')
    const response: any = await UserAPI.checkEmailOtp({ email: email, otp: Number(c) })
    if (response?.data?.status === 200) {
      setShowNewPassword(true)
      setConfirmOtp(false)
    } else {
      setError(response?.data?.data?.message ?? 'Wrong OTP code. Please re-enter.')
      otpInputRef.current?.reset()
      setCode('')
    }
  }

  const onResetPassword = async () => {
    setError('')
    if (password !== confirmPassword) {
      setError('Password and confirm password does not match')
      return
    } else if (password.trim().length < 6) {
      setError('Password must be at least 6 characters')
      return
    }

    // setLoading(true)
    const response: any = await UserAPI.resetPassword({ email: email, otp: Number(code), newPassword: password.trim() })
    if (response?.data?.status === 200) {
      setError('Password reset successful. Please use the new password to login.')
      goBack()
    } else {
      setError(response?.data?.data?.message ?? 'An error occurred. Please try again.')
    }
    // setLoading(false)
  }

  const isEmail = isValidEmail(email)

  return (
    <Unauthorized>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.forgotPasswordPage)}>
          <div className={clsx(styles.header)}>
            <div className={clsx(styles.back)} onClick={goBack}>
              <IoIosArrowBack size={24} />
            </div>
            <div className={clsx(styles.headerTitle)}>
              {t('forgotPassword.forgot_password')}
            </div>
          </div>

          <div className={styles.txtDescription}>
            <p>Nhập tài khoản email của bạn để thiết lập lại mật khẩu của bạn</p>
          </div>

          <div className={clsx(styles.content)}>
            <div className="error text-red text-center">
              {error}
            </div>

            {!confirmOtp && !showNewPassword &&
              <>
                <div className={clsx(styles.inputGroup)}>
                  <input type="email" className={clsx(styles.input, isEmail && styles.valid, email && !isEmail && styles.invalid)} name="username" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className={clsx(styles.inputGroup)}>
                  <button type="button" className={clsx('btn', styles.btnSendOtp)} onClick={onPushVerifyEmailOtp}>{t('forgotPassword.forgot_password_send_otp')}</button>
                </div>
              </>
            }

            {confirmOtp && !showNewPassword &&
              <div className={clsx(styles.otpGroup)}>
                <label htmlFor="email">{t('forgotPassword.forgot_password_description')}</label>
                <div className={clsx(styles.resend)}>
                  <span className={clsx(styles.resendText)}>{t('forgotPassword.forgot_password_expires')}</span>
                  {seconds > 0 ?
                    <span className={clsx(styles.resendSecond)}>{t('forgotPassword.resend')} ({seconds}s)</span>
                    :
                    <span className={clsx(styles.resendButton)} onClick={onResendEmailOtp}>{t('forgotPassword.resend')}</span>
                  }
                </div>
                <OtpInputs ref={otpInputRef} onChange={onChangeCode} />
              </div>
            }

            {showNewPassword &&
              <>
                <label htmlFor="password">{t('forgotPassword.enter_new_password')}</label>
                <div className={clsx(styles.inputGroup)}>
                  <input type="password" className={clsx(styles.input)} name="password" placeholder={t('forgotPassword.password')} value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className={clsx(styles.inputGroup)}>
                  <input type="password" className={clsx(styles.input)} name="confirmPassword" placeholder={t('forgotPassword.confirm_password')} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </div>

                <div className={clsx(styles.btnGroup)}>
                  <button type="button" className={clsx('btn', styles.btnSetPassword)} onClick={onResetPassword}>{t('forgotPassword.reset_password')}</button>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </Unauthorized>
  )
}

export default ForgotPassword
