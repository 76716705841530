import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  notifCard: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '10px',
    marginTop: '15px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#FDA7871A',
    },
  },

  notifIcon: {
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    padding: '5px 10px',
    backgroundColor: '#FDA7871A',
    borderRadius: '8px',
    color: '#FDA7871A'
  },

  notifContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },

  notifRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
  },

  notifTitle: {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    fontWeight: 'bold',
  },

  notifTime: {
    textAlign: 'right',
    fontSize: '10px',
    color: '#999',
    marginLeft: '10px',
  },

  notifDesc: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    color: '#666',
  },
})

export default useStyles
