import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '15px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {
    },
  },

  headerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    width: '100%',
  },

  tabItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '10px 20px',
    // borderBottom: '2px solid #000',
  },

  tabActive: {
    borderBottom: `1px solid ${Colors.PRIMARY}`,
  },
})

export default useStyles
