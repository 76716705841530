import { Wrapper } from '@/components'
import RequestSchedule from '@/components/request-schedule'
import { UserAvatar } from '@/components/user-avatar'
import { Colors, RoutesUrl } from '@/constants'
import { ScheduleTypeEnum } from '@/constants/schedule-type.enum'
import { useModal } from '@/ModalContext'
import gateway from '@/stores/gateway'
import { ProfessorAPI } from '@/stores/professor'
import ProfessorModel from '@/stores/professor/model'
import useUserStore from '@/stores/user/state'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import clsx from 'clsx'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiMail, FiPhoneCall } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { IoHomeOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'

const Professor = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const params = useParams()

  const { t } = useTranslation()
  const { id } = params

  const [professor, setContact] = useState<ProfessorModel>()
  const { user } = useUserStore()
  const [openRequestSchedule, setOpenRequestSchedule] = useState(false)
  const { showModal } = useModal()

  useEffect(() => {
    if (!user)
      navigate(
        RoutesUrl.SignInCallback.replace(':callback', window.location.pathname),
      )
    if (id) {
      getUserContact(id)
    }
  }, [id])

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken,
    )
    if (token) {
      gateway.init(JSON.parse(token))
    }
  }, [])

  const getUserContact = async (id: string) => {
    const response: any = await ProfessorAPI.getProfessor(id)
    if (response?.data?.status === 200) {
      const newUserContact = new ProfessorModel(response?.data?.data)
      setContact(newUserContact)
    } else {
      // navigate(RoutesUrl.Home)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  const onHome = () => {
    navigate(RoutesUrl.Home)
  }

  const createMarkup = (content: string) => {
    return { __html: content }
  }

  const parse = (content: string) => {
    return <div dangerouslySetInnerHTML={createMarkup(content)} />
  }

  const onRequestScheduleSuccess = () => {
    setOpenRequestSchedule(false)
  }

  return (
    <Fragment>
      {openRequestSchedule && (
        <RequestSchedule
          type={ScheduleTypeEnum.professor}
          onClose={onRequestScheduleSuccess}
          id={id}
        />
      )}
      <Wrapper>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.bgPen}></div>

            <div className={styles.rowBackShare}>
              <div className={styles.back} onClick={onBack}>
                <IoIosArrowBack size={28} style={{ color: Colors.PRIMARY }} />
              </div>

              <div className={styles.share} onClick={onHome}>
                <IoHomeOutline size={24} style={{ color: Colors.PRIMARY }} />
              </div>
            </div>

            <div className={styles.avatarGroup}>
              <UserAvatar
                user={professor}
                avatarUrl={professor?.getAvatar()}
                customClass={styles.avatar}
                style={{ width: 96 }}
              />
              <div className={styles.nameArea}>
                <div className={styles.name}>{professor?.name}</div>
                <div className={styles.title}>{professor?.title}</div>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            {professor?.phone && (
              <div className={styles.rowInfo}>
                <div className={styles.iconArea}>
                  <FiPhoneCall size={24} style={{ color: Colors.PRIMARY }} />
                </div>
                <div className={styles.infoText}>{professor?.phone}</div>
              </div>
            )}

            {professor?.email && (
              <div className={styles.rowInfo}>
                <div className={styles.iconArea}>
                  <FiMail size={24} style={{ color: Colors.PRIMARY }} />
                </div>
                <div className={styles.infoText}>{professor?.email}</div>
              </div>
            )}

            <div className={styles.rowIntro}>
              <div className={styles.introText}>
                {professor?.data ? parse(professor.data) : ''}
              </div>
            </div>

            <div className={styles.rowIntro}>
              <div className={styles.actionsGroup}>
                <button
                  className={clsx('btn', styles.btnOk)}
                  onClick={() => {
                    setOpenRequestSchedule(true)
                  }}
                >
                  {t('schedule.requestMeeting')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Fragment>
  )
}

export default Professor
