import gateway, { APIMethod } from "../gateway"

import { envConfigs } from "@/configs"

const API_SERVER = envConfigs.SERVER_URL

export const getEvents = (filter?: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`, filter)
}

export const getEvent = (eventId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events/${eventId}`)
}

export const getUserEvents = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events`)
}

export const getUserEvent = (eventId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/${eventId}`)
}

export const joinEvent = (eventId: string, params: any) => {
  return gateway.send(APIMethod.POST, `${API_SERVER}/events/${eventId}/participants`, params)
}

export const favoriteEvent = (eventId: string) => {
  return gateway.send(APIMethod.PUT, `${API_SERVER}/events/${eventId}/user-favorite`)
}

export const getEventTicket = (eventId: string, ticketId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/${eventId}/user-participants/${ticketId}`)
}

export const getPriorityEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`, params)
}

export const getNearEvents = () => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`)
}

export const getMusicEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`, params)
}

export const getArtEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`, params)
}

export const getWorkshopEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`, params)
}

export const getExhibitionEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/p/events`, params)
}

export const getUpcomingEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/user-events`, params)
}

export const getHistoryEvents = (params: any) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/events/user-events`, params)
}

export const getEventEnterprise = (eventId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/event-enterprises/${eventId}`)
}

export const getEventProfessor = (eventId: string) => {
  return gateway.send(APIMethod.GET, `${API_SERVER}/event-professors/${eventId}`)
}