
import { Wrapper } from '@/components'

import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import History from './history'
import useStyles from './styles'
import Upcoming from './upcoming'

const Events = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const [tab, setTab] = useState<any>(0)


  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Events)
  }, [])

  const onChangeTab = (tab: number) => {
    setTab(tab)
  }

  return (
    <Wrapper>
      <Header
        title={t('events.event')}
        bottom={
          <div className={styles.headerTab}>
            <div className={clsx(styles.tabItem, tab === 0 && styles.tabActive)} onClick={() => onChangeTab(0)}>{t('events.upcoming')}</div>
            <div className={clsx(styles.tabItem, tab === 1 && styles.tabActive)} onClick={() => onChangeTab(1)}>{t('events.history')}</div>
          </div>
        }
      />
      <div className={styles.container}>
        {tab === 0 && <Upcoming />}
        {tab === 1 && <History />}
      </div>
    </Wrapper>
  )
}

export default Events
