export default class EventDateTimesModel {
  public id!: string
  public eventId!: number
  public startDateTime!: string
  public endDateTime!: string

  constructor(data: any) {
    if (data) {
      this.id = data.id
      this.eventId = data.event_id
      this.startDateTime = data.start_date_time
      this.endDateTime = data.end_date_time
    }
  }

}