import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  profileCard: {
    display: 'flex',
    width: '60%',
    minHeight: '175px',
    backgroundSize: '50% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    boxShadow: '-1px 1px 16px 0px rgba(0, 0, 0, 0.08)',
    '@media (max-width: 768px)': {
      width: '100%',
    },
    '&:hover div.showGroupBtn': {
      display: 'flex',
    },
    position: 'relative',
  },

  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '0',
    paddingRight: '10px',
    zIndex: 1,
  },

  cardName: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#FFFFFF',
  },

  cardPosition: {
    fontSize: '0.8rem',
    fontWeight: '400',
    color: '#FFFFFF',
    marginBottom: '20px',
  },

  cardPhone: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#FFFFFF',
  },

  cardEmail: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#FFFFFF',
  },

  cardAddress: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '400',
    color: '#FFFFFF',
  },

  iconArea: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '4px',
    backgroundColor: '#FEEDE7',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px',
  },

  qrSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    zIndex: 1,
    position: 'relative',
  },

  qrCodeContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(248, 76, 11, 0.80)',
    clipPath: 'polygon(60% 0, 100% 0, 100% 100%, 40% 100%)',
    zIndex: 0,
  },

  qrBoxView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '20px',
  },

  cardCompany: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: 'black',
  },

  qrCodeHex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
    },
  },

  qrCodeContact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    width: '125px',
    height: '125px',
    borderRadius: '5px',
    zIndex: 1,
    '@media (max-width: 768px)': {
      width: '100px',
      height: '100px',
    },
  },

  //groupBtn
  groupBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    position: 'absolute',
    top: -15,
    right: 5,
  },

  rightBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5px',
    marginLeft: '5px',
    cursor: 'pointer',
  },

  mt2: {
    marginTop: '10px',
  },
})

export default useStyles
