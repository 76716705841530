import { createUseStyles } from 'react-jss';

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  input: {
    display: 'flex',
    width: '60px !important',
    height: '60px !important',
    fontSize: '24px !important',
    fontWeight: '400',
    color: '#F84C0B',
    textAlign: 'center',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    borderRadius: 'unset',
    outline: 'none',
    '&:focus': {
      borderBottom: '2px solid #F84C0B',
      outline: 'none'
    },
  },
})

export default useStyles