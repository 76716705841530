import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 150px)',
    width: '100%',
    padding: '20px',
  },

  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  headerRightItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5px',
    marginLeft: '5px',
    cursor: 'pointer',
  },

  userProfile: {
    display: 'flex',
    width: '100%',
    height: '95px',
    alignItems: 'center',
    paddingBottom: '20px',
  },

  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '72px',
    height: '72px',
    borderRadius: '8px',
    backgroundColor: '#FEEDE7',
    marginBottom: '10px',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
    },
  },

  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
  },

  userName: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color: '#F84C0B',
  },

  userPosition: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#131313',
  },

  headerTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '45px',
  },

  tabItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '10px 20px',
    color: '#A59F9C',
    fontWeight: '500',
    borderBottom: '1px solid #EBE9E8',
  },

  tabActive: {
    color: Colors.PRIMARY,
    borderBottom: `1px solid ${Colors.PRIMARY}`,
  },

  contentTab: {
    marginTop: '10px',
    width: '100%',
    height: 'calc(100% - 145px)',
    maxHeight: '-webkit-fill-available',
  },

  //modal
  qrModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '75vw',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
})

export default useStyles
