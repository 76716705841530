import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  faq: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },

  faqItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    fontSize: '0.875rem',
    borderBottom: '1px solid #E5E5E5',
    backgroundColor: '#FFFFFF',
    '&:last-child': {
      marginBottom: 0,
    },
  },

  faqItemTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },

  title: {
    display: 'flex',
    flex: 1,
    fontWeight: '500',
    color: '#131313',
  },

  toggle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },

  faqItemContent: {
    display: 'flex',
    marginTop: '15px',
    borderRadius: '4px',
    lineHeight: '1.5rem',
  },

})

export default useStyles
