import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  modal: {
    width: '100dvw',
    height: '100dvh',
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.5)',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    padding: '2rem 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  container: {
    width: '100%',
    maxWidth: '600px',
    margin: '10% auto 0 auto',
    background: Colors.WHITE,
    borderRadius: '8px',
    padding: '20px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalBody: {
    marginTop: '1rem',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  label: {
    marginBottom: '0.5rem',
  },
  dateTimeInput: {
    padding: '0.5rem',
    borderRadius: '4px',
    border: `1px solid ${Colors.GRAY}`,
    outline: 'none',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
  submitBtn: {
    padding: '0.5rem 1rem',
    background: Colors.PRIMARY,
    color: Colors.WHITE,
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
  },
  cancelBtn: {
    padding: '0.5rem 1rem',
    background: Colors.BGGRAY,
    color: Colors.BLACK1,
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    marginRight: '1rem',
  },
  error: {
    color: Colors.RED,
    fontSize: 12,
    marginTop: 5,
    paddingLeft: 10,
  },
  //alert
  alertModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '250px',
    },
  },

  successImg: {
    display: 'flex',
    width: '250px',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      width: '135px',
    },
  },

  successTitle: {
    color: Colors.PRIMARY,
    fontSize: '20px',
    fontWeight: '500',
  },

  successDescription: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '15px',
  },

  groupBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },

  btnRequestMeeting: {
    maxWidth: '200px',
  },
  btnOk: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },

  btnCancel: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },
})

export default useStyles
