import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {

    },
  },

  eventSection: {
    display: 'flex',
    flexDirection: 'column',
  },

  orderInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    boxShadow: '0 0 10px rgb(223 227 233)',
    marginBottom: '15px',
    borderRadius: '8px',
  },

  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    boxShadow: '0 0 10px rgb(223 227 233)',
    marginBottom: '15px',
    borderRadius: '8px',
  },

  groupName: {
    display: 'flex',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: '0',
    },
  },

  inputLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },

  input: {
    height: '40px',
    padding: '0 10px',
    border: 'none',
    borderBottom: '1px solid #bcbcbc',
    outline: 'none',
    borderRadius: 'unset',
    '&:focus': {
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
  },

  select: {
    '& option': {
      height: '40px',
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
  },

  textArea: {
    height: '100px',
    padding: '10px',
    border: 'none',
    borderBottom: '1px solid #bcbcbc',
    outline: 'none',
    resize: 'none',
    '&:focus': {
      borderBottom: `1px solid ${Colors.PRIMARY}`,
    },
  },

  checkbox: {
    display: 'flex',
    marginBottom: '10px',
    borderRadius: '2px !important',
    '&:checked': {
      backgroundColor: Colors.PRIMARY,
      border: `1px solid ${Colors.PRIMARY}`,
    },
  },

  width50: {
    width: '47%',
    marginRight: '15px',
    '&:last-child': {
      marginRight: '0',
    },
  },

  datePicker: {
    width: '100%',
  },

  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    padding: '10px',
  },

  btnConfirm: {
    width: '100%',
    height: '45px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: Colors.PRIMARY,
    },
  },

  btnDisabled: {
    width: '100%',
    height: '45px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.GRAY,
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'not-allowed',
  },

  btnJoined: {
    width: '100%',
    height: '45px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#27AE601A',
    color: '#27AE60',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'not-allowed',
    '&:hover': {
      color: '#27AE60',
      backgroundColor: '#27AE601A',
    },
  },

  //alert
  alertModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: 'unset',
    },
  },

  successImg: {
    display: 'flex',
    width: '250px',
    marginBottom: '15px',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },

  successTitle: {
    color: Colors.PRIMARY,
    fontSize: '20px',
    fontWeight: '500',
  },

  successDescription: {
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    marginTop: '15px',
  },

  groupBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '15px',
  },

  btnViewTicket: {
    width: '65%',
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      color: '#fff',
      backgroundColor: Colors.PRIMARY,
    },
  },

  btnBack: {
    width: '65%',
    height: '40px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      color: Colors.PRIMARY,
      backgroundColor: '#fff',
      border: `1px solid ${Colors.PRIMARY}`,
    },
  },

  btnContinue: {
    height: '40px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      color: '#fff',
      backgroundColor: Colors.PRIMARY,
    },
  },

  ml15: {
    marginLeft: '15px',
  },

  addCalendarTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
  },

  addCalendar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
  },

  listBtn: {
    display: 'flex',
    flexDirection: 'column',
  },

  btnItem: {
    display: 'flex',
    width: '100%',
    lineHeight: '45px',
    backgroundColor: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    borderBottom: `1px solid ${Colors.LIGHTGRAY}`,
    '&:hover': {
      color: Colors.PRIMARY,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  iconItem: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    color: Colors.PRIMARY,
  },

  btnLabel: {
    flex: 1,
    fontWeight: 'bold',
  },
})

export default useStyles
