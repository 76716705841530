import EventTicketModel from './event-ticket/model'
import EventModel from './model'
import useEventStore from './state'

export const setLoading = (loading: boolean) => useEventStore.setState({ loading })
export const setEvent = (event: EventModel) => useEventStore.setState({ event })
export const setEvents = (events: EventModel[]) => useEventStore.setState({ events })
export const setRecentEvents = (recentEvents: any) => useEventStore.setState({ recentEvents })
export const setPriorityEvents = (priorityEvents: any) => useEventStore.setState({ priorityEvents })
export const setNearestEvents = (nearestEvents: any) => useEventStore.setState({ nearestEvents })
export const setMusicEvents = (musicEvents: any) => useEventStore.setState({ musicEvents })
export const setArtEvents = (artEvents: any) => useEventStore.setState({ artEvents })
export const setWorkshopEvents = (workshopEvents: any) => useEventStore.setState({ workshopEvents })
export const setExhibitionEvents = (exhibitionEvents: any) => useEventStore.setState({ exhibitionEvents })
export const setUpcomingEvents = (upcomingEvents: any) => useEventStore.setState({ upcomingEvents })
export const setHistoryEvents = (historyEvents: any) => useEventStore.setState({ historyEvents })
export const setEventTicket = (eventTicket: EventTicketModel) => useEventStore.setState({ eventTicket })
export const setEventTickets = (eventTickets: EventTicketModel[]) => useEventStore.setState({ eventTickets })