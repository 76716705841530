import clsx from 'clsx'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import useStyles from './styles'

const Header = (props: any) => {
  const { title, showBack, right, bottom } = props
  const navigate = useNavigate()
  const styles = useStyles()

  return (
    <div className={styles.header}>
      <div className={styles.headerTop}>
        {showBack && (
          <div className={styles.back} onClick={() => navigate(-1)}>
            <IoIosArrowBack size={24} />
          </div>
        )}

        {title && <div className={clsx(styles.title, showBack && styles.center)}>{title}</div>}
        {right}
      </div>
      {bottom}
    </div>
  )
}

export default Header
