import images from '@/assets';
import { Wrapper } from '@/components';
import Header from '@/components/wrapper/components/header';
import { RoutesUrl } from '@/constants';
import { UserContactAPI } from '@/stores/user-contact';
import { modalRef } from '@/utils/refs';
import { QrScanner } from '@yudiel/react-qr-scanner';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';

const AlertModal = (props: any) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { isSuccess, message, contact_id, callback } = props

  const onContinue = () => {
    navigate(RoutesUrl.ContactDetail.replace(':id', contact_id))
    modalRef.current?.close()
  }

  const onTryAgain = () => {
    modalRef.current?.close()
    callback()
  }

  const onCancel = () => {
    navigate(-1)
    modalRef.current?.close()
  }

  return (
    <div className={styles.alertModal}>
      <div className={styles.successImg}>
        <img src={isSuccess ? images.success() : images.failed()} alt="success" />
      </div>

      <div className={styles.successTitle}>{isSuccess ? t('alert.success') : t('alert.failed')}</div>
      <div className={styles.successDescription}>
        {isSuccess ? t('contact.add_contact_success') : message}
      </div>

      <button className={clsx('btn', styles.btnOk)} onClick={isSuccess ? onContinue : onTryAgain}>{isSuccess ? t('alert.ok') : t('alert.try_again')}</button>
      {!isSuccess && <button className={clsx('btn', styles.btnCancel)} onClick={onCancel}>{t('alert.cancel')}</button>}
    </div>
  )
}

const ScanOverlay = () => {
  const styles = useStyles()
  return (
    <div className={styles.svgContainerStyle}>
      <div className={styles.svgDesc}>Place the QR code inside the area</div>

      <svg
        viewBox="0 0 100 100"
        className={styles.svgStyle}
      >
        <path
          fill="none"
          d="M23,0 L0,0 L0,23"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
        <path
          fill="none"
          d="M0,77 L0,100 L23,100"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
        <path
          fill="none"
          d="M77,100 L100,100 L100,77"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
        <path
          fill="none"
          d="M100,23 L100,0 77,0"
          stroke="#F84C0B"
          strokeWidth="2"
          strokeLinejoin="round" // Sử dụng bo góc cho đường viền
        ></path>
      </svg>
    </div>
  )
}

const QRScanner = () => {
  const styles = useStyles()
  const navigate = useNavigate()

  const [scan, setScan] = useState(true)

  const callback = () => {
    setScan(true)
  }

  const handleScan = async (qrValues: any) => {
    if (qrValues) {
      setScan(false)
      let isSuccess = false
      let message = ''
      let contact_id = ''

      const parseUrl = qrValues.split('/')
      const userBusinessProfileId = parseUrl[parseUrl.length - 1]

      const data = {
        userBusinessProfileId,
      }

      const response = await UserContactAPI.addUserContact(data)
      if (response?.status === 200) {
        isSuccess = true
        contact_id = response?.data?.data?.user_business_profile_id
      } else {
        isSuccess = false
        message = response?.data?.data?.message
      }
      onShowAlertModal(isSuccess, message, contact_id, callback)
    }
  }

  const onShowAlertModal = (isSuccess: boolean, message: string, contact_id: string, callback: () => void) => {
    modalRef.current?.open({
      content: <AlertModal isSuccess={isSuccess} message={message} contact_id={contact_id} callback={callback} />,
    })
  }

  return (
    <Wrapper>
      <Header
        title='Scanning'
        showBack={true}
      />

      <div className={styles.qrScannerContent}>
        <div className={styles.qrReaderContainer}>
          <QrScanner
            constraints={{ facingMode: 'environment' }}
            scanDelay={100}
            onDecode={!scan ? undefined : handleScan}
            onError={(error: any) => console.log(error?.message)}
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
            viewFinder={ScanOverlay}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default QRScanner;
