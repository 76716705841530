import { Colors } from '@/constants'
import moment from 'moment'
import { IoNotifications } from 'react-icons/io5'
import useStyles from "./styles"

export const NotificationCard = (props: any) => {
  const styles = useStyles()

  const { notification } = props

  const onClickNotification = () => {
    notification?.link && window.open(notification?.link)
  }

  return (
    <div className={styles.notifCard} onClick={onClickNotification}>
      <div className={styles.notifIcon}>
        <IoNotifications size={24} style={{ color: Colors.PRIMARY }} />
      </div>

      <div className={styles.notifContent}>
        <div className={styles.notifRow}>
          <div className={styles.notifTitle}>
            {notification?.title}
          </div>

          <div className={styles.notifTime}>
            {notification?.timestamp ? moment.unix(notification?.timestamp).format('DD/MM/YYYY HH:mm') : ''}
          </div>
        </div>

        <div className={styles.notifDesc}>
          {notification?.body}
        </div>
      </div>
    </div>
  )
}
