import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  //css container
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, ///height: vh
    padding: '20px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.25em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgb(223 227 233)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#bcbcbc',
    },
    '@media (max-width: 768px)': {},
  },

  ticket: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
  },

  ticketInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  eventBanner: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#FFC1AA1A',
    borderRadius: '10px 10px 0 0',
  },

  banner: {
    display: 'flex',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '10px',
    padding: '15px',
  },

  eventInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px 15px',
    width: '100%',
    background:
      'radial-gradient(circle 20px at bottom left ,#0000 98%,#FFC1AA1A) bottom left, radial-gradient(circle 20px at bottom right,#0000 98%,#FFC1AA1A) bottom right',
    backgroundSize: '50% 100%',
    backgroundRepeat: 'no-repeat',
  },

  eventName: {
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '10px',
  },

  label: {
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '5px',
    color: '#8A8380',
  },

  value: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '10px',
  },

  eventTypeSeat: {
    display: 'flex',
    flexDirection: 'row',
  },

  eventType: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: '10px',
  },

  eventSeat: {
    display: 'flex',
    flexDirection: 'column',
    width: '75px',
  },

  eventDateTime: {
    display: 'flex',
    flexDirection: 'row',
  },

  eventDate: {
    flex: 1,
    marginBottom: '10px',
  },

  eventTime: {
    marginBottom: '10px',
    width: '75px',
  },

  eventLocation: {
    marginBottom: '10px',
  },

  ticketQrCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    background:
      'radial-gradient(circle 20px at top left ,#0000 98%,#FFC1AA1A) top left, radial-gradient(circle 20px at top right,#0000 98%,#FFC1AA1A) top right',
    backgroundSize: '50% 100%',
    backgroundRepeat: 'no-repeat',
    padding: '30px 15px 15px',
    borderRadius: '0 0 10px 10px',
  },

  ticketQrContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#F84C0B1A',
    borderRadius: '10px',
  },

  ticketQrInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    flex: 1,
  },

  ticketQrId: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '10px',
  },

  ticketQrDesc: {
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
  },

  ticketQrImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    flex: 1,
  },

  qrCode: {
    width: '250px',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },

  ticketRecLine: {
    display: 'flex',
    height: '1px',
    width: 'calc(100% - 40px)',
    border: '1px dashed #EBE9E8',
    zIndex: 1,
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    padding: '10px',
    gap: '10px',
  },

  btnConfirm: {
    width: '100%',
    height: '45px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: Colors.PRIMARY,
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: Colors.PRIMARY,
    },
  },

  btnCancel: {
    width: '100%',
    height: '45px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
  },

  btnAddCalendar: {
    width: '100%',
    height: '45px',
    border: `1px solid ${Colors.PRIMARY}`,
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: Colors.PRIMARY,
    marginTop: '15px',
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    '&:hover': {
      color: Colors.PRIMARY,
      border: `1px solid ${Colors.PRIMARY}`,
    },
  },

  addCalendar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
  },

  listBtn: {
    display: 'flex',
    flexDirection: 'column',
  },

  btnItem: {
    display: 'flex',
    width: '100%',
    lineHeight: '45px',
    backgroundColor: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
    borderBottom: `1px solid ${Colors.LIGHTGRAY}`,
    '&:hover': {
      color: Colors.PRIMARY,
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  iconItem: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    color: Colors.PRIMARY,
  },

  btnLabel: {
    flex: 1,
    fontWeight: 'bold',
  },

  //modal
  qrModal: {
    display: 'flex',
    minWidth: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      minWidth: '75vw',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
})

export default useStyles
