import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100vh',
    maxHeight: '-webkit-fill-available',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px',
    padding: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
})

export default useStyles
