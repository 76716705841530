
import { Loading, Wrapper } from '@/components'

import { useModal } from '@/ModalContext'
import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant, RoutesUrl } from '@/constants'
import { useAppStore } from '@/stores/app'
import { UserAPI } from '@/stores/user'
import useUserStore from '@/stores/user/state'
import { validateEmail, validatePhone } from '@/utils/string'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import MiniTemplate1 from '../card/mini-template-1'
import MiniTemplate2 from '../card/mini-template-2'
import MiniTemplate3 from '../card/mini-template-3'
import MiniTemplate4 from '../card/mini-template-4'
import Template1 from '../card/template-1'
import Template2 from '../card/template-2'
import Template3 from '../card/template-3'
import Template4 from '../card/template-4'
import useStyles from './styles'

interface State {
  errors: any
  loading: boolean
  name: string
  companyName: string
  companyTitle: string
  businessEmail: string
  businessPhone: string
  companyAddress: string
  companyDescription: string
  nameCardTemplate: number
}

const UpsertProfile = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { showModal } = useModal()

  const callbackUrl = location?.state?.callbackUrl

  const { changeBreadcrumb } = useAppStore()
  const { userEdit, setUserEdit } = useUserStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  const [state, setState] = useState<State>({
    errors: {},
    loading: false,
    name: '',
    companyName: '',
    companyTitle: '',
    businessEmail: '',
    businessPhone: '',
    companyAddress: '',
    companyDescription: '',
    nameCardTemplate: 1
  })
  const handleStateChange = (value: string | string[] | boolean | object, field: string) => setState(prevState => ({ ...prevState, [field]: value }))

  useEffect(() => {
    if (userEdit) {
      setState(prevState => ({
        ...prevState,
        name: userEdit?.name,
        companyName: userEdit?.companyName,
        companyTitle: userEdit?.companyTitle,
        businessEmail: userEdit?.businessEmail,
        businessPhone: userEdit?.businessPhone,
        companyAddress: userEdit?.companyAddress,
        companyDescription: userEdit?.companyDescription,
        nameCardTemplate: userEdit?.nameCardTemplate
      }))
    }
  }, [userEdit])


  const onChangeInput = (e: any, field: string) => {
    handleStateChange({}, 'errors')
    handleStateChange(e.target.value, field)
  }

  const onChangeRadio = (e: any, field: string) => {
    handleStateChange({}, 'errors')
    handleStateChange(e.target.value, field)
  }

  const onChangeAge = (value: string) => {
    handleStateChange({}, 'errors')
    handleStateChange(value, 'ageGroup')
  }

  const onChangeTemplateCard = (value: string) => {
    handleStateChange({}, 'errors')
    handleStateChange(value, 'nameCardTemplate')
  }

  const validate = (formValues: any) => {
    const errors: any = {}
    if (!formValues.name) {
      errors.name = t('errors.required')
    }
    if (!formValues.nameCardTemplate) {
      errors.nameCardTemplate = t('errors.required')
    }
    if (formValues.businessEmail && !validateEmail(formValues.businessEmail)) {
      errors.businessEmail = t('errors.invalid_email')
    }
    if (formValues.businessPhone && !validatePhone(formValues.businessPhone)) {
      errors.businessPhone = t('errors.invalid_phone')
    }
    if (!validatePhone(formValues.businessPhone)) {
      errors.businessPhone = t('errors.invalid_phone')
    }
    return errors
  }

  const onSubmit = async () => {
    const { name, companyName, companyTitle, businessEmail, businessPhone, companyAddress, companyDescription, nameCardTemplate } = state
    handleStateChange(true, 'loading')

    const errors = validate(state)
    if (Object.keys(errors).length > 0) {
      handleStateChange(errors, 'errors')
      handleStateChange(false, 'loading')
      return
    }

    const data = {
      name,
      companyName,
      companyTitle,
      businessEmail,
      businessPhone,
      companyAddress,
      companyDescription,
      nameCardTemplate
    }

    if (userEdit?.id) {
      const response = await UserAPI.updateBizProfile(userEdit?.id, data)
      if (response?.data?.status === 200) {
        if (callbackUrl) {
          navigate(callbackUrl)
        } else {
          navigate(RoutesUrl.Profile)
        }
      } else {
        showModal(response?.data?.data?.message ?? t('alert.system_error'))
      }
    } else {
      // call api update profile
      const response = await UserAPI.addBizProfile(data)
      if (response?.data?.status === 200) {
        if (callbackUrl) {
          navigate(callbackUrl)
        } else {
          navigate(RoutesUrl.Profile)
        }
      } else {
        showModal(response?.data?.data?.message ?? t('alert.system_error'))
      }
    }
    handleStateChange(false, 'loading')
  }
  const disabled = false

  return (
    <Wrapper>
      <Header
        title={userEdit?.id ? t('profile.update_profile') : t('profile.add_profile')}
        showBack={true}
      />
      <div className={styles.container}>
        {state.loading && <Loading />}

        <div className={styles.personalInfo}>
          <div className={styles.personalTitle}>{t('profile.personal_info')}</div>

          <div className={clsx(styles.inputGroup)}>
            <label className={styles.label}>{t('profile.name')}</label>
            <input type="text" className={styles.input} value={state.name} onChange={(e: any) => onChangeInput(e, 'name')} />
            <span className={styles.error}>{state.errors?.name}</span>
          </div>
        </div>

        <div className={styles.companyInfo}>
          <div className={styles.personalTitle}>{t('profile.company_info')}</div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>{t('profile.company_name')}</label>
            <input type="text" className={styles.input} value={state.companyName} onChange={(e: any) => onChangeInput(e, 'companyName')} />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>{t('profile.title')}</label>
            <input type="text" className={styles.input} value={state.companyTitle} onChange={(e: any) => onChangeInput(e, 'companyTitle')} />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>{t('profile.business_email')}</label>
            <input type="text" className={styles.input} value={state.businessEmail} onChange={(e: any) => onChangeInput(e, 'businessEmail')} />
            <span className={styles.error}>{state.errors?.businessEmail}</span>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>{t('profile.business_phone')}</label>
            <input type="text" className={styles.input} value={state.businessPhone} onChange={(e: any) => onChangeInput(e, 'businessPhone')} />
            <span className={styles.error}>{state.errors?.businessPhone}</span>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>{t('profile.address')}</label>
            <input type="text" className={styles.input} value={state.companyAddress} onChange={(e: any) => onChangeInput(e, 'companyAddress')} />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>{t('profile.company_introduction')}</label>
            <textarea className={styles.textArea} value={state.companyDescription} onChange={(e: any) => onChangeInput(e, 'companyDescription')} />
          </div>

          <div>
            <div className={styles.label}>{t('profile.choose_card_template')}</div>
            <div className={styles.cardGroup}>
              <div className={styles.cardItem}>
                <div className={clsx(styles.item, Number(state.nameCardTemplate) === 1 && styles.itemActive)} onClick={() => onChangeTemplateCard('1')}>
                  <MiniTemplate1 />
                </div>
                <div className={clsx(styles.item, Number(state.nameCardTemplate) === 2 && styles.itemActive)} onClick={() => onChangeTemplateCard('2')}>
                  <MiniTemplate2 />
                </div>
                <div className={clsx(styles.item, Number(state.nameCardTemplate) === 3 && styles.itemActive)} onClick={() => onChangeTemplateCard('3')}>
                  <MiniTemplate3 />
                </div>
                <div className={clsx(styles.item, Number(state.nameCardTemplate) === 4 && styles.itemActive)} onClick={() => onChangeTemplateCard('4')}>
                  <MiniTemplate4 />
                </div>
              </div>
            </div>
            <div className={styles.cardShow}>
              {Number(state.nameCardTemplate) === 1 && <Template1 user={state} />}
              {Number(state.nameCardTemplate) === 2 && <Template2 user={state} />}
              {Number(state.nameCardTemplate) === 3 && <Template3 user={state} />}
              {Number(state.nameCardTemplate) === 4 && <Template4 user={state} />}
            </div>
          </div>
        </div>

        <div className={clsx(styles.btnGroup)}>
          <button type="button" className={clsx('btn', styles.btnUpdate, disabled ? styles.btnDisabled : '')} onClick={disabled ? undefined : onSubmit}>
            {userEdit?.id ? t('profile.btn_update') : t('profile.btn_add')}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default UpsertProfile
