
import { Wrapper } from '@/components'

import { UserAvatar } from '@/components/user-avatar'
import Header from '@/components/wrapper/components/header'
import { BreadcrumbsConstant, RoutesUrl } from '@/constants'
import { useAppStore } from '@/stores/app'
import useUserStore from '@/stores/user/state'
import { modalRef } from '@/utils/refs'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineScan } from 'react-icons/ai'
import { LiaUserEditSolid } from 'react-icons/lia'
import { RiSettingsLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import BusinessCard from './business-card'
import Contacts from './contacts'
import useStyles from './styles'

const ShowQr = (props: any) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()

  const { qrCode } = props

  return (
    <div className={styles.qrModal}>
      <img src={qrCode} alt="qrCode" />
    </div>
  )
}

const Profile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const styles = useStyles()

  const { changeBreadcrumb } = useAppStore()
  const { user } = useUserStore()

  const [tab, setTab] = useState<any>(0)

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])

  const onOpenQRScanner = () => {
    navigate(RoutesUrl.QrScanner)
  }

  const onUpdateProfile = () => {
    navigate(RoutesUrl.UpdateProfile)
  }

  const onSettings = () => {
    navigate(RoutesUrl.Settings)
  }

  const getQrCodeSvg = () => {
    const svg = document.getElementById("qrCodeContact")
    if (!svg) return
    const svgData = new XMLSerializer().serializeToString(svg)
    return `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  const showTicket = () => {
    const qrCode = getQrCodeSvg()
    modalRef.current?.open({
      content: <ShowQr qrCode={qrCode} />,
    })
  }

  return (
    <Wrapper>
      <Header
        title='Mine'
        right={
          <div className={styles.headerRight}>
            <div className={styles.headerRightItem} onClick={onOpenQRScanner}>
              <AiOutlineScan size={22} style={{ color: '#8A8380' }} />
            </div>
            <div className={styles.headerRightItem} onClick={onUpdateProfile}>
              <LiaUserEditSolid size={22} style={{ color: '#8A8380' }} />
            </div>
            <div className={styles.headerRightItem} onClick={onSettings}>
              <RiSettingsLine size={22} style={{ color: '#8A8380' }} />
            </div>
          </div>
        }
      />
      <div className={styles.container}>
        <div className={styles.userProfile}>
          <UserAvatar user={user} />
          <div className={styles.userInfo}>
            <div className={styles.userName}>{user?.name}</div>
            <div className={styles.userPosition}>{user?.companyTitle}</div>
          </div>
        </div>

        <div className={styles.headerTab}>
          <div className={clsx(styles.tabItem, tab === 0 && styles.tabActive)} onClick={() => setTab(0)}>{t('profile.business_card')}</div>
          <div className={clsx(styles.tabItem, tab === 1 && styles.tabActive)} onClick={() => setTab(1)}>{t('profile.contacts')}</div>
        </div>

        <div className={styles.contentTab}>
          {tab === 0 && <BusinessCard />}
          {tab === 1 && <Contacts />}
        </div>
      </div>
    </Wrapper>
  )
}

export default Profile
