

import { BreadcrumbsConstant } from '@/constants'
import { useAppStore } from '@/stores/app'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import useStyles from './styles'

const FaqItem = ({ title, content }: any) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const [isShow, setIsShow] = useState(false)

  const { changeBreadcrumb } = useAppStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Profile)
  }, [])


  return (
    <div className={styles.faqItem}>
      <div className={styles.faqItemTitle}>
        <div className={styles.title}>{title}</div>
        <div className={styles.toggle} onClick={() => setIsShow(!isShow)}>
          {!isShow && <IoIosArrowDown />}
          {isShow && <IoIosArrowUp />}
        </div>
      </div>
      {isShow && (
        <div className={styles.faqItemContent}>
          {content}
        </div>
      )}
    </div>
  )
}

const Faq = () => {
  const styles = useStyles()
  const { t } = useTranslation()

  const { changeBreadcrumb } = useAppStore()

  useEffect(() => {
    changeBreadcrumb(BreadcrumbsConstant.Settings)
  }, [])

  const disabled = false

  return (
    <div className={styles.faq}>
      <FaqItem
        title={'Lorem ipsum dolor sit amet consectetur?'}
        content={
          'Lorem ipsum dolor sit amet consectetur. Vel in porttitor morbi velit tempus nunc. At adipiscing euismod a sit. Arcu imperdiet sollicitudin elementum odio ac nec enim risus. Leo dictum malesuada egestas sed consectetur commodo. Vel ullamcorper nulla erat cras quam ut at. Viverra mi ante odio eget blandit mattis nec lectus elit. Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdiet porttitor nam.Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdi.'
        }
      />
      <FaqItem
        title={'Lorem ipsum dolor sit amet consectetur?'}
        content={
          'Lorem ipsum dolor sit amet consectetur. Vel in porttitor morbi velit tempus nunc. At adipiscing euismod a sit. Arcu imperdiet sollicitudin elementum odio ac nec enim risus. Leo dictum malesuada egestas sed consectetur commodo. Vel ullamcorper nulla erat cras quam ut at. Viverra mi ante odio eget blandit mattis nec lectus elit. Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdiet porttitor nam.Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdi.'
        }
      />
      <FaqItem
        title={'Lorem ipsum dolor sit amet consectetur?'}
        content={
          'Lorem ipsum dolor sit amet consectetur. Vel in porttitor morbi velit tempus nunc. At adipiscing euismod a sit. Arcu imperdiet sollicitudin elementum odio ac nec enim risus. Leo dictum malesuada egestas sed consectetur commodo. Vel ullamcorper nulla erat cras quam ut at. Viverra mi ante odio eget blandit mattis nec lectus elit. Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdiet porttitor nam.Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdi.'
        }
      />
      <FaqItem
        title={'Lorem ipsum dolor sit amet consectetur?'}
        content={
          'Lorem ipsum dolor sit amet consectetur. Vel in porttitor morbi velit tempus nunc. At adipiscing euismod a sit. Arcu imperdiet sollicitudin elementum odio ac nec enim risus. Leo dictum malesuada egestas sed consectetur commodo. Vel ullamcorper nulla erat cras quam ut at. Viverra mi ante odio eget blandit mattis nec lectus elit. Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdiet porttitor nam.Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdi.'
        }
      />
      <FaqItem
        title={'Lorem ipsum dolor sit amet consectetur?'}
        content={
          'Lorem ipsum dolor sit amet consectetur. Vel in porttitor morbi velit tempus nunc. At adipiscing euismod a sit. Arcu imperdiet sollicitudin elementum odio ac nec enim risus. Leo dictum malesuada egestas sed consectetur commodo. Vel ullamcorper nulla erat cras quam ut at. Viverra mi ante odio eget blandit mattis nec lectus elit. Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdiet porttitor nam.Nisl ac nisi adipiscing etiam. Pulvinar augue habitant ut imperdi.'
        }
      />
    </div>
  )
}

export default Faq
