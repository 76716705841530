import useStyles from './styles'

const EmptyState = (props: any) => {
  const styles = useStyles()

  const { imgUrl, text } = props
  return (
    <div className={styles.viewEmpty}>
      <img className={styles.imgEmpty} src={imgUrl} />
      <h4 className={styles.txtEmpty}>{text}</h4>
    </div>
  )
}

export default EmptyState