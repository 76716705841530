import images from '@/assets'
import { Colors } from '@/constants'
import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    margin: '0 auto',
    maxWidth: '800px',
    minWidth: '300px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },

  signInPage: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '38px',
    height: '38px',
    cursor: 'pointer',
  },

  headerTitle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: Colors.BLACK,
  },

  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
  },

  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '50px',
  },

  userAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    height: '120px',
    borderRadius: '50%',
  },

  avatarImg: {
    width: '100%',
    height: '100%',
  },

  image: {
    width: '120px',
    height: '120px',
    border: '1px solid #bcbcbc',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
  },

  empty: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  txtChar: {
    fontSize: '3rem !important',
  },

  userName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: Colors.BLACK,
    marginTop: '10px',
  },

  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },

  input: {
    display: 'flex',
    padding: '10px 0',
    paddingRight: '35px',
    fontSize: '1rem',
    fontWeight: '400',
    color: '#858585',
    height: '45px',
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: '1px solid #EBE9E8',
    borderRadius: 'unset',
    outline: 'none',
  },

  inputField: {
    display: 'flex',
    alignItems: 'center',
  },

  invalid: {
    backgroundImage: `url(${images.icInvalid()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '20px 20px',
    borderBottom: '1px solid #D93F21',
  },

  valid: {
    backgroundImage: `url(${images.icValid()})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '20px 20px',
    borderBottom: '1px solid green',
  },

  invalidMsg: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0',
    fontStyle: 'italic',
    color: 'red',
    fontWeight: '500',
    fontSize: '14px',
  },

  btnGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },

  btnSignIn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.WHITE,
    backgroundColor: Colors.PRIMARY,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.PRIMARY,
      cursor: 'pointer',
    },
  },

  btnDisabled: {
    backgroundColor: Colors.GRAY,
    color: Colors.WHITE,
    cursor: 'not-allowed',
    '&:hover': {
      color: Colors.WHITE,
      backgroundColor: Colors.GRAY,
      cursor: 'not-allowed',
    },
  },

  btnCancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '45px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.PRIMARY,
    backgroundColor: Colors.WHITE,
    borderRadius: '8px',
    cursor: 'pointer',
    width: '100%',
    marginTop: '10px',
    border: `1px solid ${Colors.PRIMARY}`,
    '&:hover': {
      border: `1px solid ${Colors.PRIMARY}`,
      cursor: 'pointer',
    },
  },

  // error
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    color: Colors.RED,
    marginTop: '20px',
  },

  imageError: {
    width: '120px',
    height: '120px',
  },

  errorMsg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
})

export default useStyles
