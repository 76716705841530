import { Wrapper } from '@/components'
import { UserAvatar } from '@/components/user-avatar'
import { Colors, RoutesUrl } from '@/constants'
import { EnterpriseAPI } from '@/stores/enterprise'
import EnterpriseModel from '@/stores/enterprise/model'
import gateway from '@/stores/gateway'
import { localStorageUtils } from '@/utils'
import { LocalStorageValueTypes } from '@/utils/localStorage'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineGlobal } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'
import { IoHomeOutline } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import useStyles from './styles'
import clsx from 'clsx'
import RequestSchedule from '@/components/request-schedule'
import { ScheduleTypeEnum } from '@/constants/schedule-type.enum'
import useUserStore from '@/stores/user/state'

const Enterprise = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const { user } = useUserStore()

  const { id } = params

  const [enterprise, setContact] = useState<EnterpriseModel>()
  const [openRequestSchedule, setOpenRequestSchedule] = useState(false)

  useEffect(() => {
    if (id) {
      if (!user)
        navigate(
          RoutesUrl.SignInCallback.replace(
            ':callback',
            window.location.pathname,
          ),
        )
      getUserContact(id)
    }
  }, [id])

  useEffect(() => {
    const token = localStorageUtils.getLocalStorage(
      LocalStorageValueTypes.AccessToken,
    )
    if (token) {
      gateway.init(JSON.parse(token))
    }
  }, [])

  const getUserContact = async (id: string) => {
    const response: any = await EnterpriseAPI.getEnterprise(id)
    if (response?.data?.status === 200) {
      const newUserContact = new EnterpriseModel(response?.data?.data)
      setContact(newUserContact)
    } else {
      // navigate(RoutesUrl.Home)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  const onHome = () => {
    navigate(RoutesUrl.Home)
  }

  const onOpenWebsite = (url: string) => {
    if (!url) return
    const newUrl =
      url.includes('http') || url.includes('https') ? url : 'https://' + url
    window.open(newUrl, '_blank', 'noopener,noreferrer')
  }

  const createMarkup = (content: string) => {
    return { __html: content }
  }

  const parse = (content: string) => {
    return <div dangerouslySetInnerHTML={createMarkup(content)} />
  }

  const onRequestScheduleSuccess = () => {
    setOpenRequestSchedule(false)
  }

  return (
    <Fragment>
      {openRequestSchedule && (
        <RequestSchedule
          type={ScheduleTypeEnum.enterprise}
          onClose={onRequestScheduleSuccess}
          id={id}
        />
      )}
      <Wrapper>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.rowBackShare}>
              <div className={styles.back} onClick={onBack}>
                <IoIosArrowBack size={28} style={{ color: Colors.PRIMARY }} />
              </div>

              <div className={styles.share} onClick={onHome}>
                <IoHomeOutline size={24} style={{ color: Colors.PRIMARY }} />
              </div>
            </div>

            <div className={styles.avatarGroup}>
              <UserAvatar user={enterprise} />
              <div className={styles.nameArea}>
                <div className={styles.name}>{enterprise?.name}</div>

                {enterprise?.website && (
                  <div className={styles.website}>
                    <div className={styles.iconArea}>
                      <AiOutlineGlobal
                        size={16}
                        style={{ color: Colors.PRIMARY }}
                      />
                    </div>
                    <div
                      className={styles.infoText}
                      onClick={
                        enterprise?.website
                          ? () => onOpenWebsite(enterprise?.website)
                          : undefined
                      }
                    >
                      {enterprise?.website}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.rowIntro}>
              <div className={styles.rowTitle}>{t('enterprise.bio')}</div>
              <div className={styles.introText}>
                {enterprise?.bio && parse(enterprise?.bio)}
              </div>
            </div>

            <div className={styles.rowIntro}>
              <div className={styles.rowTitle}>{t('enterprise.data')}</div>
              <div className={styles.introText}>
                {enterprise?.data && parse(enterprise?.data)}
              </div>
            </div>
            <div className={styles.rowIntro}>
              <div className={styles.actionsGroup}>
                <button
                  className={clsx('btn', styles.btnOk)}
                  onClick={() => {
                    setOpenRequestSchedule(true)
                  }}
                >
                  {t('schedule.requestMeeting')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Fragment>
  )
}

export default Enterprise
